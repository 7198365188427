import {
	type ActivatedRouteSnapshot,
	BaseRouteReuseStrategy,
} from "@angular/router";

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
	override shouldReuseRoute(
		future: ActivatedRouteSnapshot,
		curr: ActivatedRouteSnapshot,
	): boolean {
		if (
			future.data["reuseRoute"] === true &&
			curr.data["reuseRoute"] === true
		) {
			return true;
		}
		return future.routeConfig === curr.routeConfig;
	}
}
