import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable } from "rxjs";
import {
	type CreateFloorplanInput,
	type Floorplan,
	type UpdateFloorplanInput,
} from "src/app/schema";
import {
	ApiService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class FloorplanService {

	constructor(
		private api: ApiService,
		private httpClient: HttpClient
	) {}

	public createFloorplan(
		floorplan: CreateFloorplanInput,
	): Observable<Floorplan> {
		return this.httpClient.post<Floorplan>(
			`${this.api.inventoryApiEndpoint}/floorplan`,
			floorplan,
		);
	}

	public updateFloorplan(
		floorplan: UpdateFloorplanInput,
	): Observable<Floorplan> {
		return this.httpClient.put<Floorplan>(
			`${this.api.inventoryApiEndpoint}/floorplan`,
			floorplan,
		);
	}

	public deleteFloorplan(floorplan: Floorplan): Observable<{ id: string }> {
		return this.httpClient.delete<{ id: string }>(
			`${this.api.inventoryApiEndpoint}/floorplan/${floorplan.id}`,
		);
	}
}
