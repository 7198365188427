import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type {
	ApplicantRelationship,
	ApplicantStatus,
	ApplicationRole,
	CreditBureau,
	DocumentationStatus,
	EmploymentStatus,
	Gender,
	IdentityVerificationStatus,
	MaritalStatus,
	PersonalDetailsStatus,
	PresentResidence,
	SourceOfIncome,
} from "../../enumerations";
import type { Address } from "../utilities";
import type { EmergencyContact } from "./emergency-contact";
import type { Pet } from "./pet";

export namespace ApplicantReplicache {
	export const prefix = "applicant";

	export enum IndexNames {
		byApplicationId = `${prefix}/byApplicationId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byApplicationId]: {
			jsonPointer: "/applicationApplicantsId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Applicant {
	__typename: "Applicant";
	id: string;
	createdAt: string;
	updatedAt: string;
	firstName: string;
	lastName: string;
	middleName?: string | null;
	noMiddleName?: boolean | null;
	suffix?: string | null;
	preferredName?: string | null;
	status: ApplicantStatus;
	role: ApplicationRole;
	guarantorForApplicantId?: string | null;
	relationToPrimaryApplicant?: ApplicantRelationship | null;
	title?: string | null;
	occupation?: string | null;
	employer?: string | null;
	employmentStatus?: EmploymentStatus | null;
	university?: string | null;
	income?: number | null;
	sourceOfIncome?: SourceOfIncome | null;
	dateOfBirth?: string | null;
	dateOfBirthVerified?: boolean | null;
	phoneNumber: string;
	email: string;
	emailVerified?: boolean | null;
	secondaryEmail?: string | null;
	secondaryEmailVerified?: boolean | null;
	referralSources?: string[] | null;
	residentReferralName?: string | null;
	presentResidence?: PresentResidence | null;
	reasonsForLooking?: string[] | null;
	lengthAtPresentAddress?: string | null;
	maritalStatus?: MaritalStatus | null;
	gender?: Gender | null;
	pets?: Pet[] | null;
	requiresParking?: boolean | null;
	licensePlateNumber?: string | null;
	identificationId?: string | null;
	identificationType?: string | null;
	identificationNumber?: string | null;
	identificationNumberVerified?: boolean | null;
	identificationNumberSubmitted?: string | null;
	countriesOfCitizenship?: string[] | null;
	identityDateOfBirth?: string | null;
	identityDocumentType?: string | null;
	identityDocumentCountryOfIssue?: string | null;
	identityDocumentNumber?: string | null;
	identityAddress?: IdentityAddress | null;
	identityDocumentDateIssued?: string | null;
	identityVerificationStatus?: IdentityVerificationStatus | null;
	identityErrorMessage?: string | null;
	addresses?: Address[] | null;
	identityVerified?: boolean | null;
	creditVerified?: boolean | null;
	creditScore?: number | null;
	creditScoreBureau?: CreditBureau | null;
	convictedOfFelony?: boolean | null;
	convictedExplanation?: string | null;
	pendingCriminalCharges?: boolean | null;
	pendingCriminalChargesExplanation?: string | null;
	evicted?: boolean | null;
	evictedExplanation?: string | null;
	sexOffender?: boolean | null;
	sexOffenderExplanation?: string | null;
	drugConviction?: boolean | null;
	drugConvictionExplanation?: boolean | null;
	feePaid?: boolean | null;
	feePaidDate?: string | null;
	applicationFee?: number | null;
	emergencyContacts?: EmergencyContact[] | null;
	personalDetailsStatus?: PersonalDetailsStatus | null;
	documentationStatus?: DocumentationStatus | null;
	applicationComplete?: boolean | null;
	dateAdded: string;
	lastContact?: string | null;
	lastLogin?: string | null;
	personalDetailsCompletedDate?: string | null;
	personalDetailsApprovedDate?: string | null;
	documentationUploadedDate?: string | null;
	documentationApprovedDate?: string | null;
	canEditPersonalDetails?: boolean | null;
	canEditUnitSelection?: boolean | null;
	canUploadDocuments?: boolean | null;
	portalUserId: string; // Same as the cognito user ID.
	applicationApplicantsId: string;
	leadApplicantsId?: string | null;
	userApplicantsId?: string | null;
	propertyApplicantsId: string;
	organizationApplicantsId?: string | null;
}

export type CreateAdditionalApplicantInput = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	middleName?: string | null;
	noMiddleName?: boolean | null;
	suffix?: string | null;
	preferredName?: string | null;
	relationToPrimaryApplicant?: ApplicantRelationship | null;
	role: ApplicationRole;
	propertyId: string;
	applicationId: string;
	sendInvite: boolean;
};

export type CreateApplicantInput = {
	id?: string | null;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	middleName?: string | null;
	noMiddleName?: boolean | null;
	suffix?: string | null;
	preferredName?: string | null;
	role: ApplicationRole;
};

type ApplicantProperties = Omit<Applicant, "__typename">;

export type UpdateApplicantInput = Partial<ApplicantProperties> & {
	id: string;
};

export type IdentityAddress = {
	line1: string;
	line2?: string;
	city: string;
	state: string;
	postal_code: string;
	country: string;
};
