import type {
	IndexDefinition,
	IndexDefinitions,
	MutatorDefs,
} from "replicache";

import type { Replicacheable } from "./types";

export namespace ReplicacheUtils {
	export class IndexBuilder {
		constructor(private models: Replicacheable[]) {}

		public build(): IndexDefinitions {
			const indexDefinitions: Record<string, IndexDefinition> = {};
			for (const model of this.models) {
				if (!model.IndexDefinitions) continue;
				Object.entries(model.IndexDefinitions).forEach(
					([indexName, indexDefinition]) => {
						indexDefinitions[indexName] = indexDefinition;
					},
				);
			}
			return indexDefinitions;
		}
	}

	export class MutatorBuilder {
		constructor(private models: Replicacheable[]) {}

		public build(): MutatorDefs {
			const mutatorDefinitions: MutatorDefs = {};
			for (const model of this.models) {
				if (!model.MutatorDefinitions) continue;
				Object.entries(model.MutatorDefinitions).forEach(
					([mutatorName, mutatorDefinition]) => {
						mutatorDefinitions[mutatorName] = mutatorDefinition;
					},
				);
			}
			return mutatorDefinitions;
		}
	}
}
