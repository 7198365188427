import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { type Observable, map } from "rxjs";
import {
	ArrayOperation,
	type Audience,
	type AudienceFilter,
	BooleanOperation,
	type CreateAudienceInput,
	type Lead,
	NumberDateOperation,
	StringOperation,
	type UpdateAudienceInput,
} from "src/app/schema";
import { ApiService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class AudienceService {

	private readonly _api = inject(ApiService);
	private readonly _httpClient = inject(HttpClient);

	public getAudience(id: string): Observable<Audience> {
		return this._httpClient
			.get<Audience>(`${this._api.emailMarketingApiEndpoint}/audience/${id}`)
			.pipe(map((audience) => audience));
	}

	public createAudience(audience: CreateAudienceInput): Observable<Audience> {
		return this._httpClient
			.post<Audience>(
				`${this._api.emailMarketingApiEndpoint}/audience`,
				audience,
			)
			.pipe(map((audience) => audience));
	}

	public updateAudience(audience: UpdateAudienceInput): Observable<Audience> {
		return this._httpClient
			.put<Audience>(`${this._api.emailMarketingApiEndpoint}/audience`, audience)
			.pipe(map((audience) => audience));
	}

	// TODO: This is slow. It needs to be optimized.
	// Look into:
	// Running this on the server, or via a web worker.
	// Using a bloom filter to speed up the filtering process.
	// Storing the results in a seperate remote (and/or local) database.
	public getLeadsFromAudienceFilters(
		leads: Lead[],
		filters: AudienceFilter<keyof Lead>[],
	): Lead[] {
		return leads.filter((lead) => filters.every((filter) => this._isLeadMatchingFilter(lead, filter)));
	}

	private _isLeadMatchingFilter(
		lead: Lead,
		filter: AudienceFilter<keyof Lead>,
	): boolean {
		const leadValue = lead[filter.field];
		const arrayValue = Array.isArray(filter.value)
			? filter.value
			: [filter.value];

		switch (filter.operation) {
			case BooleanOperation.EQUALS:
			case StringOperation.EQUALS:
			case NumberDateOperation.EQUALS:
				return leadValue === filter.value;

			case BooleanOperation.NOT_EQUALS:
			case StringOperation.NOT_EQUALS:
			case NumberDateOperation.NOT_EQUALS:
				if (!leadValue) return true;
				return leadValue !== filter.value;

			case ArrayOperation.CONTAINS:
				return (
					Array.isArray(leadValue) &&
					arrayValue.some((value) => leadValue.includes(value as never))
				);

			case ArrayOperation.NOT_CONTAINS:
				return (
					Array.isArray(leadValue) &&
					arrayValue.some((value) => !leadValue.includes(value as never))
				);

			case NumberDateOperation.GREATER_THAN:
				return leadValue ? leadValue > filter.value : false;

			case NumberDateOperation.LESS_THAN:
				return leadValue ? leadValue < filter.value : false;

			default:
				return false;
		}
	}
}
