import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	get emailMarketingApiEndpoint(): string {
		return environment.emailMarketingEndpoint;
	}

	get communicationsApiEndpoint(): string {
		return environment.communicationsEndpoint;
	}

	get calendaringApiEndpoint(): string {
		return environment.calendaringEndpoint;
	}

	get inventoryApiEndpoint(): string {
		return environment.inventoryEndpoint;
	}

	get analyticsApiEndpoint(): string {
		return environment.analyticsEndpoint;
	}

	get coreApiEndpoint(): string {
		return environment.coreEndpoint;
	}
}
