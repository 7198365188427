import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { TagType } from "../../enumerations";

export namespace CommentReplicache {
	export const prefix = "comment";

	export enum IndexNames {
		byLeadId = `${prefix}/byLeadId`,
		byApplicationId = `${prefix}/byApplicationId`,
		byCampaignId = `${prefix}/byCampaignId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byLeadId]: {
			jsonPointer: "/leadCommentsId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
		[IndexNames.byApplicationId]: {
			jsonPointer: "/applicationCommentsId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
		[IndexNames.byCampaignId]: {
			jsonPointer: "/campaignCommentsId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Comment {
	id: string;
	createdAt: string;
	updatedAt: string;
	__typename: "Comment";
	date: string;
	contents: string;
	tags?: CommentTag | null;
	reads?: UserComment | null;
	leadCommentsId?: string | null;
	unitCommentsId?: string | null;
	tenantCommentsId?: string | null;
	applicationCommentsId?: string | null;
	leaseCommentsId?: string | null;
	campaignCommentsId?: string | null;
	floorplanCommentsId?: string | null;
	imageCommentsId?: string | null;
	virtualTourCommentsId?: string | null;
	propertyCommentsId?: string | null;
	propertyGroupCommentsId?: string | null;
	organizationCommentsId?: string | null;
	userCommentsId: string;
	user: UserComment;
}

type CommentProperties = Omit<Comment, "__typename">;

export type CreateCommentInput = Partial<CommentProperties>;
export type UpdateCommentInput = Partial<CommentProperties> & { id: string };

export type UserComment = {
	id: string;
	faviconSrc?: string | null;
	firstName: string;
	lastName: string;
	email: string;
};

export type CommentTag = {
	id: string;
	tagType: TagType;
	read: boolean;
	userTagsId: string;
};
