import { persistentAtom } from "@nanostores/persistent";
import type {
	ClientStateNotFoundResponse,
	Cookie,
	IndexDefinitions,
	MutatorDefs,
	PullResponseOKV0,
	PullResponseOKV1,
	PullerResult,
	VersionNotSupportedResponse,
} from "replicache";

export const CLIENT_VIEW_KEY = "CLIENT_VIEW";
export enum SyncState {
	COMPLETE = "COMPLETE",
	PARTIAL = "PARTIAL",
}
export type PullRequestResponse =
	| PullResponseOKV1
	| ClientStateNotFoundResponse
	| VersionNotSupportedResponse
	| PullResponseOKV0;

export interface Replicacheable {
	prefix: string;
	IndexDefinitions?: IndexDefinitions;
	MutatorDefinitions?: MutatorDefs;
}

export const COOKIE = persistentAtom<Cookie>("REPLICACHE_COOKIE", null, {
	encode: JSON.stringify,
	decode: JSON.parse,
});