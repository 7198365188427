import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import type {
	Cookie,
	HTTPRequestInfo,
	PullRequest,
	Puller,
	PullerResult,
	ReadTransaction,
	Replicache,
} from "replicache";
import { firstValueFrom } from "rxjs";

import { Queue } from "src/app/utils";
import { environment } from "src/environments/environment";
import {
	CLIENT_VIEW_KEY,
	COOKIE,
	type PullRequestResponse,
	SyncState,
} from "../types";

@Injectable({
	providedIn: "root",
})
export class PullService {
	public pokePartitionKeyQueue: Queue<string> = new Queue();

	private _syncState: SyncState = SyncState.PARTIAL;

	public get syncState(): SyncState {
		return this._syncState;
	}

	constructor(
		private httpClient: HttpClient,
		private ngZone: NgZone,
	) {}

	public puller: Puller = async (
		requestBody: PullRequest,
		requestID: string,
	): Promise<PullerResult> => {
		return this.ngZone.runOutsideAngular(() =>
			this._puller(requestBody, requestID),
		);
	};

	private _puller: Puller = async (
		requestBody: PullRequest,
		requestID: string,
	): Promise<PullerResult> => {
		const httpRequestInfo: HTTPRequestInfo = {
			httpStatusCode: 200,
			errorMessage: "",
		};
		try {
			const response = await this._executePull(requestBody, requestID);
			if ("lastMutationID" in response)
				throw new Error("PullResponseOKV0 is not supported.");
			if ("cookie" in response) {
				const cookie = response.cookie as Cookie;
				COOKIE.set(cookie);
			}
			return { response, httpRequestInfo };
		} catch (error) {
			return this._buildError(error, httpRequestInfo);
		}
	};

	public registerSyncStateObserver(replicache: Replicache): void {
		this.ngZone.runOutsideAngular(() =>
			this._registerSyncStateObserver(replicache),
		);
	}

	private _registerSyncStateObserver(replicache: Replicache): void {
		let readyState = false;
		const clientView = async (tx: ReadTransaction) =>
			(await tx.get<Record<string, any>>(CLIENT_VIEW_KEY)) || {};
		const onPull = async (clientView?: Record<string, any>) => {
			if (!clientView) return;
			this._syncState = clientView["syncState"] || SyncState.PARTIAL;
			if (!readyState) {
				readyState = true;
				return;
			}
			if (this._syncState === SyncState.PARTIAL) {
				console.log("CLIENT VIEW STATE CHANGED. PULL NEEDED. PULLING...");
				await replicache.pull();
			} else {
				console.log("CLIENT VIEW STATE CHANGED. NO PULL NEEDED.");
			}
		};
		replicache.subscribe(clientView, onPull);
	}

	private async _executePull(
		requestBody: PullRequest,
		requestID: string,
	): Promise<PullRequestResponse> {
		const { pullEndpoint } = environment.replicache;
		const params: Record<string, string> = {};
		const headers = new HttpHeaders().set("X-Replicache-RequestID", requestID);
		const nextPartitionKey = this.pokePartitionKeyQueue.dequeue();
		if (nextPartitionKey) params["partitionKey"] = nextPartitionKey;
		const response = await firstValueFrom(
			this.httpClient.post<PullRequestResponse>(pullEndpoint, requestBody, {
				headers,
				params,
			}),
		);
		return response;
	}

	private _buildError(
		error: unknown,
		httpRequestInfo: HTTPRequestInfo,
	): { httpRequestInfo: HTTPRequestInfo } {
		console.error("Pull error:", error);
		if (error instanceof HttpErrorResponse) {
			httpRequestInfo.httpStatusCode = error.status;
			httpRequestInfo.errorMessage = error.error.message || error.message;
		} else {
			httpRequestInfo.httpStatusCode = 500;
			httpRequestInfo.errorMessage = JSON.stringify(error);
		}
		return { httpRequestInfo };
	}
}
