import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, map } from "rxjs";

import type { Campaign, CreateCampaignInput } from "src/app/schema";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class CampaignService {
	startDate: Date = new Date();
	endDate: Date = new Date();

	constructor(private httpClient: HttpClient) {
		this.startDate.setDate(this.startDate.getDate() - 30);
	}

	public createCampaign(campaign: CreateCampaignInput): Observable<Campaign> {
		return this.httpClient
			.post<Campaign>(`${environment.marketingEndpoint}/campaign`, campaign)
			.pipe(map((campaign) => campaign));
	}

	public campaignsByProperty(propertyId: string): Observable<Campaign[]> {
		return this.httpClient
			.get<{ items: Campaign[] }>(
				`${environment.marketingEndpoint}/properties/${propertyId}/campaigns`,
			)
			.pipe(map((response) => response.items));
	}

	public getCampaign(campaignId: string): Observable<Campaign> {
		return this.httpClient
			.get<Campaign>(`${environment.marketingEndpoint}/campaign/${campaignId}`)
			.pipe(map((campaign) => campaign));
	}
}
