import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

// declare const window: any;

@Injectable({
  providedIn: "root"
})

export class AdobeEmbedService {

  constructor(@Inject(DOCUMENT) private _doc: Document) {}

  getWindow(): any {
    return this._doc.defaultView;
  }
  adobeDCView: any;

  readyPromise: Promise<any> = new Promise<void>((resolve) => {
    const window = this.getWindow();
    if (window.AdobeDC) {
      resolve();
    } else {
      /* Wait for Adobe Document Services PDF Embed API to be ready */
      document.addEventListener("adobe_dc_view_sdk.ready", () => {
        resolve();
      });
    }
  });

  ready() {
    return this.readyPromise;
  }

  previewFile(divId: string, viewerConfig: any, url: string, filename: string, fileId: string) {
    const window = this.getWindow();

    const config: any = {
      /* LeadReel Platform Client ID */
      clientId: environment.adobeClientId
    };

    if (divId) { /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url: url,
        },
      },
      /* Pass meta data of file */
      metaData: {
        fileName: filename,
        id: fileId,
      }
    }, viewerConfig);

    return previewFilePromise;
  }

}
