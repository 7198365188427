import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type {
	ApplicationRole,
	ApplicationStatus,
	ConcessionType,
	PropertyType,
	UnitType,
} from "../../enumerations";
import type { UnitImage } from "../inventory";
import type { RawAddress } from "../utilities";
import type { CreateApplicantInput } from "./applicant";
import type {
	ApplicationFeeComponent,
	ApplicationFeePayment,
} from "./application-fee-payment";
import { ReactiveQuery } from "src/app/utils";

export namespace ApplicationReplicache {
	export const prefix = "application";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyApplicationsId",
			prefix: `${prefix}/`,
		},
	};

	export const GetApplicationById: ReactiveQuery<Application, string> = (id) => async (tx) => {
		return await tx.get(`${prefix}/${id}`) as unknown as Application;
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Application {
	__typename: "Application";
	id: string;
	createdAt: string;
	updatedAt: string;
	read: boolean;
	status: ApplicationStatus;
	applicants: ApplicationApplicant[];
	applicationFeePayments?: ApplicationFeePayment[] | null;
	rent?: number | null;
	deposit?: number | null;
	leaseTermPreference?: number | null;
	leaseTerm?: number | null;
	moveInDate?: string | null;
	leaseStartDate?: string | null;
	leaseEndDate?: string | null;
	dateStarted: string;
	dateCompleted?: string | null;
	dateApproved?: string | null;
	dateRejected?: string | null;
	lastContact?: string | null;
	completedByAgent?: boolean | null;
	activePredeposit?: boolean | null;
	reservationDate?: string | null;
	reservationAmount?: number | null;
	amountPredepositCharged?: number | null;
	reservationFee?: number | null;
	totalApplicationFeePaid?: boolean | null;
	totalApplicationFeeAmount?: number | null;
	totalApplicationFeePaidDate?: string | null;
	userApproval?: ApplicationUser | null;
	userRejection?: ApplicationUser | null;
	documentsApproved?: boolean | null;
	depositPaid?: boolean | null;
	depositAmount?: number | null;
	dateDepositPaid?: string | null;
	leaseSigned?: boolean | null;
	dateLeaseSigned?: string | null;
	feePaid?: boolean | null;
	applicationFee?: number | null;
	processingFee?: number | null;
	feeComponents?: ApplicationFeeComponent[] | null;
	dateApplicationFeePaid?: string | null;
	concessionType?: ConcessionType | null;
	numMonthsConcession?: number | null;
	concessionAmount?: number | null;
	concessionDescription?: string | null;
	ownerPaysBrokerFee?: boolean | null;
	unit?: ApplicationUnit | null;
	unitApplicationsId?: string | null;
	user?: ApplicationUser | null;
	userApplicationsId?: string | null;
	property: ApplicationProperty;
	propertyApplicationsId: string;
	propertyGroupApplicationsId: string;
	organizationApplicationsId: string;
}

export type CreateApplicationInput = {
	id?: string | null;
	leaseTerm?: number | null;
	moveInDate?: string | null;
	applicants: Array<CreateApplicantInput>;
	unitApplicationsId: string;
	propertyApplicationsId: string;
};

type ApplicationProperties = Omit<Application, "__typename">;
export type UpdateApplicationInput = Partial<ApplicationProperties> & {
	id: string;
};

export type ApplicationUnit = {
	id: string;
	type: UnitType;
	name: string;
	numBedrooms: number;
	numBaths: number;
	squareFootage: number;
	rent: number;
	images?: UnitImage[] | null;
	deposit?: number | null;
	availableStarting: string;
};

export type ApplicationApplicant = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	role: ApplicationRole;
};

export type ApplicationProperty = {
	id: string;
	type: PropertyType;
	name: string;
	address: RawAddress;
};

export type ApplicationUser = {
	id: string;
	favicon?: string | null;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string | null;
};

export type ApplicationPolicy = {
	name: string;
	description: string;
};

export type ApplicantBreakdown = {
	id: string;
	applicationFee: number;
	firstName: string;
	lastName: string;
	role: ApplicationRole;
	pets?: PetBreakdown[];
};

export type PetBreakdown = {
	id: string;
	fee: number;
	name: string;
	type: string;
	serviceAnimal: boolean;
};
