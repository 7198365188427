import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class Logger {
	devMode = !environment.production;

	log(...message: unknown[]) {
		if (this.devMode) {
			console.log(message);
		} else {
			const messageString = message.join(" ");
			Sentry.captureMessage(messageString, "info");
		}
	}

	error(...message: unknown[]) {
		if (this.devMode) {
			console.error(message);
		} else {
			const messageString = message.join(" ");
			Sentry.captureException(messageString);
		}
	}

	warn(...message: unknown[]) {
		if (this.devMode) {
			console.warn(message);
		} else {
			const messageString = message.join(" ");
			Sentry.captureMessage(messageString, "warning");
		}
	}

	info(...message: unknown[]) {
		if (this.devMode) {
			console.info(message);
		} else {
			const messageString = message.join(" ");
			Sentry.captureMessage(messageString, "info");
		}
	}

	debug(...message: unknown[]) {
		if (this.devMode) {
			console.debug(message);
		} else {
			const messageString = message.join(" ");
			Sentry.captureMessage(messageString, "debug");
		}
	}

	trace(...message: unknown[]) {
		if (this.devMode) {
			console.trace(message);
		} else {
			const messageString = message.join(" ");
			Sentry.captureMessage(messageString, "log");
		}
	}
}
