import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import type {
	HTTPRequestInfo,
	PushRequest,
	PushResponse,
	Pusher,
	PusherResult,
} from "replicache";
import { firstValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class PushService {
	constructor(private httpClient: HttpClient) {}

	public pusher: Pusher = async (
		requestBody: PushRequest,
		requestID: string,
	): Promise<PusherResult> => {
		const httpRequestInfo: HTTPRequestInfo = {
			httpStatusCode: 200,
			errorMessage: "",
		};
		try {
			const response = await this._executePush(requestBody, requestID);
			return { response, httpRequestInfo };
		} catch (error) {
			return this._buildError(error, httpRequestInfo);
		}
	};

	private async _executePush(
		requestBody: PushRequest,
		requestID: string,
	): Promise<PushResponse | undefined> {
		const { pushEndpoint } = environment.replicache;
		const headers = new HttpHeaders().set("X-Replicache-RequestID", requestID);
		await firstValueFrom(
			this.httpClient.post<PushResponse>(pushEndpoint, requestBody, {
				headers,
			}),
		);
		return;
	}

	private _buildError(
		error: unknown,
		httpRequestInfo: HTTPRequestInfo,
	): { httpRequestInfo: HTTPRequestInfo } {
		if (error instanceof HttpErrorResponse) {
			httpRequestInfo.httpStatusCode = error.status;
			httpRequestInfo.errorMessage = error.error.message || error.message;
		} else {
			httpRequestInfo.httpStatusCode = 500;
			httpRequestInfo.errorMessage = JSON.stringify(error);
		}
		return { httpRequestInfo };
	}
}
