export type UserAttributes = {
	id: string;
	organizationId: string;
	idToken: string;
	idTokenExpiration: number;
	role: string;
	name: string;
	firstName: string;
	lastName: string;
	email: string;
	initials: string;
};

export enum SignInResult {
	Success = "Success",
	InvalidPassword = "InvalidPassword",
	NewPasswordRequired = "NewPasswordRequired",
	MFARequired = "MFARequired",
	UserNotFound = "UserNotFound",
	UserNotConfirmed = "UserNotConfirmed",
	UnknownError = "UnknownError",
}

export enum ChangePasswordResult {
	Success = "Success",
	InvalidFormat = "InvalidFormat",
	UnknownError = "UnknownError",
	NoCurrentUser = "NoCurrentUser",
}

export interface NewPasswordRequirments {
	minLength: number;
	minNumbers: number;
	minSpecialCharacters: number;
	minUppercase: number;
	minLowercase: number;
}

export const NEW_PASSWORD_REQUIREMENTS: NewPasswordRequirments = {
	minLength: 8,
	minNumbers: 1,
	minSpecialCharacters: 1,
	minUppercase: 1,
	minLowercase: 1,
};
