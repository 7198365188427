import { Injectable, NgZone, isDevMode } from "@angular/core";
import type { User } from "src/app/schema";
import { DebugService } from "src/app/services";
import { environment } from "src/environments/environment";

const ENABLE_IN_DEV_MODE = false;

@Injectable({
	providedIn: "root",
})
export class OpenReplayService {
	private _tracker: any | undefined;

	constructor(
		private ngZone: NgZone,
		private debugService: DebugService,
	) {}

	public async start(): Promise<void> {
		if (this._tracker) return;
		if (isDevMode() && !ENABLE_IN_DEV_MODE) return;
		await this._start();
	}

	public identifyUser(user: User): void {
		this.ngZone.runOutsideAngular(() => {
			if (this._tracker) {
				this._tracker.setUserID(user.id);
				this._tracker.setMetadata("firstName", user.firstName);
				this._tracker.setMetadata("lastName", user.lastName);
			}
		});
	}

	private async _start(): Promise<void> {
		const Tracker = (await import("@openreplay/tracker")).default;
		const TrackerAssist = (await import("@openreplay/tracker-assist")).default;
		const { projectKey } = environment.openReplay;
		// Use runOutsideAngular to avoid zone polution.
		// https://docs.openreplay.com/en/using-or/angular
		this.ngZone.runOutsideAngular(() => {
			this._tracker = new Tracker({
				projectKey,
				obscureInputDates: false,
				obscureInputEmails: false,
				obscureInputNumbers: false,
				obscureTextEmails: false,
				obscureTextNumbers: false,
				defaultInputMode: 0,
				__DISABLE_SECURE_MODE: isDevMode(),
			});
			const onRemoteControlStart = () => {
				console.log("Remote control session started.");
				this.ngZone.run(() => {
					this.debugService.enable();
				});
				const onRemoteControlEnd = () => {
					console.log("Remote control session stopped.");
					this.ngZone.run(() => {
						this.debugService.disable();
					});
				};
				return onRemoteControlEnd;
			};
			this._tracker.use(
				TrackerAssist({
					onRemoteControlStart,
				}),
			);
			this._tracker.start();
		});
	}
}
