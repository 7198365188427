import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, map } from "rxjs";
import {
	type CreateOpenHouseInput,
	type OpenHouse,
	type UpdateOpenHouseInput,
} from "src/app/schema";
import {
	ApiService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class OpenHouseService {

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
	) {}

	public createOpenHouse(
		openHouse: CreateOpenHouseInput,
	): Observable<OpenHouse> {
		return this.httpClient.post<OpenHouse>(
			`${this.api.inventoryApiEndpoint}/openhouse`,
			openHouse,
		);
	}

	public updateOpenHouse(
		openHouse: UpdateOpenHouseInput,
	): Observable<OpenHouse> {
		return this.httpClient.put<OpenHouse>(
			`${this.api.inventoryApiEndpoint}/openhouse`,
			openHouse,
		);
	}

	public deleteOpenHouse(openHouse: OpenHouse): Observable<void> {
		return this.httpClient
			.delete<{ id: string }>(
				`${this.api.inventoryApiEndpoint}/openhouse/${openHouse.id}`,
			)
			.pipe(map(() => {}));
	}

}