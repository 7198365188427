import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type {
	CreateWebPushNotificationSubscription,
	Notification,
} from "src/app/schema";
import { CognitoService } from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	constructor(
		private httpClient: HttpClient,
		private cognitoService: CognitoService,
	) {}

	public addWebpushSubscription(subscription: PushSubscription) {
		const { id } = this.cognitoService.user();
		const subscriptionData: CreateWebPushNotificationSubscription = {
			id,
			date: new Date().toISOString(),
			device: "Desktop",
			subscription: subscription,
		};
		return this.httpClient.post(
			`${environment.coreEndpoint}/notifications/${id}/webpush/subscribe`,
			subscriptionData,
		);
	}

	public notifications(): Observable<Notification[]> {
		const { id } = this.cognitoService.user();
		return this.httpClient.get<Notification[]>(
			`${environment.coreEndpoint}/notifications/${id}`,
		);
	}
}
