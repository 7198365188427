import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { PropertyType } from "../../enumerations";
import type { RawAddress } from "../utilities";

export namespace ImageReplicache {
	export const prefix = "image";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyImagesId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Image {
	id: string;
	createdAt: string;
	updatedAt: string;
	__typename: "Image";
	__deleted: number;
	favorite?: boolean | null;
	thumbnail: string;
	link: string;
	name: string;
	description?: string | null;
	height?: number | null;
	width?: number | null;
	size?: number | null;
	tags?: Array<string> | null;
	defaultOrder?: number | null;
	user?: ImageUser | null;
	userImagesId?: string | null;
	unitImagesId?: string | null;
	floorplanImagesId?: string | null;
	property: ImageProperty;
	propertyImagesId: string;
}

type ImageProperties = Omit<Image, "__typename">;

export type CreateImageInput = Partial<ImageProperties>;
export type UpdateImageInput = Partial<ImageProperties> & { id: string };

export type ImageProperty = {
	id: string;
	name: string;
	type: PropertyType;
	address: RawAddress;
};

export type ImageUser = {
	id: string;
	firstName: string;
	lastName: string;
};
