import { Injectable, type WritableSignal, inject, signal } from "@angular/core";
import type { IClientOptions, ISubscriptionGrant, MqttClient } from "mqtt";
import { nanoid } from "nanoid";
import { Subject } from "rxjs";

import { CognitoService, Logger } from "src/app/services";
import { environment } from "src/environments/environment";
import {
	MQTTEvent,
	MQTT_EVENTS,
	type PokeEvent,
	type StreamableEvent,
	StreamableEventAction,
} from ".";

@Injectable({
	providedIn: "root",
})
export class PokeService {
	private readonly _logger = inject(Logger);
	private readonly _cognitoService = inject(CognitoService);

	private _mqttClient: MqttClient | undefined;
	// private _reconnectionManager: ReconnectionManager = new ReconnectionManager();

	public readonly onPoke$ = new Subject<PokeEvent>();
	public readonly connected: WritableSignal<boolean | null> = signal(null);

	public async connect(): Promise<void> {
		if (!this._mqttClient) this._mqttClient = await this._createMqttClient();
		if (this._mqttClient.connected)
			return this._logger.warn(
				"Already connected to MQTT broker.",
				this._mqttClient.connected,
			);
		if (this._mqttClient.reconnecting)
			return this._logger.warn(
				"Already reconnecting to MQTT broker. Call disconnect() first.",
			);
		this._registerEventListeners();
		this._mqttClient.connect();
	}

	public async disconnect(): Promise<void> {
		if (!this._mqttClient)
			return this._logger.warn("MQTT client not initialized.");
		// this._reconnectionManager.unbind();
		await this._mqttClient.endAsync();
		this._mqttClient.removeAllListeners();
		this._mqttClient = undefined;
	}

	private async _createMqttClient(): Promise<MqttClient> {
		const { default: mqtt } = await import("mqtt");
		const { endpoint, topicPrefix } = environment.replicache.iot;
		const transformWsUrl = (
			url: string,
			options: IClientOptions,
			_: MqttClient,
		) => {
			const { id: username, idToken: password } = this._cognitoService.user();
			options.clientId = `client-${nanoid(19)}`;
			options.username = username;
			options.password = password;
			return url;
		};
		const mqttClient = mqtt.connect(endpoint, {
			manualConnect: true,
			resubscribe: true,
			transformWsUrl,
			keepalive: 1,
		});
		mqttClient.subscribe(`${topicPrefix}/properties/#`, (...args) =>
			this._handleEvent(MQTTEvent.SUBSCRIBE, ...args),
		);
		mqttClient.subscribe(
			`${topicPrefix}/users/${this._cognitoService.user().id}`,
			(...args) => this._handleEvent(MQTTEvent.SUBSCRIBE, ...args),
		);
		// this._reconnectionManager.bind(mqttClient);
		return mqttClient;
	}

	private _registerEventListeners(): void {
		if (!this._mqttClient)
			return this._logger.warn("MQTT client not initialized.");
		for (const event of MQTT_EVENTS) {
			this._mqttClient.on(event, (...args: unknown[]) =>
				this._handleEvent(event, ...args),
			);
		}
	}

	private _handleEvent(event: MQTTEvent, ...args: unknown[]) {
		switch (event) {
			case MQTTEvent.CONNECT:
				this._logger.info("Connected to MQTT broker.");
				break;
			case MQTTEvent.SUBSCRIBE: {
				const [err, granted] = args as [Error | null, ISubscriptionGrant[]];
				if (err) {
					this._logger.error("Error subscribing to topic:", err);
					return;
				}
				if (granted.length === 0) return;
				const [{ topic }] = granted;
				this._logger.info("Subscribed to topic:", topic);
				break;
			}
			case MQTTEvent.MESSAGE: {
				const [_, message] = args as [string, Buffer];
				this._handleMessage(JSON.parse(message.toString()));
				break;
			}
			case MQTTEvent.RECONNECT:
				this._logger.info("Reconnecting to MQTT broker...");
				break;
			case MQTTEvent.DISCONNECT:
				this._logger.info("Disconnected from MQTT broker.");
				break;
			case MQTTEvent.ERROR:
				this._logger.error("MQTT error:", ...args);
				break;
			case MQTTEvent.CLOSE:
				this.connected.set(false);
				this._logger.info("Connection to MQTT broker closed.");
				break;
			case MQTTEvent.OFFLINE:
				this._logger.info("MQTT broker is offline.");
				break;
			case MQTTEvent.END:
				this._logger.info("Connection to MQTT broker ended.");
				break;
		}
	}

	private _handleMessage(event: StreamableEvent): void {
		console.log("Received message:", event);
		switch (event.action) {
			case StreamableEventAction.CONNECTED:
				this.connected.set(true);
				// this._reconnectionManager.connected();
				break;
			case StreamableEventAction.POKE:
				this.onPoke$.next(event as PokeEvent);
				break;
			default:
				this._logger.warn("Unknown event action:", JSON.stringify(event));
				break;
		}
	}
}
