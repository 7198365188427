import { Injectable } from "@angular/core";
import type { TwComboBoxItem } from "@arch-intelligence/ngx-arch";
import {
	ApplicationDocumentType,
	GuarantorPolicy,
	Parking,
	VaccinationType,
} from "src/app/schema";

@Injectable({
	providedIn: "root",
})
export class ConfigurationService {
	petOptions: TwComboBoxItem[] = [
		{ label: "Dog", value: "Dog" },
		{ label: "Cat", value: "Cat" },
	];

	referralSourcesOptions: TwComboBoxItem[] = [
		{ label: "Waitlist", value: "Waitlist" },
		{ label: "Resident", value: "Resident" },
		{ label: "Building Sign", value: "Building Sign" },
		{ label: "Website", value: "Website" },
		{ label: "Google", value: "Google" },
		{ label: "Broker", value: "Broker" },
		{ label: "WOM", value: "WOM" },
	];

	reasonsForLookingOptions: TwComboBoxItem[] = [
		{ label: "Job Relocation", value: "Job Relocation" },
		{ label: "Need more Space", value: "Need more Space" },
		{ label: "Need less Space", value: "Need less Space" },
		{ label: "Rent vs Own", value: "Rent vs Own" },
		{ label: "Lifestyle Change", value: "Lifestyle Change" },
		{ label: "Location", value: "Location" },
		{ label: "Pied-à-terre", value: "Pied-à-terre" },
		{ label: "Amenities", value: "Amenities" },
		{ label: "New Construction", value: "New Construction" },
		{ label: "Corporate", value: "Corporate" },
		{ label: "For Child", value: "For Child" },
		{ label: "Save Money/Spend Less", value: "Save Money/Spend Less" },
	];

	presentResidenceOptions: TwComboBoxItem[] = [
		{ label: "Own", value: "Own" },
		{ label: "Rent", value: "Rent" },
		{ label: "Family", value: "Family" },
		{ label: "Corporate Housing", value: "Corporate Housing" },
	];

	leaseTermOptions: TwComboBoxItem[] = [
		{ label: "3 months", value: 3 },
		{ label: "4 months", value: 4 },
		{ label: "5 months", value: 5 },
		{ label: "6 months", value: 6 },
		{ label: "7 months", value: 7 },
		{ label: "8 months", value: 8 },
		{ label: "9 months", value: 9 },
		{ label: "10 months", value: 10 },
		{ label: "11 months", value: 11 },
		{ label: "12 months", value: 12 },
		{ label: "13 months", value: 13 },
		{ label: "14 months", value: 14 },
		{ label: "15 months", value: 15 },
		{ label: "16 months", value: 16 },
		{ label: "17 months", value: 17 },
		{ label: "18 months", value: 18 },
		{ label: "19 months", value: 19 },
		{ label: "20 months", value: 20 },
		{ label: "21 months", value: 21 },
		{ label: "22 months", value: 22 },
		{ label: "23 months", value: 23 },
		{ label: "24 months", value: 24 },
		{ label: "25 months", value: 25 },
		{ label: "26 months", value: 26 },
	];

	parkingOptions: TwComboBoxItem[] = [
		{ label: "No Parking", value: Parking.NoParking },
		{ label: "Parking Spot", value: Parking.ParkingSpot },
		{ label: "Garage", value: Parking.Garage },
		{ label: "Valet", value: Parking.Valet },
		{ label: "Carport", value: Parking.Carport },
		{ label: "Street Parking", value: Parking.StreetParking },
	];

	concessionOptions: TwComboBoxItem[] = [{ label: "None", value: 0 }].concat(
		[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4].map((value) => {
			return { label: `${value} Months Free`, value: value };
		}),
	);
	commissionOptions: TwComboBoxItem[] = [{ label: "None", value: 0 }].concat(
		[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4].map((value) => {
			return { label: `${value} Months Rent`, value: value };
		}),
	);

	minIncomeReqRange: TwComboBoxItem[] = [35, 36, 37, 38, 39, 40]
		.map((value) => {
			return { label: `${value}x Monthly Rent`, value: value };
		})
		.concat([{ label: "None", value: 0 }]);
	guarantorPolicies: TwComboBoxItem[] = [
		{ value: GuarantorPolicy.Allowed, label: "Allowed" },
		{ value: GuarantorPolicy.NotAllowed, label: "Not Allowed" },
		{ value: GuarantorPolicy.Student, label: "Allowed for Students Only" },
	];

	petVaccinations: TwComboBoxItem[] = [
		{ value: VaccinationType.CanineParvovirus, label: "Canine Parvovirus" },
		{ value: VaccinationType.CanineDistemper, label: "Canine Distemper" },
		{ value: VaccinationType.Hepatitis, label: "Hepatitis" },
		{ value: VaccinationType.Rabies, label: "Rabies" },
		{ value: VaccinationType.Bordetella, label: "Bordetella" },
		{ value: VaccinationType.CanineInfluenza, label: "Canine Influenza" },
		{ value: VaccinationType.LeptoSpirosis, label: "Lepto Spirosis" },
		{ value: VaccinationType.LymeDisease, label: "Lyme Disease" },
	];
	applicationDocumentTypes: TwComboBoxItem[] = [
		{ value: ApplicationDocumentType.W9, label: "W9" },
		{ value: ApplicationDocumentType.BankStatement, label: "Bank Statement" },
		{ value: ApplicationDocumentType.PayStub, label: "Pay Stub" },
		{ value: ApplicationDocumentType.PetVaccination, label: "Pet Vaccination" },
		{ value: ApplicationDocumentType.Other, label: "Other" },
	];
	petDocumentTypes: TwComboBoxItem[] = [
		{ value: ApplicationDocumentType.PetVaccination, label: "Pet Vaccination" },
		{
			value: ApplicationDocumentType.PetAttestation,
			label: "Service Animal Attestation",
		},
		{ value: ApplicationDocumentType.Other, label: "Other" },
	];

	unitSizeOptions: TwComboBoxItem[] = [
		{ value: 0, label: "Studio" },
		{ value: 0.5, label: "Alcove" },
		{ value: 1, label: "1 Bedroom" },
		{ value: 1.5, label: "1 Bedroom + Den" },
		{ value: 2, label: "2 Bedroom" },
		{ value: 2.5, label: "2 Bedroom + Den" },
		{ value: 3, label: "3 Bedroom" },
	];
}
