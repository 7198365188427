import type {
	CognitoUser,
	CognitoUserSession,
} from "amazon-cognito-identity-js";

export const SessionResolver = async (
	cognitoUser: CognitoUser | null,
): Promise<CognitoUserSession | null> => {
	if (!cognitoUser) return null;
	return new Promise((resolve) => {
		cognitoUser.getSession(
			(error: Error | null, session: CognitoUserSession | null) => {
				resolve(error || !session?.isValid() ? null : session);
			},
		);
	});
};
