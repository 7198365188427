import type {
	IndexDefinitions,
} from "replicache";
import { Replicacheable } from "src/app/services";

export namespace TemplateReplicache {
	export const prefix = "template";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyTemplatesId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Template {
	__typename: "Template";
	id: string;
	createdAt: string;
	updatedAt: string;
	name: string;
	description?: string | null;
	plainText: string | null;
	thumbnail: string;
	grapesTemplate: any | null;
	userTemplatesId: string;
	propertyTemplatesId: string;
	organizationTemplatesId: string;
	emailContents: string;
	emailSubject: string;
}

type TemplateProperties = Omit<Template, "__typename">;

export type CreateTemplateInput = Partial<TemplateProperties> & {
	userTemplatesId: string;
	propertyTemplatesId: string;
	organizationTemplatesId: string;
};

export type UpdateTemplateInput = Partial<TemplateProperties> & {
	id: string;
};
