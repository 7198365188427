import type { IndexDefinitions, MutatorDefs } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type {
	BookingSource,
	PropertyType,
	TourEventType,
	TourProvider,
	TourStatus,
	TourType,
	UnitType,
} from "../../enumerations";
import type { Property } from "../inventory";
import type { User } from "../users";
import type { RawAddress } from "../utilities";
import { ReactiveQuery } from "src/app/utils";

export namespace TourReplicache {
	export const prefix = "tour";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
		byUnitId = `${prefix}/byUnitId`,
		byLeadId = `${prefix}/byLeadId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyToursId",
			prefix: `${prefix}/`,
		},
		[IndexNames.byUnitId]: {
			jsonPointer: "/unitToursId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
		[IndexNames.byLeadId]: {
			jsonPointer: "/leadToursId",
			prefix: `${prefix}/`,
		},
	};

	export const GetTourById: ReactiveQuery<Tour, string | undefined> = (id) => async (tx) => {
		return await tx.get(`${prefix}/${id}`) as unknown as Tour;
	};

	export enum MutatorNames {
		RescheduleTour = "rescheduleTour",
	}

	export const MutatorDefinitions: MutatorDefs = {
		[MutatorNames.RescheduleTour]: async (tx, update: RescheduleTourInput) => {
			const key = `${prefix}/${update.id}`;
			const tour = (await tx.get(key)) as any;
			const updatedTour = { ...tour, ...update };
			await tx.set(key, updatedTour);
			return updatedTour;
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
		MutatorDefinitions,
	};
}

export interface Tour {
	__typename: "Tour";
	id: string;
	createdAt: string;
	updatedAt: string;
	startDate: string; // ISO-8601 formatted start date with TZ. e.g. 2019-01-01T00:00:00-05:00
	endDate: string; // ISO-8601 formatted end date with TZ. e.g. 2019-01-01T00:00:00-05:00
	type: TourType; // Whether the tour is "InPerson" or "Virtual"
	status: TourStatus; // Whether the tour is "Pending" confirmation, "Confirmed", "Canceled", "Rescheduled", or a "NoShow"
	events: TourEvent[];
	canceled: boolean; // True if the tour has been canceled
	cancellationDate?: string | null; // ISO-8601 formatted cancellation date with TZ. e.g. 2019-01-01T00:00:00-05:00
	canceledBy?: string | null; // User ID who canceled the tour (if canceled via Platform by a Leasing Agent)
	rescheduled: boolean; // True if the tour has been rescheduled
	rescheduleDate?: string | null; // ISO-8601 formatted reschedule date with TZ. e.g. 2019-01-01T00:00:00-05:00
	rescheduledBy?: string | null; // User ID who rescheduled the tour
	moveInDate?: string | null; // "YYYY-MM-DD" formatted move-in date
	apartmentSizes?: Array<number> | null; // Array of apartment sizes. e.g. [1, 2, 3]
	leaseTerms?: Array<number> | null; // Array of lease terms that the tour attende would like to book. e.g. [12, 24, 36]
	budget?: { min: number; max: number } | null; // Object with min and max budget values. e.g. {min: 1000, max: 2000}
	externalId?: string | null; // External ID of the tour. Used to load in case of rescheduling / cancellation.
	googleId?: string | null; // Google Calendar ID of the tour. Used to load in case of rescheduling / cancellation.
	cancellationReasons?: Array<string> | null; // Array of cancellation reasons. e.g. ["No longer interested", "Found a better deal"]
	noShow?: boolean | null; // True if the tour was a no-show
	postTourEvalCompleted?: boolean | null; // True if the post tour evaluation has been completed
	source?: BookingSource | null; // Source of the tour booking. e.g. "Automatic", "TourScheduler", "Agent"
	read?: boolean | null; // Whether the tour booking has been seen by an agent.
	emailSendToursId?: string | null; // ID of the email campaign that led to a tour-booking converision
	textSendToursId?: string | null; // ID of the text campaign that led to a tour-booking converision
	campaignToursId?: string | null; // ID of the campaign that led to a tour-booking converision
	lead: TourLead; // Lead who is attending the tour
	leadToursId: string; // ID of the lead who is attending the tour
	bookingUserToursId?: string | null; // ID of the user who booked the tour (in case the tour was booked by a user)
	userToursId?: string | null; // ID of the user who is assigned to give the tour
	user?: User | null; // User who is assigned to give the tour
	unitIds: Array<string>; // Array of unit IDs that the tour is for
	unit?: TourUnit | null; // Unit that the tour is for
	unitToursId?: string | null; // ID of the unit that the tour is for
	property?: Property | null; // Property that the tour is for
	propertyToursId?: string | null; // ID of the property that the tour is for
	organizationToursId?: string | null; // ID of the organization that the tour is for
}

type TourProperties = Omit<Tour, "__typename">;

export type CreateTourInput = Partial<TourProperties>;
export type UpdateTourInput = Partial<TourProperties> & { id: string };

export type TourUser = {
	id: string;
	firstName: string;
	lastName: string;
};

export type TourProperty = {
	id: string;
	type: PropertyType;
	name: string;
	address: RawAddress;
};

export type TourUnit = {
	id: string;
	type: UnitType;
	name: string;
	numBedrooms: number;
	numBaths: number;
	squareFootage: number;
	rent: number;
	deposit?: number | null;
	availableStarting: string;
};

export type TourLead = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	contactSource: string;
};

export type TourEvent = {
	__typename: "TourEvent";
	id: string;
	type: TourEventType;
	date: string;
	tourType: TourType;
	source: BookingSource;
	startDate: string;
	endDate: string;
	duration: number;
	user: TourUser;
};

export type BookTourNewLeadInput = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	contactSource: string;
	userId: string;
	propertyId?: string | null;
	pagename: string;
	provider: TourProvider;
	startDate: string;
	endDate: string;
	type: TourType;
	units: Array<string>;
	sendConfirmationEmail: boolean;
};

export type BookTourExistingLeadInput = {
	leadId: string;
	pagename: string;
	provider: TourProvider;
	startDate: string;
	endDate: string;
	type: TourType;
	userId: string;
	propertyId?: string | null;
	units: Array<string>;
	sendConfirmationEmail: boolean;
};

export type RescheduleTourInput = {
	id: string;
	startDate: string;
	endDate: string;
	type: TourType;
	userId: string;
	sendConfirmationEmail: boolean;
};

export type TourPreference = {
	__typename: "TourPreference";
	id: string;
	date: string;
	timePreference?: boolean | null;
};
