import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { Budget } from "..";
import type {
	CampaignType,
	MaritalStatus,
	PresentResidence,
	WorkFromHome,
} from "../../enumerations";

export namespace CallReplicache {
	export const prefix = "call";

	export enum IndexNames {
		byLeadId = `${prefix}/byLeadId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byLeadId]: {
			jsonPointer: "/leadCallsId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Call {
	__typename: "Call";
	id: string;
	createdAt: string;
	updatedAt: string;
	callerIntent?: string | null;
	redirect?: boolean | null;
	outgoing: boolean;
	read?: boolean | null;
	phoneNumber?: string | null;
	callerName?: string | null;
	callerType?: string | null;
	date: string;
	accepted?: boolean | null;
	duration?: number | null;
	notes?: string | null;
	transcript?: string | null;
	preview?: string | null;
	returnedCallId?: string | null;
	returnedCall?: boolean | null;
	returnedCallDate?: string | null;
	returnedCallAccepted?: boolean | null;
	returnedCallUserId?: string | null;
	twilioSid?: string | null;
	twilioAppId?: string | null;
	recordingSid?: string | null;
	budget?: Budget | null;
	moveInDate?: string | null;
	apartmentSizes?: number[] | null;
	referralSources?: string[] | null;
	residentReferralName?: string | null;
	car?: boolean | null;
	workFromHome?: WorkFromHome | null;
	presentResidence?: PresentResidence | null;
	maritalStatus?: MaritalStatus | null;
	reasonsForLooking?: string[] | null;
	campaign?: CallCampaign | null;
	campaignCallsId?: string | null;
	lead?: CallLead | null;
	leadCallsId?: string | null;
	user?: CallUser | null;
	userCallsId?: string | null;
	phoneNumberCallsId?: string | null;
	propertyCallsId?: string | null;
	propertyGroupCallsId?: string | null;
	organizationCallsId?: string | null;
}

type CallProperties = Omit<Call, "__typename">;

export type CreateCallInput = Partial<CallProperties>;
export type UpdateCallInput = Partial<CallProperties> & { id: string };

export type CallCampaign = {
	id: string;
	type: CampaignType;
	name: string;
};

export type CallUser = {
	id: string;
	firstName: string;
	lastName: string;
};

export type CallLead = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
};
