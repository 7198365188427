import { Injectable, type OnDestroy, isDevMode } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Notifications } from "@arch-intelligence/ngx-arch";
import { Subject, takeUntil, timer } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class UpdateService implements OnDestroy {
	private readonly POLLING_INTERVAL: number = 1000 * 60; // Every minute.

	private _destroyed$: Subject<boolean> = new Subject<boolean>();

	private _notificationOpen = false;

	constructor(
		private updates: SwUpdate,
		private notifications: Notifications,
	) {}

	ngOnDestroy(): void {
		this._destroyed$.next(true);
		this._destroyed$.complete();
	}

	public pollForUpdates(): void {
		if (isDevMode()) return;
		const poll$ = timer(0, this.POLLING_INTERVAL);
		poll$.pipe(takeUntil(this._destroyed$)).subscribe(async () => {
			try {
				const updateFound = await this.updates.checkForUpdate();
				if (updateFound) {
					console.info(
						"A new application version was found and is ready to be activated.",
					);
					this._promptUserToRefreshPage();
				} else {
					console.info("Application is up-to-date.");
				}
			} catch (error) {
				console.error("Failed to check for updates.", error);
			}
		});
	}

	private _promptUserToRefreshPage(): void {
		if (this._notificationOpen) return;
		this._notificationOpen = true;
		const reloadPage: () => void = () => {
			document.location.reload();
		};
		const onCancel: () => void = () => {
			this._notificationOpen = false;
		};
		this.notifications.confirm(
			"Update detected.",
			"A new version of the application is available. Please refresh the page to apply the update.",
			"Refresh",
			"primary",
			reloadPage,
			onCancel,
		);
	}
}
