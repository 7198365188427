import type { CognitoUserSession } from "amazon-cognito-identity-js";
import type { UserAttributes } from "../cognito.constants";

export const UserAttributesResolver = (
	session: CognitoUserSession | null,
): UserAttributes | null => {
	if (!session) return null;
	const idToken = session.getIdToken();
	const idTokenJwt = idToken.getJwtToken();
	const idTokenExpiration = idToken.getExpiration();
	const idTokenPayload = idToken.payload;
	const {
		sub: id,
		name,
		given_name: firstName,
		family_name: lastName,
		email,
	} = idTokenPayload;
	const initials = firstName[0] + lastName[0];
	const role = idTokenPayload["custom:role"];
	const organizationId = idTokenPayload["custom:organizationId"];
	return {
		id,
		organizationId,
		idToken: idTokenJwt,
		idTokenExpiration,
		role,
		name,
		firstName,
		lastName,
		email,
		initials,
	};
};
