import type {
	IndexDefinitions,
	MutatorDefs,
	ReadTransaction,
	ScanIndexOptions,
} from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { Budget } from "..";
import type {
	Gender,
	LeadStatus,
	LeadType,
	MaritalStatus,
	PresentResidence,
	WorkFromHome,
} from "../../enumerations";
import type { AutopilotRecommendation } from "../communications/autopilot-reccomendation";

export namespace LeadReplicache {
	export const prefix = "lead";
	export const contactPrefix = "contact";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
		byCampaignId = `${prefix}/byCampaignId`,
		contactsByPropertyId = `${contactPrefix}/byPropertyId`,
		contactsByCampaignId = `${contactPrefix}/byCampaignId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyLeadsId",
			prefix: `${prefix}/`,
		},
		[IndexNames.byCampaignId]: {
			jsonPointer: "/campaignLeadsId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
		[IndexNames.contactsByPropertyId]: {
			jsonPointer: "/propertyLeadsId",
			prefix: `${contactPrefix}/`,
		},
		[IndexNames.contactsByCampaignId]: {
			jsonPointer: "/campaignLeadsId",
			prefix: `${contactPrefix}/`,
			allowEmpty: true,
		},
	};

	export const Resolvers = {
		LeadsByPropertyId: (propertyId: string) => {
			const scanOptions: ScanIndexOptions = {
				indexName: IndexNames.byPropertyId,
				start: { key: [propertyId] },
				prefix: propertyId,
			};
			const transaction = async (tx: ReadTransaction) =>
				await tx.scan(scanOptions).values().toArray();
			return transaction as unknown as (tx: ReadTransaction) => Promise<Lead[]>;
		},
		LeadById: (id: string) => {
			const transaction = async (tx: ReadTransaction) => await tx.get(`${prefix}/${id}`);
			return transaction as unknown as (tx: ReadTransaction) => Promise<Lead>;
		}
	};

	export enum MutatorNames {
		CreateLead = "createLead",
		UpdateLead = "updateLead",
	}

	export const MutatorDefinitions: MutatorDefs = {
		[MutatorNames.CreateLead]: async (tx, lead) => {
			const key = `${prefix}/${lead.id}`;
			await tx.set(key, lead);
		},
		[MutatorNames.UpdateLead]: async (tx, lead) => {
			const key = `${prefix}/${lead.id}`;
			await tx.set(key, lead);
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
		// MutatorDefinitions
	};
}

export interface Lead {
	id: string;
	createdAt: string;
	updatedAt: string;
	__typename: "Lead";
	type: LeadType;
	firstName?: string | null;
	middleName?: string | null;
	noMiddleName?: boolean | null;
	lastName?: string | null;
	suffix?: string | null;
	preferredName?: string | null;
	externalId?: string | null;
	preview?: string | null;
	summary?: string | null;
	status?: LeadStatus | null;
	autopilotRecommendation?: AutopilotRecommendation;
	email?: string | null;
	emailValidated?: boolean | null;
	secondaryEmail?: string | null;
	secondaryEmailValidated?: boolean | null;
	phoneNumber?: string | null;
	phoneNumberValidated?: boolean | null;
	secondaryPhoneNumber?: string | null;
	secondaryPhoneNumberValidated?: boolean | null;
	waitlist?: boolean | null;
	contacted: boolean;
	contactedDate?: string | null;
	awaitingResponse: boolean;
	hot: boolean;
	focused: boolean;
	spam: boolean;
	qualified: boolean;
	starred?: boolean | null;
	read?: boolean | null;
	notes?: string | null;
	archived?: boolean | null;
	unitIds?: Array<string> | null;
	contactSource?: string | null;
	reasonsForLooking?: Array<string> | null;
	referralSources?: Array<string> | null;
	residentReferralName?: string | null;
	workFromHome?: WorkFromHome | null;
	reasonsNotLeased?: Array<string> | null;
	presentResidence?: PresentResidence | null;
	maritalStatus?: MaritalStatus | null;
	gender?: Gender | null;
	cityOfEmployment?: string | null;
	pets?: Array<string> | null;
	car?: boolean | null;
	draft?: string | null;
	ageRange?: string | null;
	combinedIncome?: string | null;
	lengthAtPresentAddress?: string | null;
	address?: string | null;
	latitude?: number | null;
	longitude?: number | null;
	brokerage?: string | null;
	occupation?: string | null;
	employer?: string | null;
	moveInDate?: string | null;
	apartmentSizes?: Array<number> | null;
	budget?: Budget | null;
	leaseTerms?: Array<number> | null;
	dateOfBirth?: string | null;
	countriesOfCitizenship?: string[] | null;
	identityVerified?: boolean | null;
	tourBookingStarted?: boolean | null;
	tourBooked?: boolean | null;
	tourCompleted?: boolean | null;
	tourCanceled?: boolean | null;
	applicationStarted?: boolean | null;
	applicationSubmitted?: boolean | null;
	applicationApproved?: boolean | null;
	applicationRejected?: boolean | null;
	tenant?: boolean | null;
	unsubscribedFromMarketing?: boolean | null;
	unsubscribeData?: string | null;
	unsubscribeReasons?: Array<string> | null;
	dateAdded: string;
	focusedTimestamp: string;
	lastCommunication: string;
	lastLeadContact: string;
	lastInteraction: string;
	lastMissedCall?: string | null;
	lastReceivedCall?: string | null;
	lastMessageSent?: string | null;
	lastMessageReceived?: string | null;
	channelLeadsId?: string | null;
	campaignLeadsId?: string | null;
	googleAdGroupLeadsId?: string | null;
	googleKeywordLeadsId?: string | null;
	userLeadsId?: string | null;
	propertyLeadsId?: string | null;
	propertyGroupLeadsId?: string | null;
	organizationLeadsId?: string | null;
}

type LeadProperties = Omit<Lead, "__typename">;

export type CreateLeadInput = Partial<LeadProperties>;
export type UpdateLeadInput = Partial<LeadProperties> & { id: string };
