import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject } from "rxjs";

import {
	type Applicant,
	ApplicantReplicache,
	type UpdateApplicantInput,
} from "src/app/schema";
import { ReplicacheService } from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ApplicantService {
	constructor(
		private httpClient: HttpClient,
		private replicacheService: ReplicacheService,
	) {}

	public getApplicant(applicantId: string): Observable<Applicant> {
		const transaction = async (tx: ReadTransaction) =>
			await tx.get(`${ApplicantReplicache.prefix}/${applicantId}`);
		const applicant$ = new Subject<Applicant>();
		this.replicacheService.subscribe(transaction, (applicant) =>
			applicant$.next(applicant),
		);
		return applicant$;
	}

	public getApplicationIdentificationNumber(
		applicantId: string,
	): Observable<{ identificationNumber?: string | null }> {
		return this.httpClient.get<{ identificationNumber?: string | null }>(
			`${environment.applicationsEndpoint}/applicant/${applicantId}/identification`,
		);
	}

	public updateApplicant(
		applicant: UpdateApplicantInput,
	): Observable<Applicant> {
		return this.httpClient.put<Applicant>(
			`${environment.applicationsEndpoint}/applicant`,
			applicant,
		);
	}

	public loadApplicationApplicants(
		applicationId: string,
	): Observable<Applicant[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: ApplicantReplicache.IndexNames.byApplicationId,
			start: { key: [applicationId] },
			prefix: applicationId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const applicantsByApplicationId$ = new Subject<Applicant[]>();
		this.replicacheService.subscribe(transaction, (applicants) =>
			applicantsByApplicationId$.next(applicants),
		);
		return applicantsByApplicationId$;
	}
}
