import { HttpClient, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, type Observable, expand, map, of } from "rxjs";

import { LogLevel, Logger } from "./logger";

@Injectable({
	providedIn: "root",
})
export class APILoader extends HttpClient {
	private logger: Logger = new Logger("APILoader", { level: LogLevel.INFO });

	private isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		navigator.onLine,
	);

	constructor(_handler: HttpHandler) {
		super(_handler);
		window.addEventListener("online", () => this.isOnline$.next(true));
		window.addEventListener("offline", () => this.isOnline$.next(false));
	}

	public cacheAwareGet<T>(
		url: string,
		highestUpdatedAt: number | null,
	): Observable<T[]> {
		if (!this.isOnline$.value) {
			console.warn("Device is not connected to the internet.");
			return of([]);
		}

		const cachedHighestUpdatedAtTimestamp =
			this._getCachedHighestUpdatedAtTimestamp(url);
		const highestUpdatedAtTimestamp = highestUpdatedAt
			? new Date(highestUpdatedAt).toISOString()
			: undefined;
		if (highestUpdatedAtTimestamp)
			this._setCachedHighestUpdatedAtTimestamp(url, highestUpdatedAtTimestamp);
		const requestUrl = this._requestUrl(
			url,
			highestUpdatedAtTimestamp,
			cachedHighestUpdatedAtTimestamp,
		);

		return this.get<{
			items: T & { id: string; updatedAt: string }[];
			LastEvaluatedKey: string;
		}>(requestUrl).pipe(
			expand((response) => {
				if (response?.LastEvaluatedKey) {
					const nextUrl = this._requestUrl(
						url,
						highestUpdatedAtTimestamp,
						cachedHighestUpdatedAtTimestamp,
						response.LastEvaluatedKey,
					);
					return this.get<{ items: T[]; LastEvaluatedKey: string }>(nextUrl);
				}
				return EMPTY;
			}),
			map((response) => response.items),
		);
	}

	private _requestUrl(
		url: string,
		lastUpdatedTimestamp?: string,
		cachedHighestUpdatedAtTimestamp?: string,
		lastEvaluatedKey?: string,
	): string {
		let baseUrl = url.endsWith("/") ? url.slice(0, -1) : url;
		const params = new URLSearchParams();
		if (lastEvaluatedKey) {
			this.logger.info(
				`Using Last Evaluated Key ${lastEvaluatedKey} for ${url}`,
			);
			params.set("LastEvaluatedKey", lastEvaluatedKey);
		}
		if (lastUpdatedTimestamp) {
			if (cachedHighestUpdatedAtTimestamp) {
				if (
					new Date(lastUpdatedTimestamp) >
					new Date(cachedHighestUpdatedAtTimestamp)
				) {
					this.logger.info(
						`Using Cached Highest Updated At Timestamp ${cachedHighestUpdatedAtTimestamp} for ${url}`,
					);
					baseUrl += `/${cachedHighestUpdatedAtTimestamp}`;
				} else {
					this.logger.info(
						`Using Last Updated Timestamp ${lastUpdatedTimestamp} for ${url}`,
					);
					baseUrl += `/${lastUpdatedTimestamp}`;
				}
			} else {
				this.logger.info(
					"Cached Highest Updated At Timestamp is undefined. Using base url.",
				);
			}
		}
		return lastEvaluatedKey ? `${baseUrl}?${params.toString()}` : baseUrl;
	}

	private _getCachedHighestUpdatedAtTimestamp(url: string): string | undefined {
		const cachedHighestUpdatedAtTimestamp = localStorage.getItem(url);
		if (cachedHighestUpdatedAtTimestamp) return cachedHighestUpdatedAtTimestamp;
		return;
	}

	private _setCachedHighestUpdatedAtTimestamp(
		url: string,
		highestUpdatedAtTimestamp: string,
	): void {
		localStorage.setItem(url, highestUpdatedAtTimestamp);
	}
}
