import { Injectable } from "@angular/core";
import {
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
} from "@angular/router";
import { ReplaySubject, filter } from "rxjs";
import type { Property, PropertyGroup } from "src/app/schema";

@Injectable({
	providedIn: "root",
})
export class RouteService {
	selectedPropertyGroup: ReplaySubject<PropertyGroup | null> =
		new ReplaySubject<PropertyGroup | null>(1);
	selectedProperty: ReplaySubject<Property | null> =
		new ReplaySubject<Property | null>(1);

	constructor(private router: Router) {}

	subscribeToRouterEvents(): void {
		this.router.events
			.pipe(
				filter(
					(event) =>
						event instanceof NavigationStart ||
						event instanceof NavigationEnd ||
						event instanceof NavigationCancel ||
						event instanceof NavigationError,
				),
			)
			.subscribe((event: any) => {
				if (event instanceof NavigationStart) {
				}
				if (event instanceof NavigationEnd) {
					this.onRouteChange(event);
				}
				if (event instanceof NavigationCancel) {
				}
				if (event instanceof NavigationError) {
				}
			});
	}

	onRouteChange(event: any): void {
		// if (event.routerEvent?.url) {
		//   const url = event.routerEvent.url.split("/");
		//   if (url.includes("groups")) {
		//     if (this.persistence.get(this.propertyGroupId)) {
		//       this.selectedPropertyGroup.next(this.persistence.get(this.propertyGroupId));
		//     }
		//     this.propertyGroupService.getPropertyGroup(this.propertyGroupId).subscribe((propertyGroup) => {
		//       this.persistence.set(propertyGroup.id, propertyGroup);
		//       this.selectedPropertyGroup.next(propertyGroup);
		//     })
		//   } else {
		//     this.selectedPropertyGroup.next(null);
		//   }
		//   if (url.includes("properties") || url.includes("property")) {
		//     if (this.persistence.get(this.propertyId)) {
		//       this.selectedProperty.next(this.persistence.get(this.propertyId));
		//     }
		//     this.propertyService.getProperty(this.propertyId).subscribe((property) => {
		//       this.persistence.set(property.id, property);
		//       this.selectedProperty.next(property);
		//     })
		//   } else {
		//     this.selectedProperty.next(null);
		//   }
		// }
	}

	get propertyId() {
		const url = this.router.url.split("/");
		if (url.includes("properties")) {
			const propertyIndex = url.indexOf("properties") + 1;
			return url[propertyIndex];
		}
		const propertyIndex = url.indexOf("property") + 1;
		return url[propertyIndex];
	}

	get propertyGroupId() {
		const url = this.router.url.split("/");
		const groupIndex = url.indexOf("groups") + 1;
		return url[groupIndex];
	}

	get unitId() {
		const url = this.router.url.split("/");
		if (url.includes("inventory") && url.includes("view")) {
			const unitIndex = url.indexOf("inventory") + 2;
			return url[unitIndex];
		}
		return "";
	}
}
