import {
	AuthenticationDetails,
	CognitoUser,
	type CognitoUserPool,
} from "amazon-cognito-identity-js";
import { SignInResult } from "../cognito.constants";

export const SignInResolver = async (
	Pool: CognitoUserPool,
	Username: string,
	Password: string,
): Promise<[SignInResult, CognitoUser | null]> => {
	const authenticationDetails = new AuthenticationDetails({
		Username,
		Password,
	});
	const cognitoUser = new CognitoUser({ Pool, Username });
	cognitoUser.setAuthenticationFlowType("USER_SRP_AUTH");
	return new Promise((resolve) => {
		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: () => {
				resolve([SignInResult.Success, cognitoUser]);
			},
			onFailure: (e: any) => {
				const error = () => {
					switch (e.code) {
						case "UserNotFoundException":
							return SignInResult.UserNotFound;
						case "NotAuthorizedException":
							return SignInResult.InvalidPassword;
						case "UserNotConfirmedException":
							return SignInResult.UserNotConfirmed;
						default:
							return SignInResult.UnknownError;
					}
				};
				resolve([error(), null]);
			},
			newPasswordRequired: () =>
				resolve([SignInResult.NewPasswordRequired, cognitoUser]),
			mfaRequired: () => resolve([SignInResult.MFARequired, cognitoUser]),
		});
	});
};
