/**
 * @description
 * Simple utility class to represent a queue.
 */
export class Queue<T> {
	private _queue: T[] = [];

	public get length(): number {
		return this._queue.length;
	}

	public enqueue(item: T): void {
		this._queue.push(item);
	}

	public dequeue(): T | undefined {
		return this._queue.shift();
	}

	public dequeueAll(): T[] {
		return this._queue.splice(0, this._queue.length);
	}

	public peek(): T | undefined {
		return this._queue[0];
	}

	public toArray(): T[] {
		return this._queue;
	}
}
