import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { VirtualTourType } from "../../enumerations";

export namespace VirtualTourReplicache {
	export const prefix = "virtualTour";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyVirtualToursId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface VirtualTour {
	__typename: "VirtualTour";
	id: string;
	createdAt: string;
	updatedAt: string;
	tags?: Array<string> | null;
	virtualTourType: VirtualTourType;
	matterportId?: string | null;
	thumbnail?: string | null;
	name: string;
	active?: boolean | null;
	link?: string | null;
	unitVirtualToursId?: string | null;
	propertyVirtualToursId: string;
	floorplanVirtualToursId?: string | null;
}

type VirtualTourProperties = Omit<VirtualTour, "__typename">;

export type CreateVirtualTourInput = Partial<VirtualTourProperties>;
export type UpdateVirtualTourInput = Partial<VirtualTourProperties> & {
	id: string;
};
