import {
	Injectable,
	type Signal,
	type WritableSignal,
	computed,
	signal,
} from "@angular/core";
import type {
	CognitoUser,
	CognitoUserSession,
} from "amazon-cognito-identity-js";
import { deepEqual } from "fast-equals";
import { derivedAsync } from "ngxtension/derived-async";
import { LogLevel, Logger } from "src/app/utils";
import { BroadcastMessage, REMEMBER_DEVICE, USER_POOL } from ".";
import {
	ChangePasswordResult,
	SignInResult,
	type UserAttributes,
} from "./cognito.constants";
import {
	ChangePasswordResolver,
	SessionResolver,
	SignInResolver,
	SignOutResolver,
	UserAttributesResolver,
} from "./resolvers";
import { SessionRefreshManager } from "./session-refresh-manager";

@Injectable({
	providedIn: "root",
})
export class CognitoService {
	private readonly _logger = new Logger("CognitoService", {
		level: LogLevel.INFO,
	});
	private readonly _broadcastChannel = new BroadcastChannel("cognito");

	private readonly _signInResult: WritableSignal<SignInResult | null> =
		signal(null);
	private readonly _cognitoUser: WritableSignal<CognitoUser | null> = signal(
		USER_POOL.getCurrentUser(),
	);
	private readonly _session: Signal<CognitoUserSession | null> = derivedAsync(
		async () => await SessionResolver(this._cognitoUser()),
		{ initialValue: null, equal: deepEqual },
	);
	private readonly _user: Signal<UserAttributes | null> = computed(() =>
		UserAttributesResolver(this._session()),
	);

	private readonly _sessionRefreshManager = new SessionRefreshManager(
		this._cognitoUser,
		this._session,
	);

	public readonly isAuthenticated: Signal<boolean | null> = computed(() => {
		const session = this._session();
		if (this._cognitoUser()) return session === null ? null : session.isValid();
		return false;
	});

	public readonly newPasswordRequired: Signal<boolean> = computed(
		() => this._signInResult() === SignInResult.NewPasswordRequired,
	);

	public readonly user: Signal<UserAttributes> = computed(
		() => this._user() as UserAttributes,
	);

	constructor() {
		this._listenToBroadcastChannel();
	}

	public async signIn(
		email: string,
		password: string,
		rememberDevice: boolean,
	): Promise<SignInResult> {
		REMEMBER_DEVICE.set(rememberDevice);
		const result = await SignInResolver(USER_POOL, email, password);
		this._logger.info("Sign in result:", result);
		const [signInResult, cognitoUser] = result;
		this._signInResult.set(signInResult);
		this._cognitoUser.set(cognitoUser);
		this._broadcastChannel.postMessage(BroadcastMessage.SignIn);
		return signInResult;
	}

	public async handlePasswordChange(
		newPassword: string,
	): Promise<ChangePasswordResult> {
		const result = await ChangePasswordResolver(
			this._cognitoUser(),
			newPassword,
		);
		this._logger.info("Change password result:", result);
		const [changePasswordResult, cognitoUser] = result;
		this._cognitoUser.set(cognitoUser);
		if (changePasswordResult === ChangePasswordResult.Success)
			this._signInResult.set(SignInResult.Success);
		return changePasswordResult;
	}

	public async signOut(): Promise<void> {
		const user = this._cognitoUser();
		if (user) await SignOutResolver(user);
		this._cognitoUser.set(USER_POOL.getCurrentUser());
		this._signInResult.set(null);
		this._broadcastChannel.postMessage(BroadcastMessage.SignOut);
	}

	public async refreshSession(): Promise<void> {
		await this._sessionRefreshManager.refreshSession();
	}

	public shouldRefreshSession(): boolean {
		return this._sessionRefreshManager.shouldRefreshSession();
	}

	private _listenToBroadcastChannel(): void {
		this._broadcastChannel.addEventListener("message", ({ data }) => {
			this._logger.log("Broadcast channel message received:", data);
			switch (data) {
				case BroadcastMessage.SignIn:
					this._cognitoUser.set(USER_POOL.getCurrentUser());
					break;
				case BroadcastMessage.SignOut:
					this._cognitoUser.set(null);
					break;
			}
		});
	}
}
