import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable } from "rxjs";
import type {
	CalendarEvent,
	CreateCalendarEventInput,
	UpdateCalendarEventInput,
} from "src/app/schema";
import { ApiService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class CalendarEventsService {

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
	) {}

	public createEvent(
		event: CreateCalendarEventInput,
	): Observable<CalendarEvent> {
		return this.httpClient.post<CalendarEvent>(
			`${this.api.calendaringApiEndpoint}/event`,
			event,
		);
	}

	public getEvent(eventId: string): Observable<CalendarEvent> {
		return this.httpClient.get<CalendarEvent>(
			`${this.api.calendaringApiEndpoint}/event/${eventId}`,
		);
	}

	public updateEvent(
		event: UpdateCalendarEventInput,
	): Observable<CalendarEvent> {
		return this.httpClient.put<CalendarEvent>(
			`${this.api.calendaringApiEndpoint}/event`,
			event,
		);
	}

	public deleteEvent(eventId: string): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.api.calendaringApiEndpoint}/event/${eventId}`,
		);
	}

}