import {
	type EffectRef,
	type Signal,
	type WritableSignal,
	effect,
	inject,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import type {
	CognitoUser,
	CognitoUserSession,
} from "amazon-cognito-identity-js";
import { DateTime, Duration } from "luxon";
import { firstValueFrom, skipWhile, take } from "rxjs";
import { LogLevel, Logger } from "src/app/utils";
import { REMEMBER_DEVICE, RefreshSessionResolver, USER_POOL } from ".";
import { BackgroundTask, BackgroundTaskService } from "../background-task";
import { DeviceService } from "../device/device.service";

const REFRESH_BUFFER_IN_MINUTES = 40; // The amount to subtract from the expiration time to refresh the session before it expires.

export class SessionRefreshManager {
	private readonly _logger = new Logger("SessionRefreshManager", {
		level: LogLevel.INFO,
	});
	private readonly _deviceService = inject(DeviceService);
	private readonly _backgroundTaskService = inject(BackgroundTaskService);
	private readonly _isOnline$ = toObservable(this._deviceService.isOnline);

	private _sessionRefreshInProgress = false;

	constructor(
		private readonly _cognitoUser: WritableSignal<CognitoUser | null>,
		private readonly _session: Signal<CognitoUserSession | null>,
	) {
		this._sessionRefreshEffect();
		this._periodicallyRefreshUser();
	}

	public async refreshSession(): Promise<void> {
		if (this._sessionRefreshInProgress) return;
		this._sessionRefreshInProgress = true;
		if (REMEMBER_DEVICE.get() === true) {
			const isOnline = this._deviceService.isOnline();
			if (isOnline) {
				this._logger.log("Refreshing session.");
				const result = await RefreshSessionResolver(this._cognitoUser());
				this._logger.log(
					result ? "Session refreshed." : "Session refresh failed.",
				);
			} else {
				// const earliestBeginDate = DateTime.now().plus({ minutes: 5 });
				// this._logger.log(`Device is offline. Will attempt to refresh again ${earliestBeginDate.toRelative({ round: false })}.`);
				// const task = new BackgroundTask("SessionRefresh", async () => await this.refreshSession());
				// this._backgroundTaskService.register(task, { earliestBeginDate });
				this._logger.log(
					"Device is offline. Waiting for device to come online.",
				);
				await firstValueFrom(
					this._isOnline$.pipe(
						skipWhile((isOnline) => !isOnline),
						take(1),
					),
				);
				this._logger.log("Device is back online. Refreshing session.");
				this._sessionRefreshInProgress = false;
				await this.refreshSession();
			}
		}
		this._sessionRefreshInProgress = false;
		this._cognitoUser.set(USER_POOL.getCurrentUser());
	}

	private _sessionRefreshEffect(): EffectRef {
		return effect(async (onCleanup) => {
			const session = this._session();
			if (!session) return;
			if (REMEMBER_DEVICE.get() !== true) return;
			const delay = this._timeToRefresh();
			const earliestBeginDate = DateTime.now().plus({ milliseconds: delay });
			this._logger.log(
				delay > 0
					? `Session will be refreshed ${earliestBeginDate.toRelative({ round: false })}.`
					: "Session has expired. Refreshing now.",
			);

			const task = new BackgroundTask(
				"SessionRefresh",
				async () => await this.refreshSession(),
			);
			this._backgroundTaskService.register(task, { earliestBeginDate });

			onCleanup(() => this._backgroundTaskService.cancel(task));
		});
	}

	private _periodicallyRefreshUser(): void {
		const earliestBeginDate = DateTime.now().plus({ minutes: 5 });
		const interval = Duration.fromObject({ minutes: 5 });
		const task = new BackgroundTask("PeriodicallyRefreshUser", () => {
			this._logger.log(
				"Periodically refreshing user.",
				USER_POOL.getCurrentUser(),
			);
			this._cognitoUser.set(USER_POOL.getCurrentUser());
		});
		this._backgroundTaskService.register(task, { earliestBeginDate, interval });
	}

	public shouldRefreshSession(): boolean {
		try {
			return this._timeToRefresh() <= 0;
		} catch {
			return false;
		}
	}

	private _timeToRefresh(): number {
		const expiration = this._tokenExpiration();
		const refresh = expiration.minus({ minutes: REFRESH_BUFFER_IN_MINUTES });
		const delay = Math.max(refresh.diffNow().toMillis(), 0);
		return delay;
	}

	private _tokenExpiration(): DateTime {
		const session = this._session();
		if (!session) throw new Error("Session is not available.");
		const expiration = DateTime.fromSeconds(
			session.getIdToken().getExpiration(),
		);
		this._logger.info(
			`Token expires ${expiration.toRelative({ round: false })}.`,
		);
		return expiration;
	}
}
