import { HttpClient } from "@angular/common/http";
import { Injectable, type WritableSignal, inject, signal } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class DeviceService {
	private readonly _httpClient = inject(HttpClient);

	public readonly isOnline: WritableSignal<boolean> = signal(true);
	public readonly isAuthorized: WritableSignal<boolean> = signal(true);

	constructor() {
		window.addEventListener("online", () => this._onlineEventListener());
		window.addEventListener("offline", () => this._offlineEventListener());
	}

	private async _onlineEventListener(): Promise<void> {
		this.isOnline.set(await this._isOnline());
		this.isAuthorized.set(await this._isAuthorized());
	}

	private _offlineEventListener(): void {
		this.isOnline.set(false);
		this.isAuthorized.set(false);
	}

	private async _isOnline(): Promise<boolean> {
		try {
			const { statusEndpoint } = environment.replicache;
			await firstValueFrom(this._httpClient.get(statusEndpoint));
			return true;
		} catch (error) {
			return false;
		}
	}

	private async _isAuthorized(): Promise<boolean> {
		try {
			const { authorizedEndpoint } = environment.replicache;
			await firstValueFrom(this._httpClient.get(authorizedEndpoint));
			return true;
		} catch (error) {
			return false;
		}
	}
}
