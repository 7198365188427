export const environment = {
	name: "app.arch.platform.dev",
	production: false,
	cognito: {
		UserPoolId: "us-east-1_R9u30QrpQ",
		ClientId: "3hhfhtsrb064fktvidugdap5ha",
	},
	workspacesEndpoint: "https://dev.oblivion.platform.arch.app/workspaces",
	coreEndpoint: "https://zfqay7gd27.execute-api.us-east-1.amazonaws.com/dev",
	marketingEndpoint:
		"https://g2os63ixzi.execute-api.us-east-1.amazonaws.com/dev",
	inventoryEndpoint:
		"https://9fjf2jgu3i.execute-api.us-east-1.amazonaws.com/dev",
	communicationsEndpoint:
		"https://gs8k8zcgad.execute-api.us-east-1.amazonaws.com/dev",
	calendaringEndpoint:
		"https://pzqljowde8.execute-api.us-east-1.amazonaws.com/dev",
	applicationsEndpoint:
		"https://bahvghdo3m.execute-api.us-east-1.amazonaws.com/dev",
	analyticsEndpoint:
		"https://kh1el8syz3.execute-api.us-east-1.amazonaws.com/dev",
	propertyWebsiteEndpoint:
		"https://kosey8ltb0.execute-api.us-east-1.amazonaws.com",
	propertyAssetsEndpoint:
		"https://kosey8ltb0.execute-api.us-east-1.amazonaws.com",
	emailMarketingEndpoint:
		"https://hrcup0y5uc.execute-api.us-east-1.amazonaws.com/dev",
	token: "48FDi8PvS4hNDJUpyaGtnPYwKXt3u71g",
	tinyApiKey: "v559p27t6861urecmvgg96jn7n21uu9p4jvw54lk9uepptv7",
	adobeClientId: "9951ab3148494666ba4746f0e6c425b9",
	firebase: {
		apiKey: "AIzaSyDcqpWRExoc1KjFDlIJXJNXgfbAGIZDFn0",
		authDomain: "arch-8d18d.firebaseapp.com",
		projectId: "arch-8d18d",
		storageBucket: "arch-8d18d.appspot.com",
		messagingSenderId: "324791791615",
		appId: "1:324791791615:web:d5e0c0b7242aaece0581ca",
		measurementId: "G-WVSCTBZDJR",
		vapidKey:
			"BCOmjt3AMpDbCpqPS4PBjDkq0gDews32QLe_uDcmvzQvrHSe_Zl5tlhq1NxW5Y7draibM8WMVgqDbXN6yfK_08M",
	},
	openReplay: {
		projectKey: "vnwryGmE1nVrgba4bp77",
	},
	twilioAccountSid: "AC182849cc14c7daed682d4b5b6e7f47d0",
	replicache: {
		name: "app.arch.platform.dev",
		schemaVersion: "1",
		licenseKey: "lb243aaf3f3e84124ac7ae3b5b90428b7",
		iot: {
			endpoint: "wss://dev.iot.oblivion.platform.arch.app/mqtt",
			topicPrefix: "app.arch.platform.oblivion.dev",
		},
		authorizedEndpoint: "https://dev.oblivion.platform.arch.app/authorized",
		statusEndpoint: "https://dev.oblivion.platform.arch.app/status",
		pullEndpoint: "https://dev.oblivion.platform.arch.app/pull",
		pushEndpoint: "https://dev.oblivion.platform.arch.app/push",
		experimental: {
			pushEnabled: false,
		},
	},
};
