export enum MQTTEvent {
	CONNECT = "connect",
	SUBSCRIBE = "subscribe",
	RECONNECT = "reconnect",
	CLOSE = "close",
	OFFLINE = "offline",
	ERROR = "error",
	END = "end",
	MESSAGE = "message",
	DISCONNECT = "disconnect",
}

export const MQTT_EVENTS: Exclude<MQTTEvent, MQTTEvent.SUBSCRIBE>[] = [
	MQTTEvent.CONNECT,
	MQTTEvent.RECONNECT,
	MQTTEvent.CLOSE,
	MQTTEvent.OFFLINE,
	MQTTEvent.ERROR,
	MQTTEvent.END,
	MQTTEvent.MESSAGE,
	MQTTEvent.DISCONNECT,
];

export enum StreamableEventAction {
	CONNECTED = "connected",
	POKE = "poke",
}

export interface PokeEvent {
	action: StreamableEventAction.POKE | StreamableEventAction.CONNECTED;
	partitionKey?: string;
}

export type StreamableEvent = PokeEvent;

export * from "./poke.service";
export * from "./reconnection-manager";
