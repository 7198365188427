import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as fileSaver from "file-saver";
import type { Observable } from "rxjs";
import type { Call } from "src/app/schema";
import { environment } from "src/environments/environment";

const twilioAccountSid = environment.twilioAccountSid;

@Injectable({
	providedIn: "root",
})
export class AudioPlaybackService {
	activeAudio: any;
	activeAudioId: string | undefined;
	paused = false;

	constructor(private http: HttpClient) {}

	playCallRecording(call: Call, recordingSid: string) {
		this.activeAudio = new Audio();
		this.activeAudio.src = `https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Recordings/${recordingSid}.mp3`;
		this.activeAudio.id = call.id;
		this.activeAudio.load();
		this.activeAudioId = call.id;
		this.paused = false;
		this.activeAudio.play();
		this.paused = false;
	}

	pauseCallRecording() {
		this.paused = true;
		this.activeAudio.pause();
	}

	playVoiceMail(s3Link: string, audioId: string) {
		this.activeAudioId = audioId;
		this.activeAudio = new Audio();
		this.activeAudio.src = s3Link;
		this.activeAudio.load();
		this.paused = false;
		this.activeAudio.play();
	}

	pauseVoiceMail() {
		if (this.activeAudio) {
			this.activeAudio.pause();
			this.paused = true;
		}
	}

	downloadFile(recordingSid: string): Observable<any> {
		return this.http.get(
			`https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Recordings/${recordingSid}.mp3`,
			{ responseType: "blob" },
		);
	}

	downloadCallRecording(recordingSid: string, recordingName: string) {
		this.downloadFile(recordingSid).subscribe((response: any) => {
			const blob: any = new Blob([response], {
				type: "activeAudio/mp3; charset=utf-8",
			});
			const url = window.URL.createObjectURL(blob);
			fileSaver.saveAs(blob, `${recordingName}.mp3`);
		}),
			(error: any) => console.log("Error downloading the file"),
			() => console.info("File downloaded successfully");
	}
}
