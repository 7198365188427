import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable } from "rxjs";
import {
	type CreateVirtualTourInput,
	type UpdateVirtualTourInput,
	type VirtualTour,
} from "src/app/schema";
import {
	ApiService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class VirtualTourService {

	constructor(
		private httpClient: HttpClient,
		private api: ApiService
	) {}

	public createVirtualTour(
		virtualTour: CreateVirtualTourInput,
	): Observable<VirtualTour> {
		return this.httpClient.post<VirtualTour>(
			`${this.api.inventoryApiEndpoint}/virtual-tour`,
			virtualTour,
		);
	}

	public updateVirtualTour(
		virtualTour: UpdateVirtualTourInput,
	): Observable<VirtualTour> {
		return this.httpClient.put<VirtualTour>(
			`${this.api.inventoryApiEndpoint}/virtual-tour`,
			virtualTour,
		);
	}

	public deleteVirtualTour(
		virtualTour: VirtualTour,
	): Observable<{ id: string }> {
		return this.httpClient.delete<{ id: string }>(
			`${this.api.inventoryApiEndpoint}/virtual-tour/${virtualTour.id}`,
		);
	}
}
