import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";

export interface PropertyImage {
	id: string;
	propertyId: string;
	fileName: string;
	url: string;
	altText: string;
	size: number;
	thumbnail: string;
	isFavorite: boolean;
	description: string;
	createdAt: string;
}

declare global {
	interface Navigator {
		msSaveOrOpenBlob?: (blob: any) => void;
	}
}

@Injectable({
	providedIn: "root",
})
export class AssetService {
	constructor(private httpClient: HttpClient) {}

	public getPropertyImages(propertyId: string): Observable<PropertyImage[]> {
		const endpoint = `${environment.propertyAssetsEndpoint}/properties/${propertyId}/images`;
		return this.httpClient.get<PropertyImage[]>(endpoint);
	}

	public uploadAsset(
		propertyId: string,
		file: File,
		assetType: "logo" | "favicon" | "font" | "propertyImage",
	) {
		const endpoint = `${environment.propertyWebsiteEndpoint}/properties/${propertyId}/upload-asset`;
		const fileName: string = file.name;
		let contentType: string = file.type;
		if (assetType === "font") contentType = "binary/octet-stream";
		return this.httpClient
			.post(endpoint, {
				fileName: fileName,
				contentType: contentType,
				assetType: assetType,
			})
			.pipe(
				switchMap((response: any) => {
					const link: string = response.preSignedUrl;
					return this.httpClient.put(link, file);
				}),
			);
	}

	public deletePropertyImage(
		propertyId: string,
		imageId: string,
	): Observable<any> {
		const endpoint = `${environment.propertyAssetsEndpoint}/properties/${propertyId}/images/${imageId}`;
		return this.httpClient.delete(endpoint);
	}

	public updatePropertyImage(
		propertyId: string,
		imageId: string,
		image: Partial<PropertyImage>,
	): Observable<PropertyImage> {
		const endpoint = `${environment.propertyAssetsEndpoint}/properties/${propertyId}/images/${imageId}`;
		return this.httpClient.post<PropertyImage>(endpoint, image);
	}

	public downloadImage(image: PropertyImage): void {
		const imgUrl = image.url;
		const imgName = imgUrl.substr(imgUrl.lastIndexOf("/") + 1);
		this.httpClient
			.get(imgUrl, { responseType: "blob" as "json" })
			.subscribe((res: any) => {
				const file = new Blob([res], { type: res.type });
				if (window.navigator?.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(file);
					return;
				}
				const blob = window.URL.createObjectURL(file);
				const link = document.createElement("a");
				link.href = blob;
				link.download = imgName;
				link.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						view: window,
					}),
				);
				setTimeout(() => {
					window.URL.revokeObjectURL(blob);
					link.remove();
				}, 100);
			});
	}
}
