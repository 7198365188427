import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject } from "rxjs";
import {
	type BookTourExistingLeadInput,
	type BookTourNewLeadInput,
	type RescheduleTourInput,
	type Tour,
	TourReplicache,
	type UpdateTourInput,
} from "src/app/schema";
import {
	ApiService,
	CognitoService,
	ReplicacheService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class ToursService {
	public selectedTour$: Subject<Tour | null> = new Subject<Tour | null>();

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private cognitoService: CognitoService,
		private replicacheService: ReplicacheService,
	) {}

	public bookTourForNewLead(tour: BookTourNewLeadInput): Observable<Tour> {
		return this.httpClient.post<Tour>(
			`${this.api.calendaringApiEndpoint}/new-lead/book-tour`,
			tour,
		);
	}

	public bookTourExistingLead(
		tour: BookTourExistingLeadInput,
	): Observable<Tour> {
		return this.httpClient.post<Tour>(
			`${this.api.calendaringApiEndpoint}/leads/${tour.leadId}/tours/book`,
			tour,
		);
	}

	public rescheduleTour(tour: RescheduleTourInput) {
		// return this.httpClient.put<Tour>(`${this.api.calendaringApiEndpoint}/tours/${tour.id}/reschedule`, tour);
		const updateViaAPI = this.httpClient.put<Tour>(
			`${this.api.calendaringApiEndpoint}/tours/${tour.id}/reschedule`,
			tour,
		);
		// const updateViaReplicache = this.replicacheService.mutate(TourReplicache.MutatorNames.RescheduleTour, tour);
		// const { pushEnabled } = environment.replicache.experimental;
		// if (pushEnabled) return from(updateViaReplicache);
		return updateViaAPI;
	}

	public reassignTour(tourId: string, userId: string): Observable<Tour> {
		return this.httpClient.put<Tour>(
			`${this.api.calendaringApiEndpoint}/tours/${tourId}/reassign`,
			{ userId: userId },
		);
	}

	public cancelTour(tourId: string, cancellationReasons: string[]) {
		return this.httpClient.put<Tour>(
			`${this.api.calendaringApiEndpoint}/tours/${tourId}/cancel`,
			{ cancellationReasons: cancellationReasons, userId: this.cognitoService.user().id },
		);
	}

	public updateTour(tour: UpdateTourInput): Observable<Tour> {
		return this.httpClient.put<Tour>(
			`${this.api.calendaringApiEndpoint}/tour`,
			tour,
		);
	}

	public getTour(tourId: string): Observable<Tour> {
		return this.httpClient.get<Tour>(
			`${this.api.calendaringApiEndpoint}/tour/${tourId}`,
		);
	}

	public markAsShow(tourId: string): Observable<Tour> {
		return this.httpClient.put<Tour>(
			`${this.api.calendaringApiEndpoint}/tours/${tourId}/show`,
			{},
		);
	}

	public markAsNoShow(tourId: string): Observable<Tour> {
		return this.httpClient.put<Tour>(
			`${this.api.calendaringApiEndpoint}/tours/${tourId}/no-show`,
			{},
		);
	}

	public toursByLead(leadId: string): Observable<Tour[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: TourReplicache.IndexNames.byLeadId,
			start: { key: [leadId] },
			prefix: leadId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const toursByLeadId$ = new Subject<Tour[]>();
		this.replicacheService.subscribe(transaction, (tours) =>
			toursByLeadId$.next(tours),
		);
		return toursByLeadId$;
	}

	public toursByUnit(unitId: string): Observable<Tour[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: TourReplicache.IndexNames.byUnitId,
			start: { key: [unitId] },
			prefix: unitId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const toursByUnitId$ = new Subject<Tour[]>();
		this.replicacheService.subscribe(transaction, (tours) =>
			toursByUnitId$.next(tours),
		);
		return toursByUnitId$;
	}

}