import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable } from "rxjs";
import type {
	CreateTemplateInput,
	Template,
	UpdateTemplateInput,
} from "src/app/schema";
import { ApiService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class TemplateService {

	constructor(
		private api: ApiService,
		private httpClient: HttpClient,
	) { }

	public getTemplate(id: string): Observable<Template> {
		return this.httpClient.get<Template>(
			`${this.api.emailMarketingApiEndpoint}/template/${id}`,
		);
	}

	public createTemplate(template: CreateTemplateInput): Observable<Template> {
		return this.httpClient.post<Template>(
			`${this.api.emailMarketingApiEndpoint}/template`,
			template,
		);
	}

	public updateTemplate(template: UpdateTemplateInput): Observable<Template> {
		return this.httpClient.put<Template>(
			`${this.api.emailMarketingApiEndpoint}/template`,
			template,
		);
	}

}