import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject } from "rxjs";
import { type Call, CallReplicache } from "src/app/schema";
import { ApiService, ReplicacheService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class CallService {
	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private replicacheService: ReplicacheService,
	) {}

	public loadLeadCalls(leadId: string): Observable<Call[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: CallReplicache.IndexNames.byLeadId,
			start: { key: [leadId] },
			prefix: leadId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const leadCalls$ = new Subject<Call[]>();
		this.replicacheService.subscribe(transaction, (calls) =>
			leadCalls$.next(calls),
		);
		return leadCalls$;
	}

	public callAccepted(calldId: string): Observable<Call> {
		return this.httpClient.put<Call>(
			`${this.api.communicationsApiEndpoint}/calls/${calldId}/accept`,
			{},
		);
	}
}
