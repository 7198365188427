import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { PropertyType } from "../../enumerations";
import type { RawAddress } from "../utilities";

export namespace FloorplanReplicache {
	export const prefix = "floorplan";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyFloorplansId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Floorplan {
	__typename: "Floorplan";
	id: string;
	name: string;
	height?: number | null;
	width?: number | null;
	size?: number | null;
	thumbnail: string;
	floor?: string | null;
	line?: string | null;
	exposure?: string | null;
	squareFootage?: number | null;
	numBeds?: number | null;
	numBaths?: number | null;
	numRooms?: number | null;
	fileName: string;
	link: string;
	user?: FloorplanUser | null;
	userFloorplansId?: string | null;
	property: FloorplanProperty;
	propertyFloorplansId: string;
	createdAt: string;
	updatedAt: string;
}

type FloorplanProperties = Omit<Floorplan, "__typename">;

export type CreateFloorplanInput = Partial<FloorplanProperties>;
export type UpdateFloorplanInput = Partial<FloorplanProperties> & {
	id: string;
};

export type FloorplanProperty = {
	id: string;
	name: string;
	type: PropertyType;
	address: RawAddress;
};

export type FloorplanUser = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
};
