import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, map } from "rxjs";
import type { Organization, UpdateOrganizationInput } from "src/app/schema";
import { ApiService, CognitoService } from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class OrganizationService {

	organization: Organization | undefined;

	get organizationId(): string {
		return this.cognitoService.user().organizationId;
	}

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private cognitoService: CognitoService,
	) { }

	public activeOrganization(): Observable<Organization> {
		if (!this.organizationId) {
			throw new Error("Organization ID is required.");
		}
		return this.httpClient.get<Organization>(
			`${this.api.coreApiEndpoint}/organization/${this.organizationId}`,
		);
	}

	public updateOrganization(
		organizationInput: UpdateOrganizationInput,
	): Observable<Organization> {
		return this.httpClient.put<Organization>(
			`${this.api.coreApiEndpoint}/organization`,
			organizationInput,
		);
	}

	public updateApplicationRequirements(
		organizationInput: UpdateOrganizationInput,
	): Observable<Organization> {
		return this.httpClient.put<Organization>(
			`${this.api.coreApiEndpoint}/organization/application-requirements`,
			organizationInput,
		);
	}

	public stripeAccountLink(): Observable<string> {
		const organizationId = this.organizationId;
		return this.httpClient
			.get<{ link: string }>(
				`${environment.coreEndpoint}/organization/${organizationId}/stripe/account_link`,
			)
			.pipe(map((response) => response.link));
	}
}
