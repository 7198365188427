import { Injectable } from "@angular/core";
import {
	PutObjectCommand,
	type PutObjectCommandInput,
	S3Client,
} from "@aws-sdk/client-s3";
import { type Observable, concat, filter, from, map, tap } from "rxjs";
import type {
	CreateFloorplanInput,
	CreateImageInput,
	Floorplan,
	Image,
} from "src/app/schema";
import { FloorplanService, ImageService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class ContentUploadService {
	constructor(
		private imageService: ImageService,
		private floorplanService: FloorplanService,
	) {}

	bucket = new S3Client({
		// TODO:
		// IAM identity with write-only permissions to "leadreel-images" & "leadreel-floorplans" buckets
		// Replace with Environmental Variables.
		credentials: {
			accessKeyId: "AKIATGLVDZ6EZBE3BKXR",
			secretAccessKey: "Lkkxnejtzm+hIpsi0gVCSflhS/LC6im1cWPIZ2GG",
		},
		region: "us-east-1",
	});

	uploadAndCreateImage(file: File, image: CreateImageInput): Observable<Image> {
		if (!image.id) {
			throw new Error("Image ID is required.");
		}
		if (!image.property?.id) {
			throw new Error("Property ID is required.");
		}
		return concat(
			this.uploadImage(file, image.id, image.property.id).pipe(map(() => true)),
			// of(null).pipe(delay(3000)),
			this.imageService.createImage(image),
		).pipe(
			filter((value) => value !== true),
			map((value) => value as Image),
			tap((response) => console.log("Imaged Uploaded and created")),
		);
	}

	uploadAndCreateFloorplan(
		file: File,
		floorplan: CreateFloorplanInput,
	): Observable<Floorplan> {
		if (!floorplan.id) {
			throw new Error("Floorplan ID is required.");
		}
		if (!floorplan.property?.id) {
			throw new Error("Property ID is required.");
		}
		return concat(
			this.uploadFloorplan(file, floorplan.id, floorplan.property.id).pipe(
				map(() => true),
			),
			this.floorplanService.createFloorplan(floorplan),
		).pipe(
			filter((value) => value !== true),
			map((value) => value as Floorplan),
		);
	}

	uploadImage(
		file: File,
		imageId: string,
		propertyId: string,
	): Observable<any> {
		const params: PutObjectCommandInput = {
			Bucket: "leadreel-images",
			Key: `${propertyId}/${imageId}`,
			Body: file,
			ACL: "private",
			ContentType: file.type,
		};
		const command = new PutObjectCommand(params);
		return from(this.bucket.send(command));
	}

	uploadFloorplan(
		file: File,
		floorplanId: string,
		propertyId: string,
	): Observable<any> {
		const params: PutObjectCommandInput = {
			Bucket: "leadreel-floorplans",
			Key: `${propertyId}/${floorplanId}`,
			Body: file,
			ACL: "private",
			ContentType: file.type,
		};
		const command = new PutObjectCommand(params);
		return from(this.bucket.send(command));
	}
}
