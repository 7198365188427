import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject, map } from "rxjs";
import {
	type CreateImageInput,
	type Image,
	ImageReplicache,
	type UpdateImageInput,
} from "src/app/schema";
import {
	ApiService,
	ReplicacheService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class ImageService {
	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private replicacheService: ReplicacheService,
	) {}

	private imagesByPropertyId(propertyId: string): Observable<Image[]> {
		const CLOUDFRONT_DISTRIBUTION_URL = "https://d1pip9ksq8zsha.cloudfront.net";
		const THUMBNAIL_BUCKET_URL = "https://leadreel-thumbnails.s3.amazonaws.com";
		// TODO: Add better filtering for deleted images
		const scanOptions: ScanIndexOptions = {
			indexName: ImageReplicache.IndexNames.byPropertyId,
			start: { key: [propertyId] },
			prefix: propertyId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const imagesByPropertyId$ = new Subject<Image[]>();
		this.replicacheService.subscribe(transaction, (images) =>
			imagesByPropertyId$.next(images),
		);
		const filteredImagesByPropertyId$ = imagesByPropertyId$.pipe(
			map((images) => images.filter((image) => !image.__deleted)),
		);
		return filteredImagesByPropertyId$.pipe(
			map((images) =>
				images.map((image) => {
					return {
						...image,
						thumbnail: image.thumbnail.replace(
							THUMBNAIL_BUCKET_URL,
							CLOUDFRONT_DISTRIBUTION_URL,
						),
					};
				}),
			),
		);
	}

	public createImage(image: CreateImageInput): Observable<Image> {
		return this.httpClient.post<Image>(
			`${this.api.inventoryApiEndpoint}/image`,
			image,
		);
	}

	public updateImage(image: UpdateImageInput): Observable<Image> {
		return this.httpClient.put<Image>(
			`${this.api.inventoryApiEndpoint}/image`,
			image,
		);
	}

	public deleteImage(imageId: string): Observable<{ id: string }> {
		return this.httpClient.delete<{ id: string }>(
			`${this.api.inventoryApiEndpoint}/image/${imageId}`,
		);
	}
}
