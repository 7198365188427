import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject, tap } from "rxjs";
import {
	type Comment,
	CommentReplicache,
	type CreateCommentInput,
} from "src/app/schema";
import { ReplicacheService } from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class CommentService {
	constructor(
		private httpClient: HttpClient,
		private replicacheService: ReplicacheService,
	) {}

	public postLeadComment(
		leadId: string,
		propertyId: string,
		comment: string,
	): Observable<Comment> {
		return this.httpClient.post<Comment>(
			`${environment.communicationsEndpoint}/leads/${leadId}/comments/post`,
			{ comment, propertyId },
		);
	}

	public postApplicationComment(
		applicationId: string,
		comment: string,
	): Observable<Comment> {
		return this.httpClient
			.post<Comment>(
				`${environment.applicationsEndpoint}/applications/${applicationId}/comments/post`,
				{ comment },
			)
			.pipe(tap((response) => console.log(response)));
	}

	public commentsByLeadId(leadId: string): Observable<Comment[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: CommentReplicache.IndexNames.byLeadId,
			start: { key: [leadId] },
			prefix: leadId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const commentsByLeadId$ = new Subject<Comment[]>();
		this.replicacheService.subscribe(transaction, (comments) =>
			commentsByLeadId$.next(comments),
		);
		return commentsByLeadId$;
	}

	public commentsByApplicationId(applicationId: string): Observable<Comment[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: CommentReplicache.IndexNames.byApplicationId,
			start: { key: [applicationId] },
			prefix: applicationId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const commentsByApplicationId$ = new Subject<Comment[]>();
		this.replicacheService.subscribe(transaction, (comments) =>
			commentsByApplicationId$.next(comments),
		);
		return commentsByApplicationId$;
	}

	public createCampaignComment(
		comment: CreateCommentInput,
	): Observable<Comment> {
		return this.httpClient.post<Comment>(
			`${environment.marketingEndpoint}/comment`,
			comment,
		);
	}

	public commentsByCampaign(campaignId: string): Observable<Comment[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: CommentReplicache.IndexNames.byCampaignId,
			start: { key: [campaignId] },
			prefix: campaignId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const commentsByCampaignId$ = new Subject<Comment[]>();
		this.replicacheService.subscribe(transaction, (comments) =>
			commentsByCampaignId$.next(comments),
		);
		return commentsByCampaignId$;
	}
}
