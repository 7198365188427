import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable } from "rxjs";
import {
	type Application,
	type UpdateApplicationInput,
} from "src/app/schema";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ApplicationService {

	constructor(
		private httpClient: HttpClient,
	) {}

	public updateApplication(
		application: UpdateApplicationInput,
	): Observable<Application> {
		return this.httpClient.put<Application>(
			`${environment.applicationsEndpoint}/application`,
			application,
		);
	}

	public approveApplication(applicationId: string): Observable<Application> {
		return this.httpClient.post<Application>(
			`${environment.applicationsEndpoint}/applications/${applicationId}/approve`,
			{},
		);
	}

	public rejectApplication(applicationId: string): Observable<Application> {
		return this.httpClient.post<Application>(
			`${environment.applicationsEndpoint}/applications/${applicationId}/reject`,
			{},
		);
	}

}