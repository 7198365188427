import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class DebugService {
	public readonly enabled$ = new BehaviorSubject<boolean>(false);

	public enable(): void {
		this.enabled$.next(true);
	}

	public disable(): void {
		this.enabled$.next(false);
	}
}
