import type { CognitoUser } from "amazon-cognito-identity-js";
import { ChangePasswordResult } from "../cognito.constants";

export const ChangePasswordResolver = async (
	cognitoUser: CognitoUser | null,
	newPassword: string,
): Promise<[ChangePasswordResult, CognitoUser | null]> => {
	if (!cognitoUser) return [ChangePasswordResult.NoCurrentUser, null];
	return new Promise((resolve) => {
		cognitoUser.completeNewPasswordChallenge(
			newPassword,
			{},
			{
				onSuccess: () => {
					resolve([ChangePasswordResult.Success, cognitoUser]);
				},
				onFailure: (e: any) => {
					const error = () => {
						switch (e.code) {
							case "InvalidParameterException":
								return ChangePasswordResult.InvalidFormat;
							default:
								return ChangePasswordResult.UnknownError;
						}
					};
					resolve([error(), null]);
				},
			},
		);
	});
};
