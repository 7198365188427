import { HttpClient } from "@angular/common/http";
import { computed, inject, Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
	type Observable,
	firstValueFrom,
	map,
	tap,
} from "rxjs";

import type { UpdateLastWorkspaceInput, Workspace } from "src/app/schema";
import { CognitoService } from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class WorkspaceService {

	private readonly _cognitoService = inject(CognitoService);
	private readonly _httpClient = inject(HttpClient);

	public readonly workspaces: Signal<Workspace[] | undefined> = toSignal(this._loadWorkspaces());

	private readonly _workspace: WritableSignal<Workspace | undefined> = signal(undefined);

	public readonly workspace: Signal<Workspace> = computed(() => {
		const workspace = this._workspace();
		if (workspace === undefined) throw new Error("workspace. Workspace is not set.");
		return workspace;
	});

	public readonly workspaceId: Signal<string> = computed(() => {
		const workspace = this._workspace();
		if (workspace === undefined) throw new Error("workspaceId. Workspace is not set.");
		return workspace.id;
	});

	public async selectWorkspace(workspace: Workspace): Promise<void> {
		this._setWorkspace(workspace);
		const { id } = this._cognitoService.user();
		await firstValueFrom(this._updateLastWorkspace(id, { id, lastWorkspace: workspace }));
	}

	public addUserToProperty(
		userId: string,
		propertyId: string,
		organizationId: string,
		role: string,
	): Observable<void> {
		return this._httpClient.post<void>(
			`${environment.coreEndpoint}/users/${userId}/properties/${propertyId}/add`,
			{ organizationId, role },
		);
	}

	private _setInitialWorkspace(
		workspaces: Workspace[],
		lastWorkspace?: Workspace | null,
	): void {
		if (workspaces.length === 0) return;
		const workspace = workspaces.find(({ id }) => id === lastWorkspace?.id);
		this._setWorkspace(workspace || workspaces[0]);
	}

	private _setWorkspace(workspace: Workspace): void {
		this._workspace.set(workspace);
		Object.assign(this, {
			propertyId: workspace.id,
			pagename: workspace.pagename,
			phoneNumber: workspace.phoneNumber,
		});
	}

	private _loadWorkspaces(): Observable<Workspace[]> {
		const workspaces$ = this._httpClient.get<{ workspaces: Workspace[]; lastWorkspace?: Workspace | null }>(
			environment.workspacesEndpoint,
		);
		return workspaces$.pipe(
			tap(({ workspaces, lastWorkspace }) => this._setInitialWorkspace(workspaces, lastWorkspace)),
			map(({ workspaces }) => workspaces),
		)
	}

	private _updateLastWorkspace(
		userId: string,
		workspace: UpdateLastWorkspaceInput,
	): Observable<Workspace> {
		return this._httpClient.put<Workspace>(
			`${environment.coreEndpoint}/users/${userId}/last-workspace`,
			workspace,
		);
	}

}