import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { type Observable, combineLatest, map, of, switchMap } from "rxjs";
import type {
	Call,
	Comment,
	Inquiry,
	Lead,
	Message,
	Tour,
	TourEvent,
} from "src/app/schema";
import {
	AutopilotService,
	CallService,
	CognitoService,
	CommentService,
	InquiryService,
	MessageService,
	ToursService,
	WorkspaceService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class CommunicationsService {
	constructor(
		private toursService: ToursService,
		private messageService: MessageService,
		private cognitoService: CognitoService,
		private inquiryService: InquiryService,
		private callService: CallService,
		private autopilotService: AutopilotService,
		private workspaceService: WorkspaceService,
		private commentService: CommentService,
	) {}

	public sendMessage(message: string, lead: Lead): Observable<{}> {
		if (lead.autopilotRecommendation)
			return this.autopilotService.modify(lead.id, message);
		return this.messageService.sendMessage({
			leadId: lead.id,
			message,
			userId: this.cognitoService.user().id,
			propertyId: this.workspaceService.workspaceId(),
		});
	}

	public loadThread(leadId: string): Observable<any[]> {
		return this.getCommunications(leadId).pipe(
			switchMap((comms) => of(this.processThread(comms))),
		);
	}

	public getCommunications(
		leadId: string,
	): Observable<(Tour | Message | Inquiry | Call | Comment)[]> {
		return combineLatest([
			this.toursService.toursByLead(leadId),
			this.messageService.leadMessages(leadId),
			this.inquiryService.leadInquiries(leadId),
			this.callService.loadLeadCalls(leadId),
			this.commentService.commentsByLeadId(leadId),
		]).pipe(
			map(
				(values) =>
					values.flat() as (Tour | Message | Inquiry | Call | Comment)[],
			),
		);
	}

	public processThread(
		comms: (Tour | Message | Inquiry | Call | Comment)[],
	): any[] {
		const threadItems = {
			messages: comms.filter((c) => c.__typename === "Message"),
			tourEvents: (comms.filter((c) => c.__typename === "Tour") as Array<Tour>)
				.flatMap((t) => t.events)
				.map((e) => ({ ...e, __typename: "TourEvent" })),
			inquiries: comms.filter((c) => c.__typename === "Inquiry"),
			calls: comms.filter((c) => c.__typename === "Call"),
			comments: comms.filter((c) => c.__typename === "Comment"),
		};

		const thread = [
			...threadItems.messages,
			...threadItems.tourEvents,
			...threadItems.inquiries,
			...threadItems.calls,
			...threadItems.comments,
		] as (Message | TourEvent | Inquiry | Call | Comment)[];

		const sortedThread = thread.sort((a, b) =>
			DateTime.fromISO(a.date).diff(DateTime.fromISO(b.date)).valueOf(),
		);
		return this.addDateEvents(sortedThread);
	}

	private addDateEvents(thread: any[]): any[] {
		if (!thread.length) return [];
		let currentDate = DateTime.fromISO(thread[0].date).toFormat("y-LL-dd");
		const newThread = [...thread];
		for (let i = 1, n = 0; i < newThread.length; i++) {
			const date = DateTime.fromISO(newThread[i].date);
			if (date.toFormat("y-LL-dd") !== currentDate) {
				thread.splice(i + n++, 0, {
					__typename: "DateEvent",
					date: date.setZone("America/New_York").toFormat("DDDD"),
				});
			}
			currentDate = date.toFormat("y-LL-dd");
		}
		return thread;
	}
}
