import type {
	CognitoUser,
	CognitoUserSession,
} from "amazon-cognito-identity-js";

export const RefreshSessionResolver = async (
	cognitoUser: CognitoUser | null,
): Promise<CognitoUserSession | null> => {
	if (!cognitoUser) return null;
	return new Promise((resolve) => {
		cognitoUser.getSession(
			(error: Error | null, session: CognitoUserSession | null) => {
				if (error || !session?.isValid()) return resolve(null);
				cognitoUser.refreshSession(
					session.getRefreshToken(),
					(error, newSession) => {
						resolve(error || !newSession?.isValid() ? null : newSession);
					},
				);
			},
		);
	});
};
