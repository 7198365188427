import type {
	IndexDefinitions,
} from "replicache";
import { Replicacheable } from "src/app/services";
import type {
	AmenityFeeType,
	CommissionType,
	ConcessionType,
	GuarantorPolicy,
	MultiFamilyKind,
	Parking,
	ParkingFeeType,
	PetFeeType,
	PlatformType,
	PropertyType,
	ScreeningTier,
} from "../../enumerations";
import type {
	AdditionalApplicationQuestions,
	ApplicationDocumentRequirement,
	Question,
} from "../applications";
import type { Availability } from "../users";
import type { RawAddress } from "../utilities";
import { ReactiveQuery } from "src/app/utils";

export namespace PropertyReplicache {
	export const prefix = "property";

	export enum IndexNames {
		byId = `${prefix}/byId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byId]: {
			jsonPointer: "/id",
			prefix: `${prefix}/`,
		},
	};

	export const GetPropertyById: ReactiveQuery<Property, string> = (id) => async (tx) => {
		return await tx.get(`${prefix}/${id}`) as unknown as Property;
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Property {
	__typename: "Property";
	id: string;
	createdAt: string;
	updatedAt: string;
	type: PropertyType;
	kind: MultiFamilyKind;
	name: string;
	dateAdded: string;
	pytzTimeZone?: string | null;
	momentTimeZone?: string | null;
	legalEntity?: string | null;
	leasingOffice?: string | null;
	maxBedrooms?: number | null;
	budgetOptions?: Array<string> | null;
	rentStabilized?: boolean | null;
	availableLeaseTerms?: Array<number> | null;
	description?: string | null;
	faviconSrc?: string | null;
	logoSrc?: string | null;
	platformType: PlatformType;
	virtualToursEnabled?: boolean | null;
	websiteLink?: string | null;
	availabilityLink?: string | null;
	applicationLink?: string | null;
	faqLink?: string | null;
	tourSchedulerQuestions?: Array<Question> | null;
	inquiryFormQuestions?: Array<Question> | null;
	preScreeningQuestions?: Array<Question> | null;
	allowSelfSchedule?: boolean | null;
	allowSelfReschedule?: boolean | null;
	toursPerHour?: number | null;
	tourLength?: number | null;
	availability?: Availability[] | null;
	tourDuration?: number | null;
	tourBuffer?: number | null;
	nlpSummary?: string | null;
	nlpPropertyDescription?: string | null;
	nlpActive?: boolean | null;
	address: RawAddress;
	streetNumber: string;
	street: string;
	city?: string | null;
	state: string;
	zipCode: string;
	neighborhood?: string | null;
	country: string;
	latitude: number;
	longitude: number;
	addressLink: string;
	parking?: Parking | null;
	yearBuilt?: number | null;
	numUnits: number;
	numListings: number;
	numPendingApplications: number;
	numPendingLeases: number;
	formattedAdress?: string | null;
	facebookLink?: string | null;
	twitterLink?: string | null;
	instagramLink?: string | null;
	linkedinLink?: string | null;
	youtubeLink?: string | null;
	lineOptions?: Array<string> | null;
	amenities?: Array<string> | null;
	hasBarbecueArea?: boolean | null;
	hasDoorman?: boolean | null;
	hasGym?: boolean | null;
	hasValet?: boolean | null;
	hasPackageReceiving?: boolean | null;
	hasConcierge?: boolean | null;
	hasAirConditioning?: boolean | null;
	hasHeating?: boolean | null;
	hasBalcony?: boolean | null;
	hasCable?: boolean | null;
	hasDishwasher?: boolean | null;
	hasElevator?: boolean | null;
	hasFurnished?: boolean | null;
	hasHardwoodFloors?: boolean | null;
	hasInUnitLaundry?: boolean | null;
	hasInBuildingLaundry?: boolean | null;
	hasParking?: boolean | null;
	hasPool?: boolean | null;
	hasStorage?: boolean | null;
	hasWheelchairAccess?: boolean | null;
	hasBikeStorage?: boolean | null;
	hasInUnitDishwasher?: boolean | null;
	includesAirConditioning?: boolean | null;
	includesElectricity?: boolean | null;
	includesGas?: boolean | null;
	includesWater?: boolean | null;
	includesHeating?: boolean | null;
	includesCable?: boolean | null;
	includesInternet?: boolean | null;
	allowsPets?: boolean | null;
	allowsSmallDogs?: boolean | null;
	allowsLargeDogs?: boolean | null;
	allowsCats?: boolean | null;
	petPolicy?: string | null;
	petFee?: number | null;
	petFeeType?: PetFeeType | null;
	petScreening?: boolean | null;
	petAttestation?: boolean | null;
	flexWallPolicy?: string | null;
	moveInFee?: number | null;
	parkingFee?: number | null;
	parkingFeeType?: ParkingFeeType | null;
	amenityFee?: number | null;
	amenityFeeType?: AmenityFeeType | null;
	latePaymentFee?: number | null;
	concessionType?: ConcessionType | null;
	numMonthsConcession?: number | null;
	concessionAmount?: number | null;
	concessionDescription?: string | null;
	ownerPaysBrokerFee?: boolean | null;
	commissionType?: CommissionType | null;
	numMonthsCommission?: number | null;
	commissionAmount?: number | null;
	commissionDescription?: string | null;
	images?: PropertyImage[] | null;
	minimumIncomeRequirement?: number | null;
	guarantorPolicy?: GuarantorPolicy | null;
	delistOnSubmission?: boolean | null;
	screeningTier?: ScreeningTier | null;
	screeningFee?: number | null;
	applicationFee?: number | null;
	applicationDocumentRequirements?: ApplicationDocumentRequirement[] | null;
	additionalApplicationQuestions?: AdditionalApplicationQuestions | null;
	requirePresentResidence?: boolean | null;
	requirePresentResidenceLength?: boolean | null;
	requireReasonsForLooking?: boolean | null;
	requireReferralSources?: boolean | null;
	requireMaritalStatus?: boolean | null;
	requireOccupation?: boolean | null;
	requireEmployer?: boolean | null;
	requireRequiresParking?: boolean | null;
	requireOwnsCar?: boolean | null;
	requireLicensePlateNumber?: boolean | null;
	requireEvictionHistory?: boolean | null;
	requireCriminalHistory?: boolean | null;
	requireNationality?: boolean | null;
	requireIdNumber?: boolean | null;
	landlord?: PropertyLandlord | null;
	userPropertiesId: string;
	clientPropertiesId?: string | null;
	propertyGroupPropertiesId?: string;
	organizationPropertiesId: string;
	customUnitAttributes: string[] | null;
}

type PropertyProperties = Omit<Property, "__typename">;

export type CreatePropertyInput = Partial<PropertyProperties>;
export type UpdatePropertyInput = Partial<PropertyProperties> & { id: string };

export type PropertyImage = {
	id: string;
	link: string;
	thumbnail?: string | null;
};

export type PropertyLandlord = {
	id: string;
	name: string;
	address: RawAddress;
	email: string;
	phoneNumber: string;
};

export type PropertySummary = {
	id: string;
	type: PropertyType;
	name: string;
	address: RawAddress;
	yearBuilt: number;
	numUnits: number;
};
