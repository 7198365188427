import type { IndexDefinitions } from "replicache";
import type { Replicacheable } from "src/app/services";
import type { ReactiveQuery } from "src/app/utils";
import type { Lead } from "../base";

export namespace AudienceReplicache {
	export const prefix = "audience";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyAudiencesId",
			prefix: `${prefix}/`,
		},
	};

	export const GetAudienceById: ReactiveQuery<Audience, string> = (id) => async (tx) => {
		return await tx.get(`${prefix}/${id}`) as unknown as Audience;
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Audience {
	__typename: "Audience";
	id: string;
	createdAt: string;
	updatedAt: string;
	name: string;
	description?: string | null;
	filters: AudienceFilter<any>[];
	userAudiencesId: string;
	propertyAudiencesId: string;
	organizationAudiencesId: string;
}

export enum BooleanOperation {
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS",
}

export enum StringOperation {
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS",
}

export enum ArrayOperation {
	CONTAINS = "CONTAINS",
	NOT_CONTAINS = "NOT_CONTAINS",
}

export enum NumberDateOperation {
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS",
	GREATER_THAN = "GREATER_THAN",
	LESS_THAN = "LESS_THAN",
}

type FieldType<T> = T extends boolean
	? BooleanOperation
	: T extends string
	? StringOperation
	: T extends any[]
	? ArrayOperation
	: T extends number | Date
	? NumberDateOperation
	: never;

type FieldValueType<T> = T extends keyof typeof BooleanOperation
	? boolean
	: T extends keyof typeof StringOperation
	? string | string[]
	: T extends keyof typeof ArrayOperation
	? any
	: T extends keyof typeof NumberDateOperation
	? number | Date
	: never;

export type AudienceFilter<T extends keyof Lead> = {
	field: T;
	operation: FieldType<Lead[T]>;
	value: FieldValueType<FieldType<Lead[T]>>;
};

type AudienceProperties = Omit<Audience, "__typename">;

export type CreateAudienceInput = Partial<AudienceProperties> & {
	userAudiencesId: string;
	propertyAudiencesId: string;
	organizationAudiencesId: string;
};

export type UpdateAudienceInput = Partial<AudienceProperties> & {
	id: string;
};
