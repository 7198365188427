import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, map } from "rxjs";
import type { PropertyImage } from "src/app/services";
import { environment } from "src/environments/environment";

export interface PropertyWebsite {
	id: string;
	name: string;
	tourLink: string;
	applicationLink: string;
	theme: string;
	logo: string;
	favicon: string;
	font: Font;
	address: Address;
	email: string;
	phone: string;
	facebookLink?: string;
	instagramLink?: string;
	developmentID: string;
	inventoryAPIKey: string;
	pages: WebsitePage[];
}

export interface Font {
	name: string;
	url: string;
}

interface Address {
	street: string;
	city: string;
	state: string;
	zip: string;
}

export enum WebsitePageType {
	Landing = "landing",
}

export interface WebsitePage {
	type: WebsitePageType;
	title: string;
	path: string;
	hero: ComponentImage[];
	components: PageComponent[];
	metaTitle: string;
	metaDescription: string;
	focusKeyword: string;
	openGraphTitle: string;
	openGraphDescription: string;
	openGraphImage: PropertyImage;
}

export interface LandingPage extends WebsitePage {
	type: WebsitePageType.Landing;
	ctaSection: CtaSection;
	buildingSection: ExplorePageSection;
	residencesSection: ExplorePageSection;
	lifestyleSection: ExplorePageSection;
	neighborhoodSection: ExplorePageSection;
}

export interface CtaSection {
	title: string;
	subtitle: string;
}

export interface ExplorePageSection {
	images: ComponentImage[];
	calloutText: string;
	description: string;
}

export interface ComponentImage {
	id: string;
	typeName: "ComponentImage";
}

export type PageComponent = {};

@Injectable({
	providedIn: "root",
})
export class WebsiteService {
	constructor(private httpClient: HttpClient) {}

	getPropertyWebsite(id: string): Observable<PropertyWebsite> {
		const endpoint = `${environment.propertyWebsiteEndpoint}/properties/${id}`;
		return this.httpClient.get<PropertyWebsite>(endpoint);
	}

	updatePropertyWebsite(
		id: string,
		data: PropertyWebsite,
	): Observable<PropertyWebsite> {
		const endpoint = `${environment.propertyWebsiteEndpoint}/properties/${id}`;
		return this.httpClient.put<PropertyWebsite>(endpoint, data);
	}

	getPage(
		id: string,
		pageType: WebsitePageType,
	): Observable<WebsitePage | undefined> {
		return this.getPropertyWebsite(id).pipe(
			map((propertyWebsite: PropertyWebsite) =>
				propertyWebsite.pages.find(
					(page: WebsitePage) => page.type === pageType,
				),
			),
		);
	}

	updatePage(
		id: string,
		data: Partial<WebsitePage>,
		pageType: WebsitePageType,
	): Observable<WebsitePage | undefined> {
		const endpoint = `${environment.propertyWebsiteEndpoint}/properties/${id}/pages/${pageType}`;
		return this.httpClient
			.put<PropertyWebsite>(endpoint, data)
			.pipe(
				map((propertyWebsite: PropertyWebsite) =>
					propertyWebsite.pages.find(
						(page: WebsitePage) => page.type === pageType,
					),
				),
			);
	}

	publishChanges(id: string): Observable<any> {
		const endpoint = `${environment.propertyWebsiteEndpoint}/properties/${id}/publish`;
		return this.httpClient.post<any>(endpoint, {});
	}
}
