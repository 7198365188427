import { persistentAtom } from "@nanostores/persistent";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { environment } from "src/environments/environment";

export * from "./resolvers";
export * from "./cognito.constants";
export * from "./cognito.service";

export const USER_POOL = new CognitoUserPool(environment.cognito);

export const REMEMBER_DEVICE = persistentAtom<boolean>(
	"REMEMBER_DEVICE",
	true,
	{ encode: (value) => String(value), decode: (value) => value === "true" },
);

export enum BroadcastMessage {
	SignIn = "sign-in",
	SignOut = "sign-out",
}
