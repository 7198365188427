import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, type Observable, interval, map, tap } from "rxjs";
import type { CreateNewUserInput, UpdateUserInput, User } from "src/app/schema";
import {
	ApiService,
	CognitoService,
	OpenReplayService,
} from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class UserService {
	public user: User | undefined;

	public organizationUsers$: Observable<User[]> = this.usersByOrganizationId();

	public activeUser$: Observable<User> = this.activeUser().pipe(
		tap((user) => {
			if (!this.user) {
				this.openReplayService.identifyUser(user);
			}
			this.user = user;
		}),
	);

	get organizationId(): string | undefined {
		return this.cognitoService.user().organizationId;
	}

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private cognitoService: CognitoService,
		private openReplayService: OpenReplayService,
	) {}

	public usersByOrganizationId(): Observable<User[]> {
		return this.httpClient.get<User[]>(
			`${this.api.coreApiEndpoint}/organizations/${this.organizationId}/users`,
		);
	}

	public usersByProperty(propertyId: string): Observable<User[]> {
		return this.httpClient.get<User[]>(
			`${this.api.coreApiEndpoint}/properties/${propertyId}/users`,
		);
	}

	public otherUsersByOrganizationId(userId: string): Observable<User[]> {
		return this.usersByOrganizationId().pipe(
			map((users) => users.filter((user) => user.id !== userId)),
		);
	}

	public getUser(userId: string): Observable<User> {
		return this.httpClient.get<User>(
			`${this.api.coreApiEndpoint}/user/${userId}`,
		);
	}

	private activeUser(): Observable<User> {
		const userId: string | undefined = this.cognitoService.user().id;
		if (!userId) {
			return EMPTY;
		}
		return this.httpClient.get<User>(
			`${this.api.coreApiEndpoint}/user/${this.cognitoService.user().id}`,
		);
	}

	public createNewUser(
		user: CreateNewUserInput,
		propertyId: string,
	): Observable<User> {
		return this.httpClient.post<User>(
			`${environment.coreEndpoint}/properties/${propertyId}/user`,
			user,
		);
	}

	public updateUser(user: UpdateUserInput): Observable<User> {
		return this.httpClient.put<User>(`${this.api.coreApiEndpoint}/user`, user);
	}

	// TODO: Call this method as soon as the user is authenticated. The ngOnInit() was not getting called in this service.
	public toggleLastUserContactSubscription(): void {
		const source = interval(20000);
		source.subscribe(() => {
			const user = {
				id: this.cognitoService.user().id,
				lastContact: new Date().toISOString(),
			};
			this.updateUser(user);
		});
	}
}
