import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject } from "rxjs";
import {
	type Message,
	MessageReplicache,
	type SendMessageInput,
	type Template,
} from "src/app/schema";
import { ApiService, CognitoService, ReplicacheService } from "src/app/services";

export type TemplatedPreview = {
	id: string;
	subject: string;
	contents: string;
};

@Injectable({
	providedIn: "root",
})
export class MessageService {
	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private cognitoService: CognitoService,
		private replicacheService: ReplicacheService,
	) {}

	public sendMessage(message: SendMessageInput): Observable<Message> {
		return this.httpClient.post<Message>(
			`${this.api.communicationsApiEndpoint}/leads/${message.leadId}/messages/send`,
			message,
		);
	}

	public leadMessages(leadId: string): Observable<Message[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: MessageReplicache.IndexNames.byLeadId,
			start: { key: [leadId] },
			prefix: leadId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const messagesByLeadId$ = new Subject<Message[]>();
		this.replicacheService.subscribe(transaction, (messages) =>
			messagesByLeadId$.next(messages),
		);
		return messagesByLeadId$;
	}

	public getMessagePreview(messageId: string): Observable<TemplatedPreview> {
		return this.httpClient.get<TemplatedPreview>(
			`${this.api.communicationsApiEndpoint}/messages/${messageId}/preview`,
		);
	}

	public getTemplatePreview(
		template: string,
		leadId: string,
		propertyId: string,
	): Observable<Template> {
		return this.httpClient.get<Template>(
			`${this.api.communicationsApiEndpoint}/properties/${propertyId}/templates/${template}/leads/${leadId}/preview`,
		);
	}

	public sendTemplatedMessage(
		template: string,
		leadId: string,
		propertyId: string,
	): Observable<Template> {
		return this.httpClient.post<Template>(
			`${this.api.communicationsApiEndpoint}/properties/${propertyId}/templates/${template}/leads/${leadId}/send`,
			{ userId: this.cognitoService.user().id },
		);
	}
}
