import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	PutObjectCommand,
	type PutObjectCommandInput,
	S3Client,
} from "@aws-sdk/client-s3";
import { type Observable, map } from "rxjs";

import type {
	ApplicationDocument,
	CreateApplicationDocumentInput,
} from "src/app/schema";
import {
	ApplicationDocumentStatus,
	type ApplicationDocumentType,
} from "src/app/schema/enumerations";
import { environment } from "src/environments/environment";

export type SecureLink = {
	link: string;
	contentType: string;
};

export interface UploadDocumentInput {
	id: string;
	file: File;
	type: ApplicationDocumentType;
	filename: string;
	documentName: string;
	applicationId: string;
	applicantId: string;
	propertyId: string;
}

export interface UploadPetPictureInput {
	id: string;
	file: File;
}

@Injectable({
	providedIn: "root",
})
export class ApplicationDocumentService {
	constructor(private httpClient: HttpClient) {}

	public getApplicationDocumentsByApplicantId(
		applicantId: string,
	): Observable<ApplicationDocument[]> {
		return this.httpClient
			.get<{ items: ApplicationDocument[] }>(
				`${environment.applicationsEndpoint}/application-documents-by-applicant/${applicantId}`,
			)
			.pipe(map((response) => response.items));
	}

	public loadDocumentLink(
		documentId: string,
		applicationId: string,
	): Observable<SecureLink> {
		return this.httpClient.get<SecureLink>(
			`${environment.applicationsEndpoint}/application-document-link/${applicationId}/${documentId}`,
		);
	}

	public loadThumbnailLink(
		documentId: string,
		applicationId: string,
	): Observable<SecureLink> {
		return this.httpClient.get<SecureLink>(
			`${environment.applicationsEndpoint}/application-document-thumbnail-link/${applicationId}/${documentId}`,
		);
	}

	bucket = new S3Client({
		// TODO: IAM identity with write-only permissions to "leasing-application-documents" bucket
		// Replace with Environmental Variables.
		credentials: {
			accessKeyId: "AKIATGLVDZ6EXGKGSTV7",
			secretAccessKey: "eza0rJwmSHxLIwuoeXmWWGRZ0l3Anu+0Y88KzCLT",
		},
		region: "us-east-1",
	});

	async uploadPetPicture(input: UploadDocumentInput): Promise<any> {
		const params = this.getParams(
			input.file,
			input.id,
			input.applicationId,
			input.file.type,
		);
		const command = new PutObjectCommand(params);
		return this.bucket.send(command);
	}

	async uploadDocument(input: UploadDocumentInput): Promise<any> {
		const params = this.getParams(
			input.file,
			input.id,
			input.applicationId,
			input.file.type,
		);
		const command = new PutObjectCommand(params);
		return this.bucket.send(command);
	}

	async uploadApplicationDocument(input: UploadDocumentInput): Promise<any> {
		return new Promise((resolve, reject) => {
			const params = this.getParams(
				input.file,
				input.id,
				input.applicationId,
				input.file.type,
			);
			const command = new PutObjectCommand(params);
			this.bucket
				.send(command)
				.then((event) => {
					const document = this.getDocumentInput(
						input.id,
						input.documentName,
						input.type,
						input.filename,
						input.applicantId,
						input.applicationId,
						input.propertyId,
					);
					this.createDocument(document).subscribe({
						next: (document) => {
							resolve(document);
						},
						error: (error) => {
							reject(error);
						},
					});
				})
				.catch((e) => {
					reject(e);
				});
		});
	}

	public getDocumentInput(
		documentId: string,
		documentName: string,
		documentType: ApplicationDocumentType,
		filename: string,
		applicantId: string,
		applicationId: string,
		propertyId: string,
	) {
		const document: CreateApplicationDocumentInput = {
			id: documentId,
			status: ApplicationDocumentStatus.PendingReview,
			dateCreated: new Date().toISOString(),
			name: documentName,
			type: documentType,
			filename: filename,
			applicantApplicationDocumentsId: applicantId,
			applicationApplicationDocumentsId: applicationId,
			propertyApplicationDocumentsId: propertyId,
		};
		return document;
	}

	getParams(
		file: File,
		fileId: string,
		applicationId: string,
		contentType: string,
	): PutObjectCommandInput {
		return {
			Bucket: "leasing-application-documents",
			Key: `${applicationId}/${fileId}`,
			Body: file,
			ACL: "private",
			ContentType: contentType,
		};
	}

	public createDocument(
		document: CreateApplicationDocumentInput,
	): Observable<ApplicationDocument> {
		return this.httpClient.post<ApplicationDocument>(
			`${environment.applicationsEndpoint}/application-document`,
			document,
		);
	}

	public documentsByApplicantId(
		applicationId: string,
		applicantId: string,
	): Observable<ApplicationDocument[]> {
		return this.httpClient
			.get<{ items: ApplicationDocument[] }>(
				`${environment.applicationsEndpoint}/applications/${applicationId}/documents/${applicantId}`,
			)
			.pipe(map((response) => response.items));
	}
}
