import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type { Budget } from "..";
import type {
	MaritalStatus,
	PresentResidence,
	TourType,
	WorkFromHome,
} from "../../enumerations";
import type { TourPreference } from "./tour";

export namespace InquiryReplicache {
	export const prefix = "inquiry";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
		byUnitId = `${prefix}/byUnitId`,
		byLeadId = `${prefix}/byLeadId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyInquiriesId",
			prefix: `${prefix}/`,
		},
		[IndexNames.byUnitId]: {
			jsonPointer: "/unitInquiriesId",
			prefix: `${prefix}/`,
			allowEmpty: true,
		},
		[IndexNames.byLeadId]: {
			jsonPointer: "/leadInquiriesId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export type InquiryUnit = {
	id: string;
	name: string;
};

export interface Inquiry {
	id: string;
	createdAt: string;
	updatedAt: string;
	__typename: "Inquiry";
	date: string;
	source: string;
	message?: string | null;
	unit?: InquiryUnit | null;
	emailId?: string | null;
	subject?: string | null;
	contents?: string | null;
	processed?: boolean | null;
	processedDate?: string | null;
	tourPreferences?: Array<TourPreference> | null;
	tourType?: TourType | null;
	manual?: boolean | null;
	hot?: boolean | null;
	read?: boolean | null;
	impliedBudget?: number | null;
	budget?: Budget | null;
	moveInDate?: string | null;
	apartmentSizes?: Array<number> | null;
	referralSources?: Array<string> | null;
	residentReferralName?: string | null;
	car?: boolean | null;
	workFromHome?: WorkFromHome | null;
	presentResidence?: PresentResidence | null;
	lengthAtPresentResidence?: string | null;
	maritalStatus?: MaritalStatus | null;
	reasonsForLooking?: Array<string> | null;
	reasonsNotLeased?: Array<string> | null;
	hotness?: number | null;
	unitInquiriesId?: string | null;
	leadInquiriesId: string;
	campaignInquiriesId?: string | null;
	googleAdKeywordInquiriesId?: string | null;
	emailSendInquiriesId?: string | null;
	textSendInquiriesId?: string | null;
	userInquiriesId?: string | null;
	propertyInquiriesId?: string | null;
	propertyGroupInquiriesId?: string | null;
	organizationInquiriesId: string;
}

type InquiryProperties = Omit<Inquiry, "__typename">;

export type CreateInquiryInput = Partial<InquiryProperties>;
export type UpdateInquiryInput = Partial<InquiryProperties> & { id: string };
