import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { BehaviorSubject, type Observable, Subject, map } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LayoutService {
	public mobileSidebarOpen$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public isMobile$: Observable<boolean> = this.breakpointObserver
		.observe("(max-width: 1024px)")
		.pipe(map((result) => result.matches));

	public backButtonPresent$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public toolbarHeight = 0;
	public contentAreaWidth: number | undefined;
	public contentAreaHeight: number | undefined;

	constructor(private breakpointObserver: BreakpointObserver) {
		this._observeThemeChanges();
	}

	private _observeThemeChanges(): void {
		const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
		mediaQuery.addEventListener("change", (event) => {
			const htmlElement = document.querySelector("html");
			const theme = event.matches ? "dark" : "light";
			if (htmlElement && theme) {
				htmlElement.setAttribute("data-theme", theme);
			}
		});
	}

	public toggleMobileSidebar(event?: any): void {
		if (event) {
			event.stopPropagation();
		}
		this.mobileSidebarOpen$.next(!this.mobileSidebarOpen$.value);
	}

	public closeMobileSidebar(event?: any): void {
		if (event) {
			event.stopPropagation();
		}
		this.mobileSidebarOpen$.next(false);
	}

	public themeChanged$: Subject<"dark" | "light" | undefined> = new Subject<
		"dark" | "light" | undefined
	>();

	public theme$: BehaviorSubject<"dark" | "light" | undefined> =
		new BehaviorSubject<"dark" | "light" | undefined>(this.theme);

	public setMode(mode: "light" | "dark" | "system"): void {
		this.theme = mode === "system" ? undefined : mode;
		this.theme$.next(this.theme);
	}

	public get theme(): "dark" | "light" | undefined {
		const storedTheme = localStorage.getItem("theme");
		if (storedTheme === "dark" || storedTheme === "light") {
			return storedTheme;
		}
		return undefined;
	}

	public set theme(theme: "dark" | "light" | undefined) {
		const htmlElement = document.querySelector("html");
		if (htmlElement && theme) {
			htmlElement.setAttribute("data-theme", theme);
			localStorage.setItem("theme", theme);
		}
		if (!theme) {
			htmlElement?.removeAttribute("data-theme");
			localStorage.removeItem("theme");
		}
		this.theme$.next(theme);
		this.themeChanged$.next(theme);
	}
}
