import type { IndexDefinitions } from "replicache";
import { ReactiveQuery } from "src/app/utils";
import type { Replicacheable } from "src/app/services/replicache/types";
import type {
	CommissionType,
	ConcessionType,
	GuarantorPolicy,
	Parking,
	ParkingFeeType,
	PetFeeType,
	PredepositType,
	PropertyType,
	ScreeningTier,
	UnitStatus,
	UnitType,
} from "../../enumerations";
import type {
	AdditionalApplicationQuestions,
	ApplicationDocumentRequirement,
} from "../applications";
import type { RawAddress } from "../utilities";

export namespace UnitReplicache {
	export const prefix = "unit";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyUnitsId",
			prefix: `${prefix}/`,
		},
	};

	export const GetUnitById: ReactiveQuery<Unit, string> = (id) => async (tx) => {
		return await tx.get(`${prefix}/${id}`) as unknown as Unit;
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Unit {
	__typename: "Unit";
	id: string;
	createdAt: string;
	updatedAt: string;
	type: UnitType;
	status: UnitStatus;
	name: string;
	floor?: number | null;
	line?: string | null;
	numRooms?: number | null;
	numBedrooms: number;
	numBaths: number;
	squareFootage: number;
	den?: boolean | null;
	rent?: number | null;
	deposit?: number | null;
	reservationFee?: number | null;
	reservationAmount?: number | null;
	availableStarting?: string | null;
	availableLeaseTerms?: Array<number> | null;
	landlordUnitsId?: string | null;
	tags?: Array<string> | null;
	address: RawAddress;
	streetNumber: string;
	street: string;
	city?: string | null;
	state: string;
	zipCode: string;
	neighborhood?: string | null;
	latitude: number;
	longitude: number;
	country: string;
	addressLink: string;
	parking?: Parking | null;
	images?: Array<UnitImage> | null;
	listedOn?: string | null;
	floorplan?: UnitFloorplan | null;
	floorplanUnitsId?: string | null; // So you can query units by floorplan
	virtualTour?: UnitVirtualTour | null;
	virtualTourUnitsId?: string | null; // So you can query units by virtual tour
	description?: string | null;
	availableForApplications?: boolean | null;
	availableForTour?: boolean | null;
	published?: boolean | null;
	amenities?: Array<string> | null;
	hasBarbecueArea?: boolean | null;
	hasDoorman?: boolean | null;
	hasGym?: boolean | null;
	hasValet?: boolean | null;
	hasPackageReceiving?: boolean | null;
	hasAirConditioning?: boolean | null;
	hasHeating?: boolean | null;
	hasBalcony?: boolean | null;
	hasCable?: boolean | null;
	hasDishwasher?: boolean | null;
	hasElevator?: boolean | null;
	hasFurnished?: boolean | null;
	hasHardwoodFloors?: boolean | null;
	hasInUnitLaundry?: boolean | null;
	hasInBuildingLaundry?: boolean | null;
	hasParking?: boolean | null;
	hasPool?: boolean | null;
	hasStorage?: boolean | null;
	hasYard?: boolean | null;
	hasWheelchairAccess?: boolean | null;
	includesAirConditioning?: boolean | null;
	includesElectricity?: boolean | null;
	includesGas?: boolean | null;
	includesWater?: boolean | null;
	includesHeating?: boolean | null;
	includesCable?: boolean | null;
	includesInternet?: boolean | null;
	allowsPets?: boolean | null;
	allowsSmallDogs?: boolean | null;
	allowsLargeDogs?: boolean | null;
	allowsCats?: boolean | null;
	petPolicy?: string | null;
	petFee?: number | null;
	petFeeType?: PetFeeType | null;
	petScreening?: boolean | null;
	petAttestation?: boolean | null;
	flexWallPolicy?: string | null;
	moveInFee?: number | null;
	parkingFee?: number | null;
	parkingFeeType?: ParkingFeeType | null;
	concessionType?: ConcessionType | null;
	numMonthsConcession?: number | null;
	concessionAmount?: number | null;
	concessionDescription?: string | null;
	ownerPaysBrokerFee?: boolean | null;
	commissionType?: CommissionType | null;
	numMonthsCommission?: number | null;
	commissionAmount?: number | null;
	commissionDescription?: string | null;
	minimumIncomeRequirement?: number | null;
	guarantorPolicy?: GuarantorPolicy | null;
	delistOnSubmission?: boolean | null;
	allowReservations?: boolean | null;
	reservationType?: PredepositType | null;
	screeningTier?: ScreeningTier | null;
	screeningFee?: number | null;
	applicationFee?: number | null;
	applicationDocumentRequirements?: ApplicationDocumentRequirement[] | null;
	additionalApplicationQuestions?: AdditionalApplicationQuestions | null;
	requirePresentResidence?: boolean | null;
	requirePresentResidenceLength?: boolean | null;
	requireReasonsForLooking?: boolean | null;
	requireReferralSources?: boolean | null;
	requireMaritalStatus?: boolean | null;
	requireOccupation?: boolean | null;
	requireEmployer?: boolean | null;
	requireRequiresParking?: boolean | null;
	requireOwnsCar?: boolean | null;
	requireLicensePlateNumber?: boolean | null;
	requireEvictionHistory?: boolean | null;
	requireCriminalHistory?: boolean | null;
	requireNationality?: boolean | null;
	requireIdNumber?: boolean | null;
	exposure?: string | null;
	terrace?: boolean | null;
	user?: UnitUser | null; // Updated via Backend
	userUnitsId?: string | null; // So you can query units by user
	property: UnitProperty; // Updated via Backend
	propertyUnitsId: string; // So you can query units by property
	organizationUnitsId: string;
	propertyGroupUnitsId: string;
	createdByUnitsId?: string | null; // The User who created the Unit
	listedByUnitsId?: string | null; // The User who listed the Unit
	dateAdded: string;
	lastEdited: string;
	lastEditedBy?: string | null;
}

type UnitProperties = Omit<Unit, "__typename">;

export type CreateUnitInput = Partial<UnitProperties>;
export type UpdateUnitInput = Partial<UnitProperties> & { id: string };
export type ListUnitInput = Partial<UnitProperties> & {
	id: string;
	userId: string;
};

export type UnitImage = {
	id: string;
	link: string;
	thumbnail?: string | null;
};

export type UnitFloorplan = {
	id: string;
	name: string;
	link: string;
	thumbnail?: string | null;
};

export type UnitVirtualTour = {
	id: string;
	name: string;
	link: string;
};

export type UnitProperty = {
	id: string;
	name: string;
	type: PropertyType;
	address: RawAddress;
};

export type UnitUser = {
	id: string;
	title?: string | null;
	faviconSrc?: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string | null;
};

export type DescribeUnitInput = {
	id: string;
	name: string;
	numBedrooms: number;
	numBaths: number;
	numRooms?: number | null;
	squareFootage?: number | null;
	exposure?: string | null;
	availableStarting?: string | null;
	rent?: number | null;
	tags?: string[] | null;
	propertyId: string;
};
