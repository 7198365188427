import type {
	IndexDefinitions,
} from "replicache";
import { Replicacheable } from "src/app/services";

export namespace SendReplicache {
	export const prefix = "send";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertySendsId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Send {
	__typename: "Send";
	id: string;
	createdAt: string;
	updatedAt: string;
	name: string;
	description?: string | null;
	status: SendStatus;
	sends: number | null;
	deliveries: number | null;
	opens: number | null;
	clicks: number | null;
	unsubscribes: number | null;
	bounces: number | null;
	complaints: number | null;
	rejects: number | null;
	userSendsId: string;
	propertySendsId: string;
	organizationSendsId: string;
	audienceSendsId: string;
	templateSendsId: string;
	filters?: any[];
	subject?: string | null;
	preheader?: string | null;
	plainText?: string | null;
}

export enum SendStatus {
	Draft = "Draft",
	InProgress = "InProgress",
	Sent = "Sent",
	Failed = "Failed",
}

type SendProperties = Omit<Send, "__typename">;

export type CreateSendInput = Partial<SendProperties> & {
	userSendsId: string;
	propertySendsId: string;
	organizationSendsId: string;
	audienceSendsId: string;
	templateSendsId: string;
	filters: any[];
	subject: string;
	preheader: string;
};

export type UpdateSendInput = Partial<SendProperties> & {
	id: string;
};
