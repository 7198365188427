import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type { Message } from "src/app/schema";
import { ApiService, CognitoService, WorkspaceService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class AutopilotService {
	constructor(
		private api: ApiService,
		private httpClient: HttpClient,
		private cognitoService: CognitoService,
		private workspaceService: WorkspaceService,
	) {}

	public accept(leadId: string, message: string): Observable<Message> {
		return this.httpClient.post<Message>(
			`${this.api.communicationsApiEndpoint}/autopilot/${leadId}/accept`,
			{
				userId: this.cognitoService.user().id,
				message,
				propertyId: this.workspaceService.workspaceId(),
			},
		);
	}

	public modify(leadId: string, message: string): Observable<Message> {
		return this.httpClient.post<Message>(
			`${this.api.communicationsApiEndpoint}/autopilot/${leadId}/modify`,
			{
				userId: this.cognitoService.user().id,
				message,
				propertyId: this.workspaceService.workspaceId(),
			},
		);
	}

	public dismiss(leadId: string): Observable<void> {
		return this.httpClient.post<void>(
			`${this.api.communicationsApiEndpoint}/autopilot/${leadId}/dismiss`,
			{
				userId: this.cognitoService.user().id,
				propertyId: this.workspaceService.workspaceId(),
			},
		);
	}
}
