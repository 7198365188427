import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class AddressService {
	street(address: Address): string | undefined {
		return address.address_components.find((component) =>
			component.types.includes("route"),
		)?.long_name;
	}

	streetNumber(address: Address): string | undefined {
		return address.address_components.find((component) =>
			component.types.includes("street_number"),
		)?.long_name;
	}

	city(address: Address): string | undefined {
		return address.address_components.find(
			(component) =>
				component.types.includes("locality") ||
				component.types.includes("sublocality"),
		)?.long_name;
	}

	state(address: Address): string | undefined {
		return address.address_components.find((component) =>
			component.types.includes("administrative_area_level_1"),
		)?.long_name;
	}

	zip(address: Address): string | undefined {
		return address.address_components.find((component) =>
			component.types.includes("postal_code"),
		)?.long_name;
	}

	country(address: Address): string | undefined {
		return address.address_components.find((component) =>
			component.types.includes("country"),
		)?.long_name;
	}

	neighborhood(address: Address): string | undefined {
		return address.address_components.find((component) =>
			component.types.includes("neighborhood"),
		)?.long_name;
	}

	latitude(address: Address) {
		return address.geometry.location.lat();
	}

	longitude(address: Address) {
		return address.geometry.location.lng();
	}

	addressLink(address: Address) {
		return address.url;
	}
}

export interface AddressComponent {
	long_name: string;
	short_name: string;
	types: string[];
}

export interface Address {
	address_components: AddressComponent[];
	adr_address: string;
	formatted_address: string;
	formatted_phone_number: string;
	geometry: Geometry;
	html_attributions: string[];
	icon: string;
	id: string;
	international_phone_number: string;
	name: string;
	opening_hours: OpeningHours;
	permanently_closed: boolean;
	photos: Photo[];
	place_id: string;
	price_level: number;
	rating: number;
	reviews: PlaceReview[];
	types: string[];
	url: string;
	utc_offset?: number;
	vicinity: string;
	website: string;
}

export interface Geometry {
	location: LatLng;
	viewport: LatLngBounds;
}

export interface LatLng {
	equals(other: LatLng): boolean;
	lat(): number;
	lng(): number;
	toString(): string;
	toUrlValue(precision?: number): string;
	toJSON(): LatLngLiteral;
}

export declare type LatLngLiteral = {
	lat: number;
	lng: number;
};

export declare type LatLngBoundsLiteral = {
	east: number;
	north: number;
	south: number;
	west: number;
};

export interface LatLngBounds {
	contains(latLng: LatLng | LatLngLiteral): boolean;
	equals(other: LatLngBounds | LatLngBoundsLiteral): boolean;
	extend(point: LatLng | LatLngLiteral): LatLngBounds;
	getCenter(): LatLng;
	getNorthEast(): LatLng;
	getSouthWest(): LatLng;
	intersects(other: LatLngBounds | LatLngBoundsLiteral): boolean;
	isEmpty(): boolean;
	toJSON(): LatLngBoundsLiteral;
	toSpan(): LatLng;
	toString(): string;
	toUrlValue(precision?: number): string;
	union(other: LatLngBounds | LatLngBoundsLiteral): LatLngBounds;
}

export interface PlaceReview {
	aspects: PlaceAspectRating[];
	author_name: string;
	author_url: string;
	language: string;
	text: string;
}

export interface PlaceAspectRating {
	rating: number;
	type: string;
}

export interface Photo {
	height: number;
	html_attributions: string[];
	width: number;
	getUrl(request: PhotoRequest): string;
}

export declare class PhotoRequest {
	maxWidth: number;
	maxHeight: number;
}

export interface OpeningHours {
	open_now: boolean;
	periods: OpeningPeriod[];
	weekday_text: string[];
}

export interface OpeningPeriod {
	open: OpeningHoursTime;
	close?: OpeningHoursTime;
}

export interface OpeningHoursTime {
	day: number;
	hours: number;
	minutes: number;
	nextDate: number;
	time: string;
}

export interface FormattedAddress {
	address?: string | null;
	streetNumber?: string | null;
	street?: string | null;
	city?: string | null;
	state?: string | null;
	zipCode?: string | null;
	neighborhood?: string | null;
	latitude?: number | null;
	longitude?: number | null;
	country?: string | null;
}
