export class BackgroundTask {
	constructor(
		public readonly id: string,
		private readonly _task: () => void | Promise<void>,
	) {}

	public async run(): Promise<void> {
		await this._task();
	}
}
