import { isDevMode } from "@angular/core";
import * as Sentry from "@sentry/angular";

export enum LogLevel {
	NONE = 0,
	ERROR = 1,
	WARN = 2,
	INFO = 3,
	DEBUG = 4,
}

export interface LoggerConfig {
	level: LogLevel;
}

/**
 * Logger class
 * @description A class that provides logging functionality with colored output.
 * @param caller - The name of the class that is using the logger.
 * @param config - The logger configuration.
 * - level - The log level for the logger.
 * - 0: No logging.
 * - 1: Log errors only.
 * - 2: Log errors and warnings.
 * - 3: Log everything (INFO, WARN, ERROR, and DEBUG).
 * - 4: Log debug messages only.
 */
export class Logger {
	constructor(
		private caller: string,
		private config: LoggerConfig,
	) {}

	private colors = {
		reset: "\x1b[0m",
		bright: "\x1b[1m",
		dim: "\x1b[2m",
		red: "\x1b[31m",
		green: "\x1b[32m",
		yellow: "\x1b[33m",
		blue: "\x1b[34m",
		magenta: "\x1b[35m",
		cyan: "\x1b[36m",
		white: "\x1b[37m",
	};

	public debug(...args: any[]): void {
		this._captureSentryMessage(args.join(" "), "debug");
		if (this.config.level === 4) {
			console.debug(
				`${this.colors.cyan}[DEBUG][${this.caller}]${this.colors.reset}`,
				...args,
			);
		}
	}

	public info(...args: any[]): void {
		this._captureSentryMessage(args.join(" "), "info");
		if (this.config.level >= 3) {
			console.info(
				`${this.colors.blue}[INFO][${this.caller}]${this.colors.reset}`,
				...args,
			);
		}
	}

	public log(...args: any[]): void {
		this._captureSentryMessage(args.join(" "), "log");
		if (this.config.level >= 3) {
			console.log(`[${this.caller}]`, ...args);
		}
	}

	public warn(...args: any[]): void {
		this._captureSentryMessage(args.join(" "), "warning");
		if (this.config.level >= 2) {
			console.warn(
				`${this.colors.yellow}[WARN][${this.caller}]${this.colors.reset}`,
				...args,
			);
		}
	}

	public error(...args: any[]): void {
		this._captureSentryMessage(args.join(" "), "error");
		if (this.config.level >= 1) {
			console.error(
				`${this.colors.red}[ERROR][${this.caller}]${this.colors.reset}`,
				...args,
			);
		}
	}

	private _captureSentryMessage(
		message: string,
		level: "fatal" | "error" | "warning" | "log" | "info" | "debug",
	): void {
		if (isDevMode()) return;
		Sentry.captureMessage(message, level);
	}
}
