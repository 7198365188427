import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class PersistanceService {
	set(key: string, data: any): void {
		try {
			localStorage.setItem(key, JSON.stringify(data));
		} catch (e) {
			console.error("Error saving to localStorage", e);
		}
	}

	get(key: string): any | null {
		try {
			const rawItem = localStorage.getItem(key);
			if (rawItem) {
				return JSON.parse(rawItem);
			}
			return null;
		} catch (e) {
			console.error("Error getting data from localStorage", e);
			return null;
		}
	}

	lastUpdate(query: string): string {
		const items = this.get(query);
		const dates = items?.map((item: any) => new Date(item.updatedAt));
		if (dates.length > 0) {
			return new Date(Math.max(...dates.map(Number))).toISOString();
		}
		return new Date(1, 1, 1).toISOString();
	}

	mergeItems(query: string, newItems: { id: string }[]): any[] {
		const newItemIds = new Set(newItems.map((item) => item.id));
		const existingItems = this.get(query).filter(
			(item: any) => !newItemIds.has(item.id),
		);
		const mergedItems = existingItems.concat(newItems);
		this.set(query, mergedItems);
		return mergedItems;
	}
}
