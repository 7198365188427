import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject, map } from "rxjs";
import {
	type CreateLeadInput,
	type Lead,
	LeadReplicache,
	type UpdateLeadInput,
} from "src/app/schema";
import {
	ApiService,
	ReplicacheService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class LeadsService {
	constructor(
		private api: ApiService,
		private httpClient: HttpClient,
		private replicacheService: ReplicacheService,
	) {}

	public createLead(lead: CreateLeadInput): Observable<Lead> {
		return this.httpClient
			.post<Lead>(`${this.api.communicationsApiEndpoint}/lead`, lead)
			.pipe(map((lead) => lead));
	}

	public updateLead(lead: UpdateLeadInput): Observable<Lead> {
		return this.httpClient
			.put<Lead>(`${this.api.communicationsApiEndpoint}/lead`, lead)
			.pipe(map((lead) => lead));
	}

	public readLead(leadId: string): Observable<Lead> {
		return this.httpClient
			.put<Lead>(
				`${this.api.communicationsApiEndpoint}/lead/${leadId}/read`,
				{},
			)
			.pipe(map((lead) => lead));
	}

	public archiveLead(leadId: string): Observable<Lead> {
		return this.httpClient
			.put<Lead>(
				`${this.api.communicationsApiEndpoint}/lead/${leadId}/archive`,
				{},
			)
			.pipe(map((lead) => lead));
	}

	public getLead(leadId: string): Observable<Lead> {
		const transaction = async (tx: ReadTransaction) =>
			await tx.get(`${LeadReplicache.prefix}/${leadId}`);
		const lead$ = new Subject<Lead>();
		this.replicacheService.subscribe(transaction, (lead) => lead$.next(lead));
		return lead$;
	}

	public leadsByCampaign(campaignId: string): Observable<Lead[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: LeadReplicache.IndexNames.byCampaignId,
			start: { key: [campaignId] },
			prefix: campaignId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const leadsByCampaignId$ = new Subject<Lead[]>();
		this.replicacheService.subscribe(transaction, (leads) =>
			leadsByCampaignId$.next(leads),
		);
		return leadsByCampaignId$;
	}

}