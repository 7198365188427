import { Injectable } from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";
import { type Observable, Subject } from "rxjs";
import { type Inquiry, InquiryReplicache } from "src/app/schema";
import { ReplicacheService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class InquiryService {
	constructor(private replicacheService: ReplicacheService) {}

	public leadInquiries(leadId: string): Observable<Inquiry[]> {
		const scanOptions: ScanIndexOptions = {
			indexName: InquiryReplicache.IndexNames.byLeadId,
			start: { key: [leadId] },
			prefix: leadId,
		};
		const transaction = async (tx: ReadTransaction) =>
			await tx.scan(scanOptions).values().toArray();
		const inquiriesByLeadId$ = new Subject<Inquiry[]>();
		this.replicacheService.subscribe(transaction, (inquiries) =>
			inquiriesByLeadId$.next(inquiries),
		);
		return inquiriesByLeadId$;
	}
}
