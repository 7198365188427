import type {
	IndexDefinitions,
	ReadTransaction,
	ScanIndexOptions,
} from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";

export namespace OpenHouseReplicache {
	export const prefix = "openHouse";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyOpenHousesId",
			prefix: `${prefix}/`,
		},
	};

	export const Resolvers = {
		OpenHouseById: (id: string) => {
			const transaction = async (tx: ReadTransaction) =>
				await tx.get(`${prefix}/${id}`);
			return transaction as unknown as (
				tx: ReadTransaction,
			) => Promise<OpenHouse>;
		},
		OpenHousesByPropertyId: (propertyId: string) => {
			const scanOptions: ScanIndexOptions = {
				indexName: IndexNames.byPropertyId,
				start: { key: [propertyId] },
				prefix: propertyId,
			};
			const transaction = async (tx: ReadTransaction) =>
				await tx.scan(scanOptions).values().toArray();
			return transaction as unknown as (
				tx: ReadTransaction,
			) => Promise<OpenHouse[]>;
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export type OpenHouseUnit = {
	id: string;
	name: string;
};

export enum OpenHouseStatus {
	Confirmed = "Confirmed",
	Canceled = "Canceled",
}

export interface OpenHouse {
	id: string;
	createdAt: string;
	__typename: "OpenHouse";
	startDate: string;
	endDate: string;
	status: OpenHouseStatus;
	appointmentOnly: boolean;
	units: Array<OpenHouseUnit>;
	propertyOpenHousesId: string;
	organizationOpenHousesId?: string | null;
}

type OpenHouseProperties = Omit<OpenHouse, "__typename">;
export type CreateOpenHouseInput = Partial<OpenHouseProperties>;
export type UpdateOpenHouseInput = Partial<OpenHouseProperties> & {
	id: string;
};
