import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable } from "rxjs";
import type { Property, UpdatePropertyInput } from "src/app/schema";
import { ApiService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class PropertyService {
	
	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
	) {}

	public getProperty(propertyId: string): Observable<Property> {
		return this.httpClient.get<Property>(
			`${this.api.coreApiEndpoint}/property/${propertyId}`,
		);
	}

	public updateProperty(property: UpdatePropertyInput): Observable<Property> {
		return this.httpClient.put<Property>(
			`${this.api.coreApiEndpoint}/property`,
			property,
		);
	}

	public deleteProperty(propertyId: string): Observable<{ id: string }> {
		return this.httpClient.delete<{ id: string }>(
			`${this.api.coreApiEndpoint}/property/${propertyId}`,
		);
	}
}
