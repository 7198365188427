import type { IndexDefinitions } from "replicache";

import type { Replicacheable } from "src/app/services/replicache/types";
import type {
	MessageChannel,
	MessageSource,
	MessageStatus,
	MessageTemplate,
	MessageType,
} from "../../enumerations";

export namespace MessageReplicache {
	export const prefix = "message";

	export enum IndexNames {
		byPropertyId = `${prefix}/byPropertyId`,
		byLeadId = `${prefix}/byLeadId`,
	}

	export const IndexDefinitions: IndexDefinitions = {
		[IndexNames.byPropertyId]: {
			jsonPointer: "/propertyMessagesId",
			prefix: `${prefix}/`,
		},
		[IndexNames.byLeadId]: {
			jsonPointer: "/leadMessagesId",
			prefix: `${prefix}/`,
		},
	};

	export const Model: Replicacheable = {
		prefix,
		IndexDefinitions,
	};
}

export interface Message {
	__typename: "Message";
	id: string;
	createdAt: string;
	updatedAt: string;
	date: string;
	template?: MessageTemplate;
	outgoing: boolean;
	type: MessageType;
	source: MessageSource;
	status: MessageStatus;
	channels: MessageChannel[];
	message?: string | null;
	sent?: string | null;
	delivered?: string | null;
	opened?: string | null;
	clicked?: string | null;
	read?: string | null;
	leadMessagesId: string;
	userMessagesId: string;
	user: MessageUser;
	organizationMessagesId?: string | null;
	propertyMessagesId?: string | null;
}

export type SendMessageInput = {
	message: string;
	leadId: string;
	userId: string;
	propertyId?: string | null;
};

export type MessageUser = {
	id: string;
	faviconSrc?: string | null;
	firstName: string;
	lastName: string;
	email: string;
};
