import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, map } from "rxjs";
import {
	type CreateUnitInput,
	type DescribeUnitInput,
	type ListUnitInput,
	type Unit,
	type UpdateUnitInput,
} from "src/app/schema";
import {
	ApiService,
	CognitoService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class UnitService {

	constructor(
		private api: ApiService,
		private cognitoService: CognitoService,
		private httpClient: HttpClient,
	) {}

	public describeUnit(unit: DescribeUnitInput): Observable<string> {
		return this.httpClient
			.post<{ description: string }>(
				`${this.api.inventoryApiEndpoint}/units/${unit.id}/describe`,
				unit,
			)
			.pipe(map((response) => response.description));
	}

	public createUnit(unit: CreateUnitInput): Observable<Unit> {
		return this.httpClient.post<Unit>(
			`${this.api.inventoryApiEndpoint}/unit`,
			unit,
		);
	}

	public updateUnit(unit: UpdateUnitInput): Observable<Unit> {
		return this.httpClient.put<Unit>(
			`${this.api.inventoryApiEndpoint}/unit`,
			unit,
		);
	}

	public listUnit(unit: UpdateUnitInput): Observable<Unit> {
		return this.httpClient.post<Unit>(
			`${this.api.inventoryApiEndpoint}/unit/${unit.id}/list`,
			unit,
		);
	}

	public delistUnit(unit: ListUnitInput): Observable<Unit> {
		return this.httpClient.post<Unit>(
			`${this.api.inventoryApiEndpoint}/unit/${unit.id}/delist/${unit.status}`,
			unit,
		);
	}

	public assignUnit(unitId: string, targetUserId: string) {
		return this.httpClient.post<Unit>(
			`${this.api.inventoryApiEndpoint}/unit/${unitId}/assign`,
			{
				newUserId: targetUserId,
				userId: this.cognitoService.user().id,
				organizationId: this.cognitoService.user().organizationId,
			},
		);
	}

}