import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, type Observable } from "rxjs";
import type {
	CreateSendInput,
	Send,
	UpdateSendInput,
} from "src/app/schema";
import {
	ApiService,
	WorkspaceService,
} from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class SendService {

	constructor(
		private api: ApiService,
		private httpClient: HttpClient,
		private workspaceService: WorkspaceService,
	) { }

	public sendTestEmail(templateId: string, emails: string[]): Observable<void> {
		return this.httpClient.post<void>(
			`${this.api.emailMarketingApiEndpoint}/properties/${this.workspaceService.workspaceId()}/test/templates/${templateId}`,
			{ emails },
		);
	}

	public getSend(id: string): Observable<Send> {
		return this.httpClient.get<Send>(
			`${this.api.emailMarketingApiEndpoint}/send/${id}`,
		);
	}

	public createSend(send: CreateSendInput): Observable<Send> {
		return this.httpClient.post<Send>(
			`${this.api.emailMarketingApiEndpoint}/send`,
			send,
		);
	}

	public send(
		sendId: string,
		templateId: string,
		leadIds: string[],
	): Observable<Send> {
		return this.httpClient.post<Send>(
			`${this.api.emailMarketingApiEndpoint}/properties/${this.workspaceService.workspaceId()}/send/${sendId}`,
			{ leadIds, templateId: templateId },
		);
	}

	public updateSend(send: UpdateSendInput): Observable<Send> {
		return this.httpClient.put<Send>(
			`${this.api.emailMarketingApiEndpoint}/send`,
			send,
		);
	}

}