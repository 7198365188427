import {
	DestroyRef,
	type Signal,
	type ValueEqualityFn,
	type WritableSignal,
	effect,
	inject,
	signal,
} from "@angular/core";
import type { ReadTransaction, ScanIndexOptions } from "replicache";

import { ReplicacheService } from "src/app/services";

/**
 * Type alias for a reactive query function used with Replicache.
 *
 * @template T The type of data returned by the query.
 * @template U The type of input parameter for the query.
 * @param {U} input The input parameter for the query.
 * @returns {(tx: ReadTransaction) => Promise<T>} A function that takes a ReadTransaction and returns a Promise of the queried data.
 */
export type ReactiveQuery<T, U> = (
	input: U,
) => (tx: ReadTransaction) => Promise<T>;

/**
 * Options for useReplicache hook.
 *
 * @template T The type of data returned by the query.
 * @param {T} [initialValue] Initial value for the data signal.
 * @param {boolean} [debug] Enable debug mode.
 */
export type useReplicacheOptions<T> = { initialValue?: T; debug?: boolean };

/**
 * Default options for useReplicache hook.
 */
const defaultOptions: useReplicacheOptions<any> = { debug: false };

/**
 * Custom hook to use Replicache for reactive data fetching with a query function.
 *
 * @template T - The type of data returned by the query.
 * @template U - The type of input parameter for the query.
 * @param {ReactiveQuery<T, U>} query - The query function to execute.
 * @param {Signal<U>} input - A signal containing the input parameter for the query.
 * @param {useReplicacheOptions<T>} [options] - Optional parameters.
 * @returns {Signal<T>} - A signal containing the query result.
 */
export function useReplicache<T, U>(
	query: ReactiveQuery<T, U>,
	input: Signal<U>,
	options?: useReplicacheOptions<T>,
): Signal<T>;

/**
 * Custom hook to use Replicache for reactive data fetching with a scan index.
 *
 * @template T - The type of data returned by the query.
 * @param {string} indexName - The name of the index to scan.
 * @param {Signal<string>} input - A signal containing the input parameter for the scan.
 * @param {useReplicacheOptions<T>} [options] - Optional parameters.
 * @returns {Signal<T>} - A signal containing the query result.
 */
export function useReplicache<T>(
	indexName: string,
	input: Signal<string>,
	options?: useReplicacheOptions<T>,
): Signal<T>;

export function useReplicache<T, U>(
	queryOrIndex: ReactiveQuery<T, U> | string,
	input: Signal<U | string>,
	options: useReplicacheOptions<T> = defaultOptions,
): Signal<T> {
	const replicache = inject(ReplicacheService).replicache;
	const cleanupRef = inject(DestroyRef);

	const { initialValue, debug } = options;

	// Custom comparison function to avoid unnecessary equality computations.
	const equal: ValueEqualityFn<T> = () => false;

	const data: WritableSignal<T> = signal<T>(initialValue as T, { equal });

	let unsubscribe: (() => void) | undefined;

	const destroy = () => {
		if (!unsubscribe) return;
		if (debug) console.log("Destroying Replicache subscription.");
		unsubscribe();
		unsubscribe = undefined;
	};

	const onData = (result: T) => {
		data.set(result);
		if (debug) console.log("Signal updated with fresh Replicache data.");
	};

	const buildSubscription = (input: U | string): (() => void) | undefined => {
		if (debug) console.log("Building Replicache subscription.");
		if (typeof queryOrIndex === "function") {
			return replicache.subscribe(queryOrIndex(input as U), onData);
		}
		if (typeof queryOrIndex === "string") {
			const indexName = queryOrIndex;
			const scanOptions: ScanIndexOptions = {
				indexName,
				start: { key: [input as string] },
				prefix: input as string,
			};
			const transaction = async (tx: ReadTransaction) =>
				(await tx.scan(scanOptions).toArray()) as T;
			return replicache.subscribe(transaction, onData);
		}
		return undefined;
	};

	const execute = (input: U | string) => {
		if (debug) console.log("Query input changed.");
		destroy();
		unsubscribe = buildSubscription(input);
	};

	effect(() => execute(input()));

	cleanupRef.onDestroy(destroy);

	return data;
}
