import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Call, Device } from "@twilio/voice-sdk";
import type AudioHelper from "@twilio/voice-sdk/es5/twilio/audiohelper";
import { type Observable, ReplaySubject, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import type { Lead } from "src/app/schema";
import { ApiService, CognitoService, WorkspaceService } from "src/app/services";
import { environment } from "src/environments/environment";

export interface TokenResponse {
	token: string;
	identity: string;
}

export type CallSubjectContent = {
	call: Call;
	direction: "inbound" | "outbound";
	lead?: Lead | null;
	leadId?: string | null;
};

@Injectable({
	providedIn: "root",
})
export class TwilioService {
	device?: Device;
	activePhoneCall = new ReplaySubject<CallSubjectContent>();
	userPhoneNumber = "";

	private boundSetUpTwilioDevice: any;

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
		private cognitoService: CognitoService,
		private workspaceService: WorkspaceService,
	) {
		// this.boundSetUpTwilioDevice = this._setUpTwilioDevice.bind(this);
	}

	public setUpTwilioDevice(): void {
		document.addEventListener("click", this.boundSetUpTwilioDevice, true);
	}

	private _setUpTwilioDevice(): void {
		document.removeEventListener("click", this.boundSetUpTwilioDevice, true);
		if (this.device) return;
		console.log("Setting up Twilio Device...");
		this.authenticateWithTwilio()
			.pipe(take(1))
			.subscribe((data) => {
				if (!data) {
					console.log("Error authenticating with Twilio");
					return;
				}
				this.initializeDevice(data);
				this.registerDeviceHandlers();
			});
	}

	private authenticateWithTwilio(): Observable<TokenResponse | null> {
		return this.httpClient
			.get<TokenResponse>(
				`${this.api.communicationsApiEndpoint}/${this.cognitoService.user().id}/authenticate`,
			)
			.pipe(catchError(() => of(null)));
	}

	private initializeDevice(data: TokenResponse): void {
		this.device = new Device(data.token, { edge: "ashburn" });
		this.userPhoneNumber = data.identity;
		this.device.register();
	}

	private registerDeviceHandlers(): void {
		if (this.device) {
			this.device.on("registered", this.handleDeviceRegistered);
			this.device.on("incoming", this.handleIncomingCall);
			this.device.on("tokenWillExpire", this.refreshDeviceToken);
			this.device.on("cancel", this.handleCallEnd);
			this.device.on("reject", this.handleCallEnd);
			this.device.on("disconnect", this.handleCallEnd);
		}
	}

	private handleDeviceRegistered = (): void => {
		const device: Device | undefined = this.device;
		if (!device || this.isSafari) return;
		const deviceAudio: AudioHelper | null = device.audio;
		if (!deviceAudio) return;
		const availableDevices = Array.from(
			deviceAudio.availableOutputDevices.keys(),
		);
		deviceAudio.ringtoneDevices.set(availableDevices);
	};

	private handleIncomingCall = (call: Call): void => {
		const leadId = call.parameters["leadId"];
		this.activePhoneCall.next({ call, leadId, direction: "inbound" });
	};

	async initiateCallToLead(lead: Lead): Promise<void> {
		const device: Device | undefined = this.device;
		if (!device) return;
		const from = environment.production
			? this.workspaceService.workspace().phoneNumber
			: this.userPhoneNumber;
		if (
			!lead.phoneNumber ||
			!this.workspaceService.workspaceId()
		)
			return;
		const startCallParams: Record<string, string> | undefined = {
			To: lead.phoneNumber,
			From: from!,
			Outbound: "True",
			leadId: lead.id,
			userId: this.cognitoService.user().id,
			propertyId: this.workspaceService.workspaceId(),
		};
		const call = await device.connect({ params: startCallParams });
		this.activePhoneCall.next({
			lead: lead,
			call: call,
			direction: "outbound",
		});
	}

	refreshDeviceToken() {
		this.authenticateWithTwilio()
			.pipe(map((data) => data?.token))
			.subscribe((token) => {
				if (token) this.device?.updateToken(token);
			});
	}

	private handleCallEnd = (call: Call): void => {
		this.activePhoneCall.next({ call, direction: "inbound" });
	};

	private get isSafari(): boolean {
		const userAgent = window.navigator.userAgent;
		const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
		return isSafari;
	}
}
