import { Injectable, NgZone, inject } from "@angular/core";
import {
	type Cookie,
	type IndexDefinitions,
	type MutatorDefs,
	type ReadTransaction,
	Replicache,
	type SubscribeOptions,
} from "replicache";

import { Subject, takeUntil } from "rxjs";
import { Logger } from "src/app/services";
import { environment } from "src/environments/environment";
import {
	COOKIE,
	PokeEvent,
	PokeService,
	PullService,
	PushService,
	ReplicacheUtils,
	type Replicacheable,
	SyncState,
} from "./";

@Injectable({
	providedIn: "root",
})
export class ReplicacheService {
	private readonly _logger = inject(Logger);

	private _closed$ = new Subject<void>();

	private _replicache: Replicache<MutatorDefs> | undefined;

	public get replicache(): Replicache<MutatorDefs> {
		if (!this._replicache) throw new Error("Replicache not initialized.");
		return this._replicache;
	}

	private set replicache(replicache: Replicache<MutatorDefs>) {
		this._replicache = replicache;
	}

	public get initialized(): boolean {
		return !!this._replicache;
	}

	public get lastCookie(): Cookie {
		return COOKIE.get();
	}

	public indexDefinitions: IndexDefinitions = {};
	public mutatorDefinitions: MutatorDefs = {};

	constructor(
		private ngZone: NgZone,
		private pullService: PullService,
		private pokeService: PokeService,
		private pushService: PushService,
	) {}

	public async init(models: Replicacheable[]): Promise<void> {
		this._logger.info("Initializing Replicache...");
		await this.ngZone.runOutsideAngular(async () => await this._init(models));
		await this.pokeService.connect();
	}

	public subscribe(
		transaction: (tx: ReadTransaction) => Promise<any>,
		options: SubscribeOptions<any> | ((result: any) => void),
	): () => void {
		return this.replicache.subscribe(transaction, options);
	}

	public mutate(mutation: string, args: any): Promise<any> {
		return this.replicache.mutate[mutation](args);
	}

	public forcePull(): Promise<void> {
		this._logger.log("FORCING PULL...");
		return this.replicache.pull({ now: true });
	}

	public async close(): Promise<void> {
		this._logger.info("Closing Replicache...");
		await this.replicache.close();
		await this.pokeService.disconnect();
		this._closed$.next();
		this._closed$.complete();
		this._closed$ = new Subject<void>();
	}

	private async _init(models: Replicacheable[]): Promise<void> {
		this.indexDefinitions = new ReplicacheUtils.IndexBuilder(models).build();
		this.mutatorDefinitions = new ReplicacheUtils.MutatorBuilder(
			models,
		).build();
		const { name, schemaVersion, licenseKey, experimental } =
			environment.replicache;
		const { pushEnabled } = experimental;
		const puller = this.pullService.puller;
		const pusher = this.pushService.pusher;
		const indexes = this.indexDefinitions;
		const mutators = this.mutatorDefinitions;
		this._replicache = new Replicache<MutatorDefs>({
			name,
			// TODO: Set pullInterval to null and implement a custom interval mechanism that is offline-aware
			pullInterval: 300000, // 5 minutes
			schemaVersion,
			licenseKey,
			puller,
			...(pushEnabled ? { pusher } : {}),
			indexes,
			mutators,
		});
		this.pullService.registerSyncStateObserver(this.replicache);
		this._registerPokeObserver();
	}

	private _registerPokeObserver(): void {
		const onPoke = async (event: PokeEvent) => {
			const { partitionKey } = event;
			if (this.pullService.syncState === SyncState.COMPLETE) {
				if (partitionKey) {
					this._logger.log(
						"POKED WITH PARTITION KEY:",
						partitionKey,
						"PULLING...",
					);
					this.pullService.pokePartitionKeyQueue.enqueue(partitionKey);
					await this.replicache.pull();
				} else {
					this._logger.log("POKED. PULLING...");
					await this.replicache.pull();
				}
			} else {
				this._logger.log("POKED. SYNC STATE IS NOT COMPLETE. NOT PULLING.");
			}
		};
		this.pokeService.onPoke$.pipe(takeUntil(this._closed$)).subscribe(onPoke);
	}
}
