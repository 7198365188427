import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	EMPTY,
	type Observable,
	Subject,
	catchError,
	filter,
	map,
	merge,
	of,
	shareReplay,
	switchMap,
	takeUntil,
	tap,
	timer,
	zip,
} from "rxjs";

export interface Entry {
	id: string;
	createdAt: string;
	updatedAt: string;
}

// Refresh every 180 seconds.
const REFRESH_INTERVAL = 180000;

@Injectable({
	providedIn: "root",
})
export class CacheService {
	private itemCache: { [id: string]: Observable<Partial<Entry>> } = {};
	private arrayCache: { [id: string]: Observable<Partial<Entry[]>> } = {};
	private lastUpdate: { [id: string]: string } = {};
	private newDataSubject: { [id: string]: Subject<void> } = {};
	private endSubject: { [id: string]: Subject<void> } = {}; // Used to reload all data after a deletion.

	private queriesByItemId: { [id: string]: Set<string> } = {};

	constructor(private httpClient: HttpClient) {}

	private createItemCache(query: string): Observable<Partial<Entry>> {
		const timer$ = timer(0, REFRESH_INTERVAL);
		return merge(timer$, this.newDataSubject[query]).pipe(
			switchMap((_) => {
				if (!this.lastUpdate.hasOwnProperty(query)) {
					return this.httpClient.get<Partial<Entry>>(query).pipe(
						catchError((error) => {
							this.handleError(error);
							return EMPTY;
						}),
						tap((response) => {
							this.lastUpdate[query] = response.updatedAt!;
							this.updateQueriesByItemId(response.id!, query);
						}),
					);
				}
				return this.httpClient
					.get<Partial<Entry>>(`${query}/${this.lastUpdate[query]}`)
					.pipe(
						tap((response) => {
							if (Object.keys(response).length === 0) {
								this.endSubject[query].next();
							}
						}),
						filter((response) => Object.keys(response).length > 1),
						catchError((error) => {
							this.handleError(error);
							return EMPTY;
						}),
						tap((response) => {
							this.lastUpdate[query] = response.updatedAt!;
						}),
					);
			}),
			takeUntil(this.endSubject[query]),
			shareReplay(1),
		);
	}

	private createArrayCache(query: string): Observable<Partial<Entry[]>> {
		const timer$ = timer(0, REFRESH_INTERVAL);
		return merge(timer$, this.newDataSubject[query]).pipe(
			switchMap((_) => {
				if (!this.lastUpdate.hasOwnProperty(query)) {
					return this.loadAllItems(query);
				}
				return this.mergeDataArrays(query);
			}),
			takeUntil(this.endSubject[query]),
			shareReplay(1),
		);
	}

	private loadAllItems(query: string): Observable<Partial<Entry[]>> {
		return this.httpClient.get<{ items: Partial<Entry[]> }>(query).pipe(
			map((response) => response.items),
			catchError((error) => {
				this.handleError(error);
				return EMPTY;
			}),
			tap((items) => {
				items.map((item) => this.updateQueriesByItemId(item?.id!, query));
			}),
			tap((items) => {
				this.lastUpdate[query] = items
					.map((item) => item?.updatedAt!)
					.reduce((a, b) => (a > b ? a : b), new Date(1, 1, 1).toISOString());
			}),
		);
	}

	private loadRecentItems(query: string): Observable<Partial<Entry[]>> {
		return this.httpClient
			.get<{ items: Partial<Entry[]> }>(`${query}/${this.lastUpdate[query]}`)
			.pipe(
				tap((response) => console.log(response.items)),
				map((response) => response.items),
				catchError((error) => {
					this.handleError(error);
					return EMPTY;
				}),
				tap((items) => {
					items.map((item) => this.updateQueriesByItemId(item?.id!, query));
				}),
				tap((items) => {
					if (items.length > 0) {
						this.lastUpdate[query] = items
							.map((item) => item?.updatedAt!)
							.reduce(
								(a, b) => (a > b ? a : b),
								new Date(1, 1, 1).toISOString(),
							);
					}
				}),
			);
	}

	private mergeDataArrays(query: string): Observable<Partial<Entry[]>> {
		return zip(this.arrayCache[query], this.loadRecentItems(query)).pipe(
			map(([allItems, recentItems]) =>
				allItems
					.filter(
						(item) => !recentItems.map((item) => item?.id).includes(item?.id),
					)
					.concat(recentItems),
			),
		);
	}

	private updateQueriesByItemId(itemId: string, query: string) {
		if (!this.queriesByItemId.hasOwnProperty(itemId)) {
			this.queriesByItemId[itemId] = new Set([query]);
		} else {
			this.queriesByItemId[itemId] = new Set([
				...this.queriesByItemId[itemId],
				...[query],
			]);
		}
	}

	clear(query: string) {
		this.endSubject[query].next();
		if (this.arrayCache.hasOwnProperty(query)) {
			this.arrayCache[query] = of([]);
		} else if (this.itemCache.hasOwnProperty(query)) {
			this.itemCache[query] = EMPTY;
		}
	}

	add(query: string) {
		if (this.newDataSubject.hasOwnProperty(query)) {
			this.newDataSubject[query].next();
		}
	}

	update(itemId: string): void {
		if (this.queriesByItemId.hasOwnProperty(itemId)) {
			for (const query of this.queriesByItemId[itemId]) {
				this.newDataSubject[query].next();
			}
		}
	}

	remove(itemId: string): void {
		if (this.queriesByItemId.hasOwnProperty(itemId)) {
			for (const query of this.queriesByItemId[itemId]) {
				if (this.itemCache.hasOwnProperty(query)) {
					this.newDataSubject[query].next();
				} else if (this.arrayCache.hasOwnProperty(query)) {
					this.arrayCache[query] = this.arrayCache[query].pipe(
						map((items) => items.filter((item) => item?.id !== itemId)),
					);
				}
			}
		}
	}

	refreshArray(query: string): void {
		if (this.lastUpdate.hasOwnProperty(query)) {
			delete this.lastUpdate[query];
		}
		if (this.newDataSubject.hasOwnProperty(query)) {
			this.newDataSubject[query].next();
		}
	}

	loadItem(query: string): Observable<Partial<Entry>> {
		if (!this.itemCache.hasOwnProperty(query)) {
			this.endSubject[query] = new Subject<void>();
			this.newDataSubject[query] = new Subject<void>();
			this.itemCache[query] = this.createItemCache(query);
		}
		return this.itemCache[query];
	}

	loadArray(query: string): Observable<Partial<Entry[]>> {
		if (!this.arrayCache.hasOwnProperty(query)) {
			this.endSubject[query] = new Subject<void>();
			this.newDataSubject[query] = new Subject<void>();
			this.arrayCache[query] = this.createArrayCache(query);
			this.newDataSubject[query].next();
		}
		return this.arrayCache[query].pipe(tap((respone) => "Array Emission"));
	}

	private handleError(error: string) {
		console.error(error);
	}
}
