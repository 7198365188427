export enum ApplicationRole {
	PrimaryApplicant = "PrimaryApplicant",
	AdditionalApplicant = "AdditionalApplicant",
	Guarantor = "Guarantor",
}

export enum VirtualTourType {
	Unit = "Unit",
	Amenity = "Amenity",
}

export enum ChangeLogParties {
	User = "User",
	Lead = "Lead",
	Both = "Both",
}

export enum ActivityType {
	TourBooked = "TourBooked",
	TourRescheduled = "TourRescheduled",
	TourCanceled = "TourCanceled",
	PostTourEvalCompleted = "PostTourEvalCompleted",
	ApplicationStarted = "ApplicationStarted",
	ApplicationDocumentUpload = "ApplicationDocumentUpload",
	ApplicationSubmitted = "ApplicationSubmitted",
	ApplicationFeePaid = "ApplicationFeePaid",
	ApplicationApproved = "ApplicationApproved",
	ApplicationRejected = "ApplicationRejected",
	ApplicantAdded = "ApplicantAdded",
	ApplicationPortalLogin = "ApplicationPortalLogin",
	DepositPaid = "DepositPaid",
	UnitListed = "UnitListed",
	UnitDelisted = "UnitDelisted",
	UnitAssigned = "UnitAssigned",
	UnitEdit = "UnitEdit",
	UnitPriceUpdate = "UnitPriceUpdate",
	UnitCreate = "UnitCreate",
	TextReceive = "TextReceive",
	TextSend = "TextSend",
	EmailSend = "EmailSend",
	EmailReceive = "EmailReceive",
	OutboundCall = "OutboundCall",
	InboundCall = "InboundCall",
	MissedCall = "MissedCall",
	CampaignCreate = "CampaignCreate",
	CampaignEdit = "CampaignEdit",
	NewInquiry = "NewInquiry",
}

export enum ConcessionType {
	None = "None",
	Amount = "Amount",
	Duration = "Duration",
}

export enum CommissionType {
	None = "None",
	Amount = "Amount",
	Duration = "Duration",
}

export enum UnitStatus {
	Unavailable = "Unavailable",
	Available = "Available",
	Listed = "Listed",
	InApplication = "InApplication",
	// LeasedAndPendingMoveIn = "LeasedAndPendingMoveIn",
	Leased = "Leased",
	InRenewal = "InRenewal",
}

export enum UnitType {
	SingleFamily = "SingleFamily",
	Apartment = "Apartment",
}

export enum PaymentStatus {
	Pending = "Pending",
	Requested = "Requested",
	Canceled = "Canceled",
	Processing = "Processing",
	Completed = "Completed",
	Failed = "Failed",
	Refunded = "Refunded",
}

export enum ReservationStatus {
	Pending = "Pending",
	Processing = "Processing",
	Active = "Active",
	Expired = "Expired",
	Failed = "Failed",
}

export enum PaymentType {
	ApplicationFee = "ApplicationFee",
	PreDeposit = "PreDeposit",
	Deposit = "Deposit",
	Rent = "Rent",
}

export enum PaymentMethod {
	Card = "Card",
	ACHDebit = "ACHDebit",
	Check = "Check",
	Cash = "Cash",
}

export enum PaymentProcessor {
	Stripe = "Stripe",
	Helcim = "Helcim",
	ACHQ = "ACHQ",
}

export enum PaymentCurrency {
	USD = "USD",
	CAD = "CAD",
}

export enum DepositType {
	Deposit = "Deposit",
	PreDeposit = "PreDeposit",
}

export enum DepositEventType {
	Requested = "Requested",
	Received = "Received",
	Refunded = "Refunded",
	Canceled = "Canceled",
	Declined = "Declined",
}

export enum QuestionType {
	TextInput = "TextInput",
	IntegerInput = "IntegerInput",
	FloatInput = "FloatInput",
	CurrencyInput = "CurrencyInput",
	AddressInput = "AddressInput",
	DatePicker = "DatePicker",
	BooleanChoice = "BooleanChoice",
	SingleChoice = "SingleChoice",
	MultipleChoice = "MultipleChoice",
	Custom = "Custom",
}

export enum QuestionCondition {
	Student = "Student",
	NotStudent = "NotStudent",
	American = "American",
	NotAmerican = "NotAmerican",
}

export enum QuestionIdentifier {
	SSN = "SSN",
	EmergencyContact = "EmergencyContact",
	Nationality = "Nationality",
}

export enum PlatformType {
	Lease = "Lease",
	Sales = "Sales",
}

export enum PropertyType {
	MultiFamily = "MultiFamily",
	Townhouse = "Townhouse",
	Condo = "Condo",
}

export enum TenantPaymentStatus {
	Paid = "Paid",
	Overdue = "Overdue",
	Delinquent = "Delinquent",
}

export enum ConferenceStatus {
	Ringing = "Ringing",
	Joinined = "Joinined",
	Declined = "Declined",
	TimedOut = "TimedOut",
}

export enum ApplicationDocumentStatus {
	PendingUpload = "PendingUpload",
	PendingReview = "PendingReview",
	Approved = "Approved",
	Rejected = "Rejected",
	Voided = "Voided",
}

export enum CampaignType {
	Placement = "Placement",
	BannerAd = "BannerAd",
	GoogleAd = "GoogleAd",
	GoogleAds = "GoogleAds",
}

export enum UserStatus {
	Available = "Available",
	Busy = "Busy",
	DoNotDisturb = "DoNotDisturb",
	Offline = "Offline",
	NewUser = "NewUser",
}

export enum TourType {
	InPerson = "InPerson",
	Virtual = "Virtual",
}

export enum BookingSource {
	Automatic = "Automatic",
	Arch = "Arch",
	User = "User",
}

export enum MaritalStatus {
	Single = "Single",
	MarriedNoChild = "MarriedNoChild",
	FamilyWithChildren = "FamilyWithChildren",
	CoupleDating = "CoupleDating",
	Roommates = "Roommates",
	EmptyNester = "EmptyNester",
}

export enum WorkFromHome {
	Yes = "Yes",
	No = "No",
	Flex = "Flex",
}

export enum PresentResidence {
	Own = "Own",
	Rent = "Rent",
	Family = "Family",
	CorporateHousing = "CorporateHousing",
}

export enum Gender {
	Male = "Male",
	Female = "Female",
	Other = "Other",
}

export enum LeadType {
	Prospect = "Prospect",
	Lead = "Lead",
	Broker = "Broker",
	Guarantor = "Guarantor",
	Applicant = "Applicant",
	Tenant = "Tenant",
	PostTenant = "PostTenant",
}

export enum MessageSource {
	User = "User",
	Website = "Website",
	TourScheduler = "TourScheduler",
	ApplicationPortal = "ApplicationPortal",
	Authentication = "Authentication",
	Campaign = "Campaign",
	Automated = "Automated",
}

export enum TextType {
	SMS = "SMS",
	MMS = "MMS",
}

export enum VisitType {
	Website = "Website",
	TourScheduler = "TourScheduler",
	ApplicationPortal = "ApplicationPortal",
	VirtualTour = "VirtualTour",
}

export enum ConversionType {
	Organic = "Organic",
	Email = "Email",
	Text = "Text",
	BannerAd = "BannerAd",
	GoogleAds = "GoogleAds",
	Campaign = "Campaign",
}

export enum DeviceType {
	Desktop = "Desktop",
	Mobile = "Mobile",
	Tablet = "Tablet",
	ConnectedTV = "ConnectedTV",
	Other = "Other",
}

export enum ApplicationStatus {
	Incomplete = "Incomplete",
	Complete = "Complete",
	Approved = "Approved",
	Rejected = "Rejected",
	Leased = "Leased",
	AwaitingDocumentation = "AwaitingDocumentation",
}

export enum PersonalDetailsStatus {
	Pending = "Pending",
	Complete = "Complete",
	AwaitingEdits = "AwaitingEdits",
	Approved = "Approved",
}

export enum DocumentationStatus {
	Pending = "Pending",
	Complete = "Complete",
	AwaitingUploads = "AwaitingUploads",
	Approved = "Approved",
}

export enum EvaluationType {
	PostTour = "PostTour",
}

export enum CreditBureau {
	Equifax = "Equifax",
	Transunion = "Transunion",
	Experian = "Experian",
}

export enum TagType {
	User = "User",
	Lead = "Lead",
	Application = "Application",
	Unit = "Unit",
}

export enum SupportModule {
	Dashboard = "Dashboard",
	Communications = "Communications",
	Calendar = "Calendar",
	Applications = "Applications",
	Campaigns = "Campaigns",
	Listings = "Listings",
}

export enum SupportRequestType {
	Question = "Question",
	FeatureRequest = "FeatureRequest",
	Error = "Error",
}

export enum SupportStatus {
	Opened = "Opened",
	AwaitingUserResponse = "AwaitingUserResponse",
	AwaitingLeadReelResponse = "AwaitingLeadReelResponse",
	Closed = "Closed",
}

export enum Weekday {
	Monday = "Monday",
	Tuesday = "Tuesday",
	Wednesday = "Wednesday",
	Thursday = "Thursday",
	Friday = "Friday",
	Saturday = "Saturday",
	Sunday = "Sunday",
}

export enum LeadReelProject {
	InquiryForm = "InquiryForm",
	TourScheduler = "TourScheduler",
	Website = "Website",
	Dashboard = "Dashboard",
	ApplicationPortal = "ApplicationPortal",
	Backend = "Backend",
}

export enum Module {
	Dashboard = "Dashboard",
	Communications = "Communications",
	Calendar = "Calendar",
	Applications = "Applications",
	Campaigns = "Campaigns",
	Listings = "Listings",
	Other = "Other",
}

export enum CampaignStatus {
	Active = "Active",
	Complete = "Complete",
	Enabled = "Enabled",
	Eligible = "Eligible",
	NotEligible = "NotEligible",
	Paused = "Paused",
	Removed = "Removed",
	Pending = "Pending",
	Incomplete = "Incomplete",
	Ended = "Ended",
}

export enum ILSProvider {
	StreetEasy = "StreetEasy",
	Zillow = "Zillow",
	Trulia = "Trulia",
	HotPads = "HotPads",
	RentPath = "RentPath",
	ApartmentsCom = "ApartmentsCom",
	ApartmentGuide = "ApartmentGuide",
}

export enum SourceType {
	Campaign = "Campaign",
	Website = "Website",
	PhoneNumber = "PhoneNumber",
	Email = "Email",
}

export enum PropertyGroupType {
	Leasing = "Leasing",
	Management = "Management",
	Portfolio = "Portfolio",
}

export enum GuarantorPolicy {
	Allowed = "Allowed",
	Student = "Student",
	NotAllowed = "NotAllowed",
}

export enum ScreeningType {
	Credit = "Credit",
	IncomeAndEmployment = "IncomeAndEmployment",
	Criminal = "Criminal",
	Eviction = "Eviction",
	LiensAndJudgements = "LiensAndJudgements",
}

export enum ScreeningStatus {
	Pending = "Pending",
	Complete = "Complete",
	Failed = "Failed",
}

export enum ScreeningProvider {
	CRS = "CRS",
}

export enum IncomeAndEmploymentScreeningProvider {
	Experian = "Experian",
}

export enum CriminalScreeningProvider {
	CRS = "CRS",
}

export enum EvictionScreeningProvider {
	CRS = "CRS",
}

export enum LiensAndJudgementsScreeningProvider {
	CRS = "CRS",
}

export enum IDVerificationProvider {
	Stripe = "Stripe",
}

export enum ApplicationDocumentCondition {
	AllApplicants = "AllApplicants",
	PrimaryApplicants = "PrimaryApplicants",
	CoApplicants = "CoApplicants",
	Guarantor = "Guarantor",
	CarOwners = "CarOwners",
	PetOwners = "PetOwners",
	ForeignNationals = "ForeignNationals",
}

export enum LeaseTemplateType {
	Lease = "Lease",
	Addendum = "Addendum",
	Rider = "Rider",
	Other = "Other",
}

export enum LeaseStatus {
	Draft = "Draft",
	Complete = "Complete",
	PendingDeposit = "PendingDeposit",
	PendingSignature = "PendingSignature",
	Signed = "Signed",
	Resident = "Resident",
	Archived = "Archived",
}

export enum SignatoryRole {
	Tenant = "Tenant",
	CoTenant = "CoTenant",
	Guarantor = "Guarantor",
	CoGuarantor = "CoGuarantor",
	Landlord = "Landlord",
	Manager = "Manager",
}

export enum FeedType {
	ILS = "ILS",
	Website = "Website",
	ApplicationPortal = "ApplicationPortal",
	TourScheduler = "TourScheduler",
	API = "API",
}

export enum ILSFeedFormat {
	StreetEasy = "StreetEasy",
	Zillow = "Zillow",
}

export enum ScreeningTier {
	Basic = "Basic",
	Essential = "Essential",
	Premium = "Premium",
}

export enum Parking {
	NoParking = "NoParking",
	ParkingSpot = "ParkingSpot",
	Garage = "Garage",
	Valet = "Valet",
	Carport = "Carport",
	StreetParking = "StreetParking",
}

export enum ApplicantRelationship {
	Spouse = "Spouse",
	Parent = "Parent",
	Sibling = "Sibling",
	Child = "Child",
	Friend = "Friend",
	Other = "Other",
}

export enum EmploymentStatus {
	Employed = "Employed",
	SelfEmployed = "SelfEmployed",
	Student = "Student",
	Retired = "Retired",
	Unemployed = "Unemployed",
	Other = "Other",
}

export enum PortalType {
	Organization = "Organization",
	PropertyGroup = "PropertyGroup",
	Property = "Property",
}

export enum ApplicantStatus {
	PendingInvite = "PendingInvite",
	Incomplete = "Incomplete",
	AwaitingReview = "AwaitingReview",
	AwaitingDocumentation = "AwaitingDocumentation",
	Approved = "Approved",
	Rejected = "Rejected",
}

export enum NotificationType {
	AutopilotMessage = "AutopilotMessage",
	AutopilotInquiry = "AutopilotInquiry",
	Message = "Message",
	Inquiry = "Inquiry",
}

export enum ApplicationDocumentRequirementType {
	W9 = "W9",
	BankStatement = "BankStatement",
	PayStub = "PayStub",
	Visa = "Visa",
	Other = "Other",
}

export enum ApplicationDocumentType {
	W9 = "W9",
	BankStatement = "BankStatement",
	PayStub = "PayStub",
	PetPhoto = "PetPhoto",
	PetVaccination = "PetVaccination",
	PetAttestation = "PetAttestation",
	Screening = "Screening",
	Other = "Other",
}

export enum PetType {
	Dog = "Dog",
	Cat = "Cat",
	Other = "Other",
}

export enum VaccinationType {
	CanineParvovirus = "Canine Parvovirus",
	CanineDistemper = "Canine Distemper",
	Hepatitis = "Hepatitis",
	Rabies = "Rabies",
	Bordetella = "Bordetella",
	CanineInfluenza = "Canine Influenza",
	LeptoSpirosis = "Lepto Spirosis",
	LymeDisease = "Lyme Disease",
}

export enum PredepositType {
	Hold = "Hold",
	Charge = "Charge",
}

export enum IdentityVerificationStatus {
	Pending = "Pending",
	Processing = "Processing",
	Verified = "Verified",
	Failed = "Failed",
	Skipped = "Skipped",
}

export enum ReservationType {
	Hold = "Hold",
	Charge = "Charge",
}

export enum SourceOfIncome {
	Employment = "Employment",
	HousingVouchers = "Housing Vouchers",
	SocialSecurity = "Social Security",
	Other = "Other",
}

export enum LeaseTemplateComponentType {
	Clause = "Clause",
	Addendum = "Addendum",
	ClauseHeader = "ClauseHeader",
	ClauseFooter = "ClauseFooter",
	Rider = "Rider",
	Disclosure = "Disclosure",
	Notice = "Notice",
	Attachment = "Attachment",
	Signature = "Signature",
	CoverPage = "CoverPage",
	Other = "Other",
}

export enum TenantRole {
	Tenant = "Tenant",
	CoTenant = "CoTenant",
}

export enum GuarantorRole {
	Guarantor = "Guarantor",
	CoGuarantor = "CoGuarantor",
}

export enum TenantStatus {
	Pending = "Pending",
	Signed = "Signed",
	Resident = "Resident",
	MovedOut = "MovedOut",
	Archived = "Archived",
}

export enum GuarantorStatus {
	Pending = "Pending",
	Signed = "Signed",
	Archived = "Archived",
}

export enum MultiFamilyKind {
	Luxury = "Luxury",
	MidRange = "MidRange",
	Affordable = "Affordable",
	Student = "Student",
	Senior = "Senior",
	Military = "Military",
}

export enum PetFeeType {
	None = "None",
	OneTime = "OneTime",
	Monthly = "Monthly",
	Annual = "Annual",
}

export enum ParkingFeeType {
	None = "None",
	OneTime = "OneTime",
	Monthly = "Monthly",
	Annual = "Annual",
}

export enum AmenityFeeType {
	None = "None",
	OneTime = "OneTime",
	Monthly = "Monthly",
	Annual = "Annual",
}

export enum TourStatus {
	Confirmed = "Confirmed",
	Rescheduled = "Rescheduled",
	Canceled = "Canceled",
	NoShow = "NoShow",
	Show = "Show",
}

export enum MessageType {
	Message = "Message",
	Listing = "Listing",
}

export enum MessageChannel {
	Email = "Email",
	Text = "Text",
}

export enum MessageStatus {
	Pending = "Pending",
	Sent = "Sent",
	Delivered = "Delivered",
	Opened = "Opened",
	Clicked = "Clicked",
	Read = "Read",
}

export enum TourProvider {
	User = "User",
	Property = "Property",
}

export enum TourEventType {
	Scheduled = "Scheduled",
	Rescheduled = "Rescheduled",
	Canceled = "Canceled",
	NoShow = "NoShow",
	Confirmed = "Confirmed",
	Completed = "Completed",
}

export enum MessageTemplate {
	TourConfirmation = "TourConfirmation",
	TourRescheduled = "TourRescheduled",
	TourCanceled = "TourCanceled",
	TourReminder = "TourReminder",
	InventoryTable = "InventoryTable",
	TourLink = "TourLink",
	InquiryConfirmation = "InquiryConfirmation",
}

export enum AutopilotRecommendationType {
	Message = "Message",
}

export enum LeadStatus {
	New = "New",
	TourBooked = "TourBooked",
	TourCompleted = "TourCompleted",
	ApplicationStarted = "ApplicationStarted",
	ApplicationSubmitted = "ApplicationSubmitted",
}

export enum StripeAccountStatus {
	Pending = "Pending",
	PendingStripe = "PendingStripe",
	Linked = "Linked",
}

export enum WebsiteTheme {
	Arch = "arch",
	Light = "light",
	Dark = "dark",
	Cupcake = "cupcake",
	Bumblebee = "bumblebee",
	Emerald = "emerald",
	Corporate = "light",
	Synthwave = "synthwave",
	Retro = "retro",
	Cyberpunk = "cyberpunk",
	Valentine = "valentine",
	Halloween = "halloween",
	Garden = "garden",
	Forest = "dark",
	Aqua = "aqua",
	Lofi = "lofi",
	Pastel = "pastel",
	Fantasy = "fantasy",
	Wireframe = "wireframe",
	Black = "black",
	Luxury = "luxury",
	Dracula = "dracula",
	Cmyk = "cmyk",
	Autumn = "autumn",
	Business = "business",
	Acid = "acid",
	Lemonade = "lemonade",
	Night = "night",
	Coffee = "coffee",
	Winter = "winter",
}
