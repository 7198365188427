import { Injectable } from "@angular/core";
import { DateTime, Duration } from "luxon";
import { LogLevel, Logger } from "src/app/utils";
import type { BackgroundTask } from "./background-task";

export interface BackgroundTaskOptions {
	earliestBeginDate: DateTime;
	interval?: Duration;
}

const POLLING_INTERVAL = Duration.fromObject({ seconds: 5 }).toMillis();

@Injectable({
	providedIn: "root",
})
export class BackgroundTaskService {
	private readonly _logger = new Logger("BackgroundTaskService", {
		level: LogLevel.ERROR,
	});
	private readonly _tasks = new Map<
		string,
		{ task: BackgroundTask; options: BackgroundTaskOptions }
	>();

	constructor() {
		setInterval(() => this._poll(), POLLING_INTERVAL);
	}

	public register(task: BackgroundTask, options: BackgroundTaskOptions): void {
		this._tasks.set(task.id, { task, options });
	}

	public update(task: BackgroundTask, options: BackgroundTaskOptions): void {
		this._logger.info(`Updating task ${task.id}.`);
		this._tasks.set(task.id, { task, options });
	}

	public cancel(task: BackgroundTask): void {
		this._logger.info(`Cancelling task ${task.id}.`);
		this._tasks.delete(task.id);
	}

	public cancelAll(): void {
		this._logger.info("Cancelling all tasks.");
		this._tasks.clear();
	}

	private async _poll(): Promise<void> {
		for (const [id, { task, options }] of this._tasks) {
			const { earliestBeginDate } = options;
			this._logger.info(
				earliestBeginDate > DateTime.now()
					? `The ${id} task will be run ${earliestBeginDate.toRelative({ round: false })}.`
					: `Running the ${id} task now.`,
			);
			if (earliestBeginDate > DateTime.now()) continue;

			this._logger.info(`Running task ${id}.`);
			await this._runTask(task);
			if (options.interval) {
				options.earliestBeginDate = DateTime.now().plus(options.interval);
			} else {
				this._tasks.delete(id);
			}
		}
	}

	private async _runTask(task: BackgroundTask): Promise<void> {
		try {
			await task.run();
		} catch (error) {
			this._logger.error(`Error running task ${task.id}:`, error);
		}
	}
}
