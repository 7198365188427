import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { type Observable, map } from "rxjs";
import type { Performance } from "src/app/schema";
import { ApiService } from "src/app/services";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class PerformanceService {

	constructor(
		private httpClient: HttpClient,
		private api: ApiService,
	) { }

	public performanceByProperty(
		propertyId: string,
	): Observable<Performance[]> {
		return this.httpClient
			.get<{ items: Performance[] }>(
				`${this.api.analyticsApiEndpoint}/properties/${propertyId}/performance`,
			)
			.pipe(map((response) => response.items));
	}

	public campaignPerformance(
		campaignId: string,
		startDate: string,
		endDate: string,
	): Observable<Performance[]> {
		return this.httpClient
			.get<{ items: Performance[] }>(
				`${environment.marketingEndpoint}/campaigns/${campaignId}/performance/${startDate}/${endDate}`,
			)
			.pipe(map((response) => response.items));
	}

	public aggregatePerformanceByDateRange(
		performance: Performance[],
		startDate: DateTime,
		nextDateFn: (date: DateTime) => DateTime,
	): Performance[] {
		const aggregatedPerformance: Performance[] = [];
		let currentDate = startDate;

		while (
			currentDate <= DateTime.fromISO(performance[performance.length - 1].date)
		) {
			const endDate = nextDateFn(currentDate).minus({ days: 1 });
			const periodPerformance = performance.filter((p: Performance) => {
				const date = DateTime.fromISO(p.date);
				return date >= currentDate && date <= endDate;
			});

			const total = periodPerformance.reduce(
				(acc: Performance, curr: Performance) => {
					const totalVisits = (acc.visits || 0) + (curr.visits || 0);
					const totalBounces = totalVisits * (curr.bounceRate / 100);
					const totalDuration =
						(acc.visitDuration || 0) * (acc.visits || 0) +
						(curr.visitDuration || 0) * (curr.visits || 0);

					return {
						...curr,
						impressions: (acc.impressions || 0) + (curr.impressions || 0),
						clicks: (acc.clicks || 0) + (curr.clicks || 0),
						visitors: (acc.visitors || 0) + (curr.visitors || 0),
						pageViews: (acc.pageViews || 0) + (curr.pageViews || 0),
						visits: totalVisits,
						bounceRate: (totalBounces / totalVisits) * 100,
						visitDuration: totalDuration / totalVisits,
						spend: (acc.spend || 0) + (curr.spend || 0),
						inquiries: (acc.inquiries || 0) + (curr.inquiries || 0),
						websiteInquiries:
							(acc.websiteInquiries || 0) + (curr.websiteInquiries || 0),
						leads: (acc.leads || 0) + (curr.leads || 0),
						bookedTours: (acc.bookedTours || 0) + (curr.bookedTours || 0),
						completedTours:
							(acc.completedTours || 0) + (curr.completedTours || 0),
						canceledTours: (acc.canceledTours || 0) + (curr.canceledTours || 0),
						listings: (acc.listings || 0) + (curr.listings || 0),
					};
				},
				{} as Performance,
			);

			aggregatedPerformance.push({
				...total,
				date: currentDate.toISODate()!,
			});

			currentDate = nextDateFn(currentDate);
		}
		return aggregatedPerformance;
	}
}
